/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Skeleton, Table, Tooltip, Typography } from 'antd';
import {
    MoreOutlined,
    RightCircleOutlined,
    ArrowLeftOutlined,
    EditOutlined,
    DeleteOutlined,
    SettingOutlined
} from '@ant-design/icons';
import MaintenanceTypes from './MaintenanceTypes';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { deleteAdminEquipTypes, fetchAdminEquipTypes, fetchAdminPihEquipTypes } from '../../redux/admin/equipmentTypes/action';
import EquipmentsNo from './EquipmentsNo';
import { fetchAdminEquipSno, fetchAdminEquipTypeSno, fetchAdminEquipDocType, fetchAdminEquipDocList } from '../../redux/admin/equipmentSno/action';

const { Text } = Typography;

const EquipSubCategory = (props) => {
    const {
        setOpenPage,
        setEquipmentSubTypeName,
        setEquipmentSubTypeId,
        equipmentTypeId,
        equipmentFormData,
        equipmentTypeName,
        handleSetForm,
        setDocumentPageActive,
        subTypeTableList,
        //
        onGetEquipSno,
        onFetchAdminEquipDocType,
        onFetchAdminEquipDocList,
        adminEquipDocTypeLoad,
        adminEquipDocTypeData,
        adminEquipSubTypeSnoLoad,
        adminEquipSubTypeSnoData,
    } = props;


    const typesListColumns = [
        {
            title: 'Equipment Subcategory',
            dataIndex: 'pihEquipmentType',
            key: 'pihEquipmentType',
            align: 'left',
            // sorter: (a, b) => {return (a-b)},
            ellipsis: true,
            // render: (text, record) => <div style={{ cursor: 'pointer' }} onClick={async () => {
            //     setOpenPage('documentList');
            //     setEquipmentSubTypeName(record.pihEquipmentType);
            //     setEquipmentSubTypeId(record.id);
            //     // console.log(record)
            //     await onFetchAdminEquipDocList({ equipmentTypeId: equipmentTypeId, equipmentId: record.id, documentId: adminEquipDocTypeLoad[0].id });
            // }}>{text}</div>
        },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            width: '100px',
            render: (text, record) => {
                return (
                    <div className='tableIcons'>
                        <Tooltip title='Documents' placement='top'>
                            <RightCircleOutlined className='icon-22'
                                onClick={async () => {
                                    setOpenPage('documentList');
                                    setEquipmentSubTypeName(record.pihEquipmentType);
                                    setEquipmentSubTypeId(record.id);
                                    equipmentFormData.setFieldsValue({ pihCategory: record.pihEquipmentType });
                                    setDocumentPageActive(true);
                                    // console.log(record)
                                    // await onFetchAdminEquipDocList({ equipmentTypeId: record?.id, equipmentId: '', documentId: adminEquipDocTypeLoad[0]?.id });
                                }} /></Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];


    //
    useEffect(() => {
        handleSetForm({ pihCategory: equipmentTypeName });
    }, [equipmentTypeName]);

    return (
        <div>
            <div>
                <Row gutter={24}>
                    <Col span={24} style={{ padding: '0px 12px 0' }}>
                        <Table
                            className='masterEquipTable_Pagination document-subEquipment'
                            columns={typesListColumns}
                            dataSource={[...subTypeTableList]}
                            scroll={{ x: '100%', y: '100%' }}
                            pagination={false}
                            // pagination={paginationClear ? false : {
                            //     position: ['bottomRight'],
                            //     showSizeChanger: true,
                            //     total: adminEquipTypesTotalCount,
                            //     defaultPageSize: 10,

                            //     onChange: async (pageNumber, pageSize) => {
                            //         await onGetPihEquipmentTypes({
                            //             pageSize: pageSize,
                            //             pageNumber: pageNumber,
                            //         });
                            //     }
                            // }}
                            // loading={adminEquipSubTypeSnoLoad}
                        />
                    </Col>
                </Row>
            </div>

        </div>
    )
}

const mapStateToProps = ({ adminEquipSnoReducer }) => {
    const adminEquipSubTypeSnoLoad = get(adminEquipSnoReducer, 'adminEquipSubTypeSnoLoad', false);
    const adminEquipSubTypeSnoData = get(adminEquipSnoReducer, 'adminEquipSubTypeSnoData', []);
    const adminEquipDocTypeLoad = get(adminEquipSnoReducer, 'adminEquipDocTypeLoad', false);
    const adminEquipDocTypeData = get(adminEquipSnoReducer, 'adminEquipDocTypeData', []);
    return {
        adminEquipSubTypeSnoLoad,
        adminEquipDocTypeLoad,
        adminEquipSubTypeSnoData,
        adminEquipDocTypeData
    }
}

const mapDispatchToProps = {
    onGetEquipSno: fetchAdminEquipTypeSno,
    onFetchAdminEquipDocType: fetchAdminEquipDocType,
    onFetchAdminEquipDocList: fetchAdminEquipDocList,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipSubCategory);