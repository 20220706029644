import React from 'react'
import { Button, Select, Typography, Spin, Table } from 'antd';
import { ReactGrid } from '@silevis/reactgrid';

const { Option } = Select;
const { Title, Text } = Typography;

const BillingGridExpand = (props) => {
    const {
        billingTitle, billingModalVisible, handleCancel, pipeLine, margins, padding, paddingLeft, rows, columns, handleChanges, selectedPipeline, setSelectedPipeline, pipelines, updateBillingData, isInitialBillingDataLoading, isBillingDataLoading, paddingPipeline, initialData, billingPipelines, isWriteAccess
    } = props

    if (!billingModalVisible) return null;

    return (
        <div className='billingGrid-expand'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className='d-flex'>
                    <h6>{billingTitle}</h6>
                    <div className='billing-select'>
                        <Select
                            className='pipeline-select'
                            placeholder='Select Pipeline'
                            onChange={(value) => setSelectedPipeline(value)}
                            value={selectedPipeline}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option.children).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {pipelines && pipelines.map((item, i) => (
                                <Option key={i} value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className='expand-billing-sheet'>
                    <Text onClick={!isWriteAccess ? updateBillingData : undefined} className="ps-3 add-new-team">
                        <span className="icon-22">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                                <path d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h11l5 5v11c0 1.1-.9 2-2 2zm-7-4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm3-11H6v2h9V6z" />
                            </svg>
                        </span>
                        Save
                    </Text>
                    <Text onClick={handleCancel} className="ps-3 add-new-team">
                        <span className="icon-22">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ display: 'block' }} > <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line> </svg>
                        </span>
                        Close
                    </Text>
                </div>
            </div>
            <Spin spinning={isBillingDataLoading || isInitialBillingDataLoading}>
                <div style={{ width: "100%", maxHeight: 'calc(100vh - 78px)', overflow: "auto" }}>
                    {(initialData.mobDemobDetails?.length && billingPipelines.includes(selectedPipeline)) ?
                        <>
                            <div style={{ display: 'flex', }}>
                                {pipeLine.map((name, index) => (
                                    <h6
                                        className='grid-header'
                                        style={{
                                            marginLeft: margins[index],
                                            paddingRight: (name == 'Date' || name == 'Personnel Mob I Demob') ? padding[index] : paddingPipeline,
                                            paddingLeft: (name == 'Date' || name == 'Personnel Mob I Demob') ? paddingLeft[index] : paddingPipeline,
                                        }} key={index} >
                                        {name}
                                    </h6>
                                ))}
                            </div>
                            <ReactGrid
                                rows={rows}
                                columns={columns}
                                onCellsChanged={handleChanges}
                            />
                        </> :
                        <Table
                            dataSource={null}
                        />
                    }
                </div>
            </Spin>
        </div>
    );
}

export default BillingGridExpand