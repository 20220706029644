import React, { useState } from 'react';
import { Button, Modal, Row, Col, DatePicker, Select, Form, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;

const ProjectDetailsModal = ({ selectedProject, onUpdateProject, projectTeamMembersAccessList }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [startDateInput, setStartDateInput] = useState(selectedProject?.startDate ? moment(selectedProject.startDate) : null);
    const [endDateInput, setEndDateInput] = useState(selectedProject?.endDate ? moment(selectedProject.endDate) : null);
    const [selectedStatus, setSelectedStatus] = useState(selectedProject?.projectStatus || 1);
    const [dayShiftInputHour, setDayShiftInputHour] = useState(selectedProject?.config?.technicalLogDefaultShiftHours?.day || 0);
    const [nightShiftInputHour, setNightShiftInputHour] = useState(selectedProject?.config?.technicalLogDefaultShiftHours?.night || 0);

    const [projectForm] = Form.useForm();

    const projectId = selectedProject?.id;

    const handleStatusChange = (value) => {
        setSelectedStatus(value);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSave = async () => {
        try {
            await projectForm.validateFields();
            if (projectId) {
                const updateData = {
                    startDate: moment(startDateInput).format('MM-DD-YYYY'),
                    endDate: moment(endDateInput).format('MM-DD-YYYY'),
                    projectStatus: selectedStatus,
                    config: {
                        "technicalLogDefaultShiftHours": {
                            "day": dayShiftInputHour,
                            "night": nightShiftInputHour
                        }
                    }
                };
                await onUpdateProject({ data: updateData, id: projectId });
            }
            setIsModalVisible(false);
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    return (
        <>
            <Button
                type="primary"
                onClick={showModal}
                shape="round"
                icon={<InfoCircleOutlined style={{ fontSize: '16px', verticalAlign: 'middle', marginRight: 8 }} />}
            >
                <span style={{ verticalAlign: 'middle' }}>Project Details</span>
            </Button>

            <Modal
                width='50vw'
                className="pipeline-modal"
                title={`Project :- ${selectedProject?.projectName || ''}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave}>
                        Save
                    </Button>,
                ]}
            >
                <Form
                    form={projectForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Client"
                                name="clientName"
                                initialValue={selectedProject?.clientName}
                            >
                                <Input
                                    placeholder="Enter Client Name"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Manager"
                                name="manager"
                                initialValue={selectedProject?.manager}
                            >
                                <Input
                                    placeholder="Enter Manager Name"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Start Date"
                                name="startDate"
                                initialValue={startDateInput}
                            >
                                <DatePicker
                                    allowClear={false}
                                    format="DD-MM-YYYY"
                                    defaultValue={startDateInput}
                                    onChange={(date) => setStartDateInput(date)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="End Date"
                                name="endDate"
                                initialValue={endDateInput}
                            >
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    allowClear={false}
                                    defaultValue={endDateInput}
                                    onChange={(date) => setEndDateInput(date)}
                                    disabledDate={(current) =>
                                        startDateInput && current < moment(startDateInput, 'DD-MM-YYYY')
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Project Status"
                                name="projectStatus"
                                initialValue={selectedStatus}
                            >
                                <Select
                                    className='client-select'
                                    value={selectedStatus}
                                    onChange={handleStatusChange}
                                >
                                    <Option value={1}>Initiated</Option>
                                    <Option value={2}>Active</Option>
                                    <Option value={3}>On Hold</Option>
                                    <Option value={4}>Completed</Option>
                                    <Option value={5}>Facility</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Completed Welds"
                                name="completedWelds"
                                initialValue="-"
                            >
                                <Input
                                    placeholder="Completed Welds"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Technician Log Default Day/Night Shift"
                                name="technicianLog"
                                initialValue={`${dayShiftInputHour} Hour / ${nightShiftInputHour} Hour`}
                            >
                                <Input
                                    value={`${dayShiftInputHour} Hour / ${nightShiftInputHour} Hour`}
                                    size="large"
                                    onChange={(e) => {
                                        const [day, night] = e.target.value.split(' / ').map(val => parseInt(val));
                                        setDayShiftInputHour(day);
                                        setNightShiftInputHour(night);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ProjectDetailsModal;