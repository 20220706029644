/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get'
import { Table, Form, Tooltip, Modal, Spin, Typography, Button } from 'antd';
import {
    EditOutlined, DeleteOutlined
} from '@ant-design/icons';
import { deleteSowDetails } from '../redux/sowDetails/actions';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';

const { Title, Text } = Typography;

const PipelineDetails = (props) => {
    const {
        isWriteAccess,
        pipelineData,
        isModalVisible,
        setIsModalVisible,
        editDetailsForm,
        setEditValue,
        setKeyValue,
        onDeleteSowDetails,
        showSowLoader,
        setChecked,
        setRestrict,
        setUniquePipeId,
    } = props;

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [loadingBtn, setLoadingBtn] = useState(false);

    const showModal = (record) => {
        let editData = null;
        if (record != null) {
            // console.log(record);
            editData = {
                pipelineSection: record.pipelineSection,
                material: record.material,
                plannedWelds: record.plannedWelds,
                stalkId: record.stalkId,
                blastStandard: record.blastStandard,
                fieldJointType: record.fieldJointType,
                prefix: record.weldNamingConfig ? record.weldNamingConfig.prefix : "",
                // suffix: record.weldNamingConfig ? record.weldNamingConfig.suffix : "",
                pipeStringId: record.pipeStringId,
                sowName: record.weldNamingConfig ? record.weldNamingConfig.sowName : ""
            };
            setUniquePipeId(record.pipeStringId)

            if (record.weldNamingConfig && record.weldNamingConfig.isRequired) {
                setChecked(true);
                setRestrict(true)
            } else {
                setChecked(false);
                setRestrict(false)
            }
            sessionStorage.setItem('sowId', record.id);
            sessionStorage.setItem('projectId', record.projectId);
        }
        editDetailsForm.setFieldsValue(editData);
        setKeyValue(record.projectId)
        setEditValue(true)
        setIsModalVisible(true);
    };

    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };

    const handleOk = async () => {
        await onDeleteSowDetails({ data: selectedData });
        setLoadingBtn(true);
        setTimeout(() => {
            setLoadingBtn(false);
            setIsDeleteModalVisible(false);
            setSelectedData('');
        }, 1000)

    }

    const handleCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('')
    }

    const columns = [
        {
            // title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            align: 'center',
            width: '60px',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Pipeline Section',
            dataIndex: 'pipelineSection',
            key: 'pipelineSection',
        },
        {
            title: 'Pipe String ID',
            dataIndex: 'pipeStringId',
            key: 'pipeStringId',
            align: 'center'
        },
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            align: 'center'
        },
        {
            title: 'Stalk #',
            dataIndex: 'stalkId',
            key: 'stalkId',
            align: 'center'
        },
        {
            title: 'Field Joint Type',
            dataIndex: 'fieldJointType',
            key: 'fieldJointType',
            align: 'center'
        },
        {
            title: 'Blast Standard',
            dataIndex: 'blastStandard',
            key: 'blastStandard',
            align: 'center'
        },
        {
            title: 'Planned Welds',
            dataIndex: 'plannedWelds',
            key: 'plannedWelds',
            align: 'center',
            width: '120px'
        },

        {
            key: 'action',
            dataIndex: 'action',
            fixed: 'right',
            width: '100px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }}>
                                <DeleteOutlined style={{ fontSize: '18px' }} onClick={() => { !isWriteAccess ? showDeleteModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">

            {pipelineData.length ? <Table
                pagination={false}
                columns={columns}
                dataSource={pipelineData}
                className="h-100 pipeline-table table-action-column"
                scroll={{ x: 1200, y: '100%' }}
                loading={showSowLoader}
            /> :
                <Table
                    pagination={false}
                    dataSource={pipelineData}
                    className="h-100 pipeline-table statement_work"
                    loading={showSowLoader}
                />
            }

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Pipeline Section"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={loadingBtn}>
                        Delete
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}><p>Deleting statement of work will delete all the corresponding Stations and related information.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.pipelineSection}</b> statement of work?</Text>
                </div>
            </Modal>
        </div>
    );
};


const mapStateToProps = ({
    sow
}) => {
    const showSowLoader = get(sow, 'isSowLoading', false);
    return {
        showSowLoader
    };
};

const mapDispatchToProps = {
    onDeleteSowDetails: deleteSowDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(PipelineDetails);
