/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, notification, DatePicker, Button, Select, Radio
} from 'antd';
import {
    FilterFilled
} from '@ant-design/icons';
import moment from 'moment';
// import { delay } from 'lodash';

const { Title, Text } = Typography;
const { Option } = Select;
var obj = {
    fromDate: '22-03-2022',
    toDate: '22-03-2023',
    sowId: 1,
    format: 'CSV',
    email: '',
    generatedBy: '',
    projectId: 1,
    generateReport: false
}
const GenerateReport = (props) => {

    const {
        isReportModalVisible,
        setIsReportModalVisible,
        setLocationData,
        locationData,
        projectIdValue,
        onGenerateReport,
        isLogReportsLoading,
        isWriteAccess
    } = props;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [location, setLocation] = useState('');
    const [format, setFormat] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [loadingReport, setLoadingReport] = useState(false);

    const [reportForm] = Form.useForm()


    const showModal = () => {
        setIsReportModalVisible(true);

    };

    useEffect(() => {
        // var editField = {
        //     format: 'PDF'
        // }
        // reportForm.setFieldsValue(editField);
        //setting default dowloading report format
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            var userName = user['custom:first_name'] ? (user['custom:first_name'] + ' ' + (user['custom:last_name'] ? user['custom:last_name'] : '')) : ''
            setName(userName);
            reportForm.setFieldsValue({ name: userName });
        }
        //setting default name for generating Report
    })

    const handleOk = () => {
        reportForm
            .validateFields()
            .then((data) => {
                if (!data.startDate && !data.endDate && !data.location && !data.email && !data.name
                    // && !data.format
                ) {
                    notification.warning({
                        message: 'Select All Required Fields to proceed!'
                    });

                } else if (!data.startDate) {
                    notification.warning({
                        message: 'Select From Date to proceed!'
                    });
                } else if (!data.endDate) {
                    notification.warning({
                        message: 'Select To Date to proceed!'
                    });
                } else if (!data.location) {
                    notification.warning({
                        message: 'Select Location to proceed!'
                    });
                } else if (!data.format) {
                    notification.warning({
                        message: 'Select Format to proceed!'
                    });
                } else if (!data.email) {
                    notification.warning({
                        message: 'Provide Email to proceed!'
                    });
                } else if (!data.name) {
                    notification.warning({
                        message: 'Provide Name to proceed!'
                    });
                }
                else {
                    // onSave(data.stationName, selectedStation);
                    var sowId = '';
                    if (data.location && locationData) {
                        locationData.filter((item) => {
                            if (item.pipelineSection == data.location) {
                                sowId = item.id
                            }
                        })
                    }
                    var fromDate = moment(data.startDate).format('YYYY-MM-DD');
                    var toDate = moment(data.endDate).format('YYYY-MM-DD')
                    var obj = {
                        fromDate: fromDate,
                        toDate: toDate,
                        sowId: sowId,
                        format: data.format,
                        email: data.email,
                        generatedBy: data.name,
                        projectId: projectIdValue,
                        generateReport: true
                    }
                    if (fromDate > toDate) {
                        notification.warn({
                            message: 'From date should be previous than To date'
                        });
                    } else {
                        onGenerateReport(obj);
                        reportForm.resetFields(); //resetting input fields
                        setLoadingReport(true);
                        // setTimeout(() => {
                        //     setLoadingReport(false);
                        //     setIsReportModalVisible(false);
                        //     setStartDate(null)
                        //     setEndDate(null);
                        //     setLocation('');
                        //     setEmail('');
                        //     setName('')
                        // }, 1000)
                    }
                }
            });
    };

    useEffect(() => {
        if (!isLogReportsLoading) {
            setLoadingReport(false);
            setIsReportModalVisible(false);
            setStartDate(null)
            setEndDate(null);
            setLocation('');
            setEmail('');
            setName('');
        }
    }, [isLogReportsLoading]);

    const handleCancel = () => {
        reportForm.resetFields(); //resetting input fields
        setIsReportModalVisible(false);
        setStartDate(null)
        setEndDate(null);
        setLocation('');
        setEmail('');
        setName('')
    };

    // const onKeyDown = (event) =>{
    //     console.log(event)
    //     if(event.which === 13 || event.which === 188 || event.which === 186) {
    //         console.log("done job", email)
    //     }
    // }

    return (
        <>
            <div onClick={!isWriteAccess ? showModal : undefined} >
                <Button>Generate Report</Button>
            </div>
            <div>
                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Generate Report"
                    centered
                    visible={isReportModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Discard
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleOk} loading={isLogReportsLoading}>
                            Confirm
                        </Button>
                    ]}
                >

                    <div>
                        <Text>Date Range:</Text>
                        <Form
                            form={reportForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                        >
                            <Row gutter={16}>
                                <Col lg={8}>
                                    <Form.Item
                                        className="date-picker"
                                        label="From"
                                        name="startDate"
                                        required
                                    >
                                        <DatePicker
                                            placholder='From'
                                            format="DD-MM-YYYY"
                                            onChange={(date, dateString) => {
                                                setStartDate(dateString);
                                                if (endDate && dateString > endDate) {
                                                    notification.warn({
                                                        message: 'From date should be previous than To date'
                                                    })

                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        className="date-picker"
                                        label="To"
                                        name="endDate"
                                        required
                                    >
                                        <DatePicker
                                            format="DD-MM-YYYY"
                                            onChange={(date, dateString) => setEndDate(dateString)}
                                            disabledDate={current => startDate && current < moment(startDate, "DD-MM-YYYY")}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={16}>
                                    <Form.Item
                                        label="Location"
                                        name="location"
                                        required
                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select Location'
                                            onChange={(value) => setLocation(value)}
                                            value={location}
                                        >
                                            {locationData && locationData.map((item, i) =>
                                                <Option key={i} value={item && item.pipelineSection}>{item && item.pipelineSection}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Form.Item
                                        label="Format"
                                        name="format"
                                        required
                                        className='report-radio'
                                    >
                                        <Radio.Group onChange={e => { setFormat(e.target.value) }} value={format}>
                                            <Radio value={'CSV'}>CSV</Radio>
                                            <Radio value={'PDF'}>PDF</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={16} style={{ marginBottom: '24px' }}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        required
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Input
                                            // disabled
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            size="large"
                                            placeholder='Enter emails followed by comma (,)'
                                        // onKeyDown={onKeyDown}
                                        />

                                    </Form.Item>
                                    <span style={{ fontSize: '12px', position: 'relative', left: '10px' }}>eg: 1234@mail.com, 234@email.com</span>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={16} >
                                    <Form.Item
                                        label="Generated By"
                                        name="name"
                                        required
                                    >
                                        <Input
                                            // disabled
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            size="large"
                                            placeholder='Name or Email' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default GenerateReport;