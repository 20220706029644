/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ProjectListHeader from '../../layout/projectListHeader';
import TabList from '../../layout/tab';
import {
  func, arrayOf, objectOf, oneOfType, string, number, array, bool
} from 'prop-types';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchProject } from '../../redux/actions/projectAction';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Spin } from 'antd';
import { fetchClients } from '../../redux/actions/clientAction';
import { fetchStationDetails } from '../../redux/stationDetails/action';
import { fetchTestTypes } from '../../redux/testDetails/action';
import { getCurrentUser } from '../../sagas/auth';
import { signOut } from '../../redux/actions/authActions';
import history from '../../services/history';
import { fetchEquipList } from '../../redux/pihEquipmentDetails/action';
import { updateProject } from '../../redux/actions/projectAction';
import ProjectDashboard from '../projects/ProjectDashboard';
import ProjectDetailsModal from '../../components/ProjectDetailsModal';
import { fetchTeamMembersAccessForProject } from '../../redux/pihEquipmentDetails/action';

const HomePage = (props) => {
  const { onGetProjects,
    projectData,
    showProjectLoader,
    onGetClients,
    clientData,
    onGetStations,
    stationData,
    onGetTestValueTypes,
    testTypes,
    isAuthenticated,
    onSignOut,
    onFetchEquipList, equipList,
    setIsShowProjectData, isShowProjectData, onUpdateProject, onFetchTeamMembersAccessForProject, projectTeamMembersAccessList
  } = props;

  const [selectedProject, setSelectedProj] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [testTypesList, setTestTypesList] = useState([]);
  const [user, setUser] = useState();
  const [equipmentList, setequipmentList] = useState([]);

  useEffect(() => {
    //console.log(projectTeamMembersAccessList)
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      onGetProjects();
      onGetClients();
      onGetStations();
      onGetTestValueTypes();
      // onFetchEquipList();
    }
    // sessionStorage.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let projectObj = [];
    if (projectData) {
      projectData.map((item) => {
        projectObj.push(item);
      });
      projectObj.sort((a, b) => parseFloat(a.id) - parseFloat(b.id)); //sorting projects by ID
      setProjectList(projectObj);
    }
  }, [projectData]);

  useEffect(() => {
    let clientObj = [];
    if (clientData) {
      clientData.map((item) => {
        clientObj.push(item);
      });
      setClientList(clientObj);
    }
  }, [clientData]);

  useEffect(() => {
    let stationObj = [];
    if (stationData) {
      stationData.map((item) => {
        stationObj.push(item);
      });
      setStationList(stationObj);
    }
  }, [stationData]);

  useEffect(() => {
    let typesObj = [];
    if (testTypes) {
      testTypes.map((item) => {
        typesObj.push(item);
      });
      setTestTypesList(typesObj);
    }
  }, [testTypes]);

  useEffect(() => {
    let equipObj = [];
    if (equipList) {
      equipList.map((item) => {
        equipObj.push(item);
      });
      setequipmentList(equipObj);
    }
  }, [equipList]);

  useEffect(() => {
    if (selectedProject.id) {
      onFetchTeamMembersAccessForProject({ id: selectedProject.id });
    }
  }, [selectedProject.id])

  if (showProjectLoader) {
    return (
      <div className="home-loader">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  const reloadNewData = () => {
    const isValid = getCurrentUser();
    if (isAuthenticated && isValid) {
      onGetProjects();
      onGetClients();
      onGetStations();
      onGetTestValueTypes();
      // onFetchEquipList();
    } else {
      history.push('/login');
      onSignOut();
    }
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="flex-auto">
        {
          (selectedProject.id && isShowProjectData == true) &&
          <div className="d-flex pb-2 align-items-center justify-content-between">
            <div className='d-flex'>
              <Button
                type="primary"
                onClick={() => setIsShowProjectData(false)}
                shape="round"
                icon={<ArrowLeftOutlined
                  style={{ fontSize: '16px', verticalAlign: 'middle', marginRight: 8 }}
                />}
              >
                {/* <span style={{ verticalAlign: 'middle' }}>Back</span> */}
              </Button>
              <h1 className="project-title" style={{ verticalAlign: 'middle', marginLeft: 8, paddingTop: '4px' }}>Project : {`${selectedProject.projectName} (${selectedProject.projectId})`}</h1>
            </div>
              <ProjectDetailsModal
                selectedProject={selectedProject}
                onUpdateProject={onUpdateProject}
                projectTeamMembersAccessList={projectTeamMembersAccessList}
              />
          </div>
        }
        {
          (selectedProject.id && isShowProjectData == true) ?
            <TabList
              selectedProject={selectedProject}
              stationList={stationList}
              testTypesList={testTypesList}
              setStationList={setStationList}
              equipmentList={equipmentList}
              projectTeamMembersAccessList={projectTeamMembersAccessList}
            />
            :
            <ProjectDashboard
              selectedProject={selectedProject}
              setSelectedProj={setSelectedProj}
              projectList={projectList}
              clientList={clientList}
              reloadData={reloadNewData}
              setIsShowProjectData={setIsShowProjectData}
              onProjectClick={() => setIsShowProjectData(true)} // Add this line
            />
        }
      </div>
    </div>
  );
}

HomePage.propTypes = {
  onGetProjects: func.isRequired,
  projectData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  clientData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  onGetClients: func.isRequired,
  onGetStations: func.isRequired
};

const mapStateToProps = ({
  projectRequests, clientRequest, station, testReducer, equip
}) => {
  const projectData = get(projectRequests, 'projectData', []);
  const clientData = get(clientRequest, 'clientData', []);
  const showProjectLoader = get(projectRequests, 'isProjectLoading', false);
  const stationData = get(station, 'stationData', []);
  const testTypes = get(testReducer, 'testTypes', []);
  const equipList = get(equip, 'equipList', []);
  const projectTeamMembersAccessList = get(equip, 'projectTeamMembersAccessList', []);
  return {
    projectData,
    showProjectLoader,
    clientData,
    stationData,
    testTypes,
    equipList,
    projectTeamMembersAccessList
  };
};

const mapDispatchToProps = {
  onGetProjects: fetchProject,
  onGetClients: fetchClients,
  onGetStations: fetchStationDetails,
  onGetTestValueTypes: fetchTestTypes,
  onSignOut: signOut,
  onFetchEquipList: fetchEquipList,
  onUpdateProject: updateProject,
  onFetchTeamMembersAccessForProject: fetchTeamMembersAccessForProject,
};


export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
