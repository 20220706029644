import get from 'lodash/get';
import moment from 'moment';
import {
    ADD_EQUIPMENT_DETAILS,
    ADD_EQUIPMENT_DETAILS_FAILURE,
    ADD_EQUIPMENT_DETAILS_SUCCESS,
    ADD_TEAM_MEMBER,
    ADD_TEAM_MEMBER_FAILURE,
    ADD_TEAM_MEMBER_SUCCESS,
    DELETE_EQUIPMENT_DETAILS,
    DELETE_EQUIPMENT_DETAILS_FAILURE,
    DELETE_EQUIPMENT_DETAILS_SUCCESS,
    DELETE_TEAM_MEMBER,
    DELETE_TEAM_MEMBER_FAILURE,
    DELETE_TEAM_MEMBER_SUCCESS,
    FETCH_EQUIPMENT_CATEGORY,
    FETCH_EQUIPMENT_CATEGORY_FAILURE,
    FETCH_EQUIPMENT_CATEGORY_SUCCESS,
    FETCH_EQUIPMENT_DETAILS,
    FETCH_EQUIPMENT_DETAILS_FAILURE,
    FETCH_EQUIPMENT_DETAILS_SUCCESS,
    FETCH_EQUIPMENT_LIST,
    FETCH_EQUIPMENT_LIST_FAILURE,
    FETCH_EQUIPMENT_LIST_SUCCESS,
    FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS,
    FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_FAILURE,
    FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_SUCCESS,
    FETCH_TEAM_MEMBERS_FOR_PROJECT,
    FETCH_TEAM_MEMBERS_FOR_PROJECT_FAILURE,
    FETCH_TEAM_MEMBERS_FOR_PROJECT_SUCCESS,
    FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT,
    FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_FAILURE,
    FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_SUCCESS,
    GET_CHECKS_DETAILS,
    GET_CHECKS_DETAILS_FAILURE,
    GET_CHECKS_DETAILS_SUCCESS,
    GET_CHECKS_LOGS_DETAILS,
    GET_CHECKS_LOGS_DETAILS_FAILURE,
    GET_CHECKS_LOGS_DETAILS_SUCCESS,
    GET_ALL_USERS,
    GET_ALL_USERS_FAILURE,
    GET_ALL_USERS_SUCCESS,
    SET_EQUIPMENT_DETAILS_UPDATE,
    UPDATE_EQUIPMENT_DETAILS,
    UPDATE_EQUIPMENT_DETAILS_FAILURE,
    GET_ALL_NON_COGNITO_USERS,
    GET_ALL_NON_COGNITO_USERS_SUCCESS,
    GET_ALL_NON_COGNITO_USERS_FAILURE,
    ADD_NON_COGNITO_USERS,
    ADD_NON_COGNITO_USERS_SUCCESS,
    ADD_NON_COGNITO_USERS_FAILURE
} from './types';

const initialState = {
    isEquipListLoading: false,
    isEquipDetailsLoading: false,
    isGetEquipDetailsLoading: false,
    isEquipUpdateLoading: false,
    isChecksLoading: false,
    checksPerformed: '',
    equipMaintenanceIntervalLoading: false,
    equipList: [],
    equipData: [],
    equipMaintenanceIntervalData: [],
    isCheckLogsLoading: false,
    checksLogData: [],
    isCategoryLoading: false,
    isUserListLoading: false,
    usersList: [],
    isProjectTeamMembersLoadin: false,
    projectTeamMembersList: [],
    isProjectTeamMembersAccessLoadin: false,
    projectTeamMembersAccessList: [],
    isAddingMemberInProgress: false,
    nonCognitoUsersList: [],
    isAddingNonCognitoUser: false,
};

const equipmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EQUIPMENT_LIST: {
            return {
                ...state,
                isEquipListLoading: true
            }
        }

        case FETCH_EQUIPMENT_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                equipList: resData.data,
                isEquipListLoading: false
            }
        }

        case FETCH_EQUIPMENT_LIST_FAILURE: {
            return {
                ...state,
                isEquipListLoading: false
            }
        }

        case ADD_EQUIPMENT_DETAILS: {
            return {
                ...state,
                isEquipDetailsLoading: true
            }
        }

        case ADD_EQUIPMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isEquipDetailsLoading: false
            }
        }

        case ADD_EQUIPMENT_DETAILS_FAILURE: {
            return {
                ...state,
                isEquipDetailsLoading: false
            }
        }

        case FETCH_EQUIPMENT_DETAILS: {
            return {
                ...state,
                isGetEquipDetailsLoading: true
            }
        }

        case FETCH_EQUIPMENT_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return b.id - a.id;
            });
            resData.rows.map((item, i) => {
                item.sno = i + 1
            })
            return {
                ...state,
                equipData: resData.rows,
                equipDataCount:resData.count,
                isGetEquipDetailsLoading: false
            }
        }

        case FETCH_EQUIPMENT_DETAILS_FAILURE: {
            return {
                ...state,
                isGetEquipDetailsLoading: false
            }
        }

        case UPDATE_EQUIPMENT_DETAILS: {
            return {
                ...state,
                isEquipUpdateLoading: true
            };
        }

        case UPDATE_EQUIPMENT_DETAILS_FAILURE: {
            return {
                ...state,
                isEquipUpdateLoading: false
            };
        }

        case SET_EQUIPMENT_DETAILS_UPDATE: {
            return {
                ...state,
                isEquipUpdateLoading: false
            }
        }

        case DELETE_EQUIPMENT_DETAILS: {
            return {
                ...state,
                isEquipDeleteLoading: true
            };
        }

        case DELETE_EQUIPMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isEquipDeleteLoading: false
            };
        }

        case DELETE_EQUIPMENT_DETAILS_FAILURE: {
            return {
                ...state,
                isEquipDeleteLoading: false
            }
        }

        case GET_CHECKS_DETAILS: {
            return {
                ...state,
                isChecksLoading: true
            }
        }

        case GET_CHECKS_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            var pihEquipmentChecksDetail = {};
            if (resData && resData.pihEquipmentChecksDetail) {
                pihEquipmentChecksDetail = resData.pihEquipmentChecksDetail
            } else {
                pihEquipmentChecksDetail = ''
            }

            return {
                ...state,
                checksData: resData.pihEquipmentChecks,
                checksPerformed: pihEquipmentChecksDetail,
                isChecksLoading: false
            }
        }

        case GET_CHECKS_DETAILS_FAILURE: {
            return {
                ...state,
                isChecksLoading: false
            }
        }

        case FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS: {
            return {
                ...state,
                equipMaintenanceIntervalLoading: true
            }
        }

        case FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                equipMaintenanceIntervalData: resData,
                equipMaintenanceIntervalLoading: false
            }
        }

        case FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_FAILURE: {
            return {
                ...state,
                equipMaintenanceIntervalLoading: false
            }
        }

        case GET_CHECKS_LOGS_DETAILS: {
            return {
                ...state,
                isCheckLogsLoading: true
            }
        }

        case GET_CHECKS_LOGS_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return moment(b.checkPerformedOn) - moment(a.checkPerformedOn);
            });
            return {
                ...state,
                checksLogData: resData.rows,
                checksLogDataCount :resData.count,
                isCheckLogsLoading: false
            }
        }

        case GET_CHECKS_LOGS_DETAILS_FAILURE: {
            return {
                ...state,
                isCheckLogsLoading: false
            }
        }

        case GET_ALL_USERS: {
            return {
                ...state,
                isUserListLoading: true
            }
        }

        case GET_ALL_USERS_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.Users.sort(function (a, b) {
                return moment(b.UserCreateDate) - moment(a.UserCreateDate);
            });
            return {
                ...state,
                usersList: resData.Users,
                isUserListLoading: false
            }
        }

        case GET_ALL_USERS_FAILURE: {
            return {
                ...state,
                isUserListLoading: false
            }
        }

        case FETCH_EQUIPMENT_CATEGORY: {
            return {
                ...state,
                isCategoryLoading: true
            }
        }

        case FETCH_EQUIPMENT_CATEGORY_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                categoryData: resData.rows,
                isCategoryLoading: false
            }
        }

        case FETCH_EQUIPMENT_CATEGORY_FAILURE: {
            return {
                ...state,
                isCategoryLoading: false
            }
        }

        case FETCH_TEAM_MEMBERS_FOR_PROJECT: {
            return {
                ...state,
                isProjectTeamMembersLoading: true
            }
        }

        case FETCH_TEAM_MEMBERS_FOR_PROJECT_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                projectTeamMembersList: resData.rows,
                isProjectTeamMembersLoading: false
            }
        }

        case FETCH_TEAM_MEMBERS_FOR_PROJECT_FAILURE: {
            return {
                ...state,
                isProjectTeamMembersLoading: false
            }
        }
        //TEAM members access
        case FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT: {
            return {
                ...state,
                isProjectTeamMembersLoading: true
            }
        }

        case FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_SUCCESS: {
            const resData = get(action, 'response', []);
            const user = JSON.parse(localStorage.getItem('user'));
            return {
                ...state,
                projectTeamMembersAccessList: resData.rows.filter(row => row.email === user.email),
                isProjectTeamMembersAccessLoading: false
            }
        }

        case FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_FAILURE: {
            return {
                ...state,
                isProjectTeamMembersLoading: false
            }
        }

        case DELETE_TEAM_MEMBER: {
            return {
                ...state,
                isDeletingInProgress: true
            }
        }

        case DELETE_TEAM_MEMBER_SUCCESS: {
            return {
                ...state,
                isDeletingInProgress: false
            }
        }

        case DELETE_TEAM_MEMBER_FAILURE: {
            return {
                ...state,
                isDeletingInProgress: false
            }
        }

        case ADD_TEAM_MEMBER: {
            return {
                ...state,
                isAddingMemberInProgress: true
            }
        }

        case ADD_TEAM_MEMBER_SUCCESS: {
            return {
                ...state,
                isAddingMemberInProgress: false
            }
        }

        case ADD_TEAM_MEMBER_FAILURE: {
            return {
                ...state,
                isAddingMemberInProgress: false
            }
        }

        case GET_ALL_NON_COGNITO_USERS: {
            return {
                ...state,
                isUserListLoading: true
            }
        }

        case GET_ALL_NON_COGNITO_USERS_SUCCESS: {
            let resData
            resData = get(action, 'response', []);
            resData = resData.rows;
            resData = resData.filter(row => row.isNonCognitoUser == true);;
            return {
                ...state,
                nonCognitoUsersList: resData,
                isUserListLoading: false
            }
        }

        case GET_ALL_NON_COGNITO_USERS_FAILURE: {
            return {
                ...state,
                isUserListLoading: false
            }
        }

        case ADD_NON_COGNITO_USERS: {
            return {
                ...state,
                isAddingNonCognitoUser: true
            }
        }

        case ADD_NON_COGNITO_USERS_SUCCESS: {
            return {
                ...state,
                isAddingNonCognitoUser: false
            }
        }

        case ADD_NON_COGNITO_USERS_FAILURE: {
            return {
                ...state,
                isAddingNonCognitoUser: false
            }
        }

        default: {
            return state;
        }
    }
}

export default equipmentReducer;