import React from 'react'
import { Button, Select, Typography, Spin } from 'antd';
import { ReactGrid } from '@silevis/reactgrid';

const { Title, Text } = Typography;
const InvoiceGridExpand = (props) => {
    const { rows, columns, handleChanges, handleCancel, handleAddRow, invoiceTitle, updateInvoiceData, isInvoiceDataLoading, isWriteAccess } = props;
    return (
        <div className='billingGrid-expand'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6>{invoiceTitle}</h6>
                <div className='expand-billing-sheet'>
                    <Text onClick={!isWriteAccess ? updateInvoiceData : undefined} className="ps-3 add-new-team">
                        <span className="icon-22">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                                <path d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h11l5 5v11c0 1.1-.9 2-2 2zm-7-4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm3-11H6v2h9V6z" />
                            </svg>
                        </span>
                        Save
                    </Text>
                    <Text onClick={!isWriteAccess ? handleAddRow : undefined} className="ps-3 add-new-team">
                        <span className="icon-22">
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add Row
                    </Text>
                    <Text onClick={handleCancel} className="ps-3 add-new-team">
                        <span className="icon-22">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ display: 'block' }} > <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line> </svg>
                        </span>
                        Close
                    </Text>
                </div>
            </div>
            <div style={{ width: "100%", maxHeight: 'calc(100vh - 100px)', overflowX: "auto", paddingBottom: '60px' }}>
                <Spin spinning={isInvoiceDataLoading}>
                <ReactGrid
                    rows={rows}
                    columns={columns}
                    onCellsChanged={handleChanges}
                />
                </Spin>
            </div>
        </div>
    );
}

export default InvoiceGridExpand