/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Table, Col, Typography, Modal, Form, Input, notification, Button, Upload, Tooltip, Select, Checkbox, Space } from 'antd';
const { Title, Text } = Typography;
import get from 'lodash/get';
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, ExportOutlined } from '@ant-design/icons';
import { fetchSparesDashBoardList, updateDashboardSpare, deleteDashboardSpare, fetchsparesEuipTypeList, fetchsparesEuipSNList, fetchSapKitNoList, fetchSapNoList, addSpareKit, addIndvSpare, publishDashboardList, fetchEuipSNList, fetchExcelSparesDashBoardList, fetchSubcategory } from '../redux/sparesDashboard/action';
import { Header } from 'antd/lib/layout/layout';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';

const SpareDashboard = (props) => {
    const {
        selectedProject, sparesEuipType, sparesEuipSn, equipmentSn, sparesSapKitNo, sparesSapNo, isSparesEuipTypeLoading, isSparesEuipSnLoading, isEquipmentSnLoading, isSparesSapKitNoLoading, isSparesSapNoLoading, equipDetails, projectTeamMembersAccessList, userAccessList,

        //
        onFetchSparesDashBoardList, onFetchExcelSparesDashBoardList, onGetPihEquipmentTypes, onGetSparesEquipmentSn, onGetEquipmentSn, onAddIndvSpare, onAddSpareKit, onFetchSubcategory, excelSparesList, isExcelSparesListLoading,
        onGetSparesEquipmentKitNo, onGetSparesEquipmentSapNo, onUpdateSpareList, onPublishDashboardList, sparesList, sparesDataCount, isSparesListLoading, onDeleteDashboardSpare, sparesSubcategoryData, isSparesDashSubcategoryLoading
    } = props;

    const { Option } = Select;
    const [addIndSpareForm] = Form.useForm();
    const [spareKitForm] = Form.useForm();
    const [showAddModal, setShowAddModal] = useState(false);
    const [editDetailsForm] = Form.useForm();
    const [editValue, setEditValue] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);
    const [editSpareId, setEditSpareId] = useState('');
    const [parentId, setParentId] = useState('');
    const [assignedToSerialNumber, setAssignedToSerialNumber] = useState('');
    const [assignToEquipmentId, setAssignedToEquipmentId] = useState('');
    const [assignToPihEquipmentId, setAssignToPihEquipmentId] = useState('');
    const [selectedData, setSelectedData] = useState('');

    const [showAddSpareModal, setShowAddSpareModal] = useState(false);
    const [isKitModalVisible, setIsKitModalVisible] = useState(false);
    const [isChecked, setIsChecked] = useState('');
    const [currency, setCurrency] = useState('');
    const [equipmentTypeList, setEquipmentTypeList] = useState([]);
    const [serialNoList, setSerialNoList] = useState([]);
    const [sapKitNoList, setSapKitNoList] = useState([]);
    const [sapNumberList, setSapNumberList] = useState([]);
    const [serialNumberList, setSerialNumberList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    //
    const [equipmentType, setEquipmentType] = useState('');
    const [equipmentSN, setEquipmentSN] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [serialNoToAssign, setSerialNoToAssign] = useState(false);
    const [equipmentSapKitNo, setEquipmentSapKitNo] = useState('');
    const [equipmentSapNumber, setEquipmentSapNumber] = useState('')
    const [current, setCurrent] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(20);
    const [newQty, setNewQty] = useState(0);
    const [initialQty, setInitialQty] = useState('');
    const [isSerialNoToAssign, setIsSerialNoToAssign] = useState(false);
    const [totalLists, setTotalLists] = useState(0);

    //
    const [equipmentTypeValue, setEquipmentTypeValue] = useState('');
    const [assignedToSerialNumberValue, setAssignedToSerialNumberValue] = useState('');
    const [equipmentSerialNumber, setEquipmentSerialNumber] = useState('');
    const [equipmentSubCategory, setEquipmentSubCategory] = useState('');
    const [sapKitNo, setSapKitNo] = useState('');
    const [sapNumber, setSapNumber] = useState('');
    const [description, setDescription] = useState('');
    const [unit, setUnit] = useState('');
    const [safetyStock, setSafetyStock] = useState('');
    const [cost, setCost] = useState('');


    const firstElement = { equipmentSerialNumber: 'All' };
    sparesEuipSn.unshift(firstElement);

    const isDisabled = isSerialNoToAssign || (equipmentSN === 'All' && serialNumberList.length === 0);

    //Spares write access control for normal user
    const isButtonDisabled = () => {

        if (userAccessList.includes('admin'))
            return false;
        else if (projectTeamMembersAccessList[0]?.isGuestUser) {
            return true;
        } else if (projectTeamMembersAccessList[0]?.isNormalUser && !projectTeamMembersAccessList[0]?.tabPermissions?.Spares?.write) {
            return true;
        } else {
            return false;
        }
    }

    const isWriteAccess = isButtonDisabled();

    useEffect(() => {
        if (selectedProject && selectedProject.id) {
            let obj = {
                projectId: selectedProject.id,
                pageSize: 20,
                pageNumber: 1,
            }
            onFetchSparesDashBoardList(obj)
            onFetchExcelSparesDashBoardList({ projectId: selectedProject.id });
            onGetPihEquipmentTypes()
            onGetEquipmentSn({ projectId: selectedProject.id })
        }
    }, [selectedProject, equipDetails]);

    useEffect(() => {
        if (sparesList.length > totalLists && totalLists ==0) {
            setTotalLists(sparesList.length);
        }
    }, [sparesList, totalLists]);
    


    //Equipment Type List
    useEffect(() => {
        setEquipmentTypeList(removeEqTypeDuplicates(sparesEuipType))
    }, [sparesEuipType])

    //Equipment  serial Number List
    useEffect(() => {
        setSerialNoList(removeDuplicates(sparesEuipSn))
    }, [sparesEuipSn])

    //Equipment Sap Kit No List
    useEffect(() => {
        setSapKitNoList(removeSapDuplicates(sparesSapKitNo))
    }, [sparesSapKitNo])

    //Equipment Sap Number List
    useEffect(() => {
        setSapNumberList(removeSapNumberDuplicates(sparesSapNo))
    }, [sparesSapNo])

    //Equipment Serial Number List
    useEffect(() => {
        setSerialNumberList(equipmentSn)
    }, [equipmentSn])

    //Equipment Sub category List
    useEffect(() => {
        const result = processSubCategories(sparesSubcategoryData);
        setSubCategoryList(removeSubCategoryDuplicates(result))
    }, [sparesSubcategoryData])

    //add ALL to sub category list
    function processSubCategories(data) {
        // Filter out entries with empty or invalid subCategory values
        const validData = data.filter(
            item => item.subCategory && item.subCategory.trim() !== ''
        );
    
        // Check if 'All' is already present
        const hasAll = validData.some(item => item.subCategory === 'All');
    
        // Add 'All' if not present
        if (!hasAll) {
            validData.unshift({ subCategory: 'All' });
        }
    
        return validData;
    }

     //remove duplicates for sub category
     const removeSubCategoryDuplicates = (arr) => {
        const uniqueObjects = {};
        arr.forEach(obj => {
            uniqueObjects[obj.subCategory] = obj;
        });
        return Object.values(uniqueObjects);
    };

    //Remove duplicates for equipment type
    const removeEqTypeDuplicates = (arr) => {
        const uniqueObjects = {};
        arr.forEach(obj => {
            uniqueObjects[obj.equipmentType] = obj;
        });
        return Object.values(uniqueObjects);
    };
    //remove duplicate for serial number
    const removeDuplicates = (arr) => {
        const uniqueSerialNumbers = new Set();
        return arr.filter((obj) => {
            const serialNumber = obj.equipmentSerialNumber;
            if (!uniqueSerialNumbers.has(serialNumber)) {
                uniqueSerialNumbers.add(serialNumber);
                return true;
            }
            return false;
        });
    };

    //remove duplicates for SpaKit Number
    const removeSapDuplicates = (arr) => {
        const uniqueObjects = {};
        arr.forEach(obj => {
            uniqueObjects[obj.sapSparesKitNumber] = obj;
        });
        return Object.values(uniqueObjects);
    };

    //remove duplicates for Sap Number
    const removeSapNumberDuplicates = (arr) => {
        const uniqueObjects = {};
        arr.forEach(obj => {
            uniqueObjects[obj.partSapNumber] = obj;
        });
        return Object.values(uniqueObjects);
    };

    const showEditModal = async (record) => {
        let editData = null;
        if (record != null) {
            setEditSpareId(record.id)
            setAssignToPihEquipmentId(record.assignedToPihEquipmentId)
            setParentId(record.parentId)
            setAssignedToEquipmentId(record.assignedToEquipmentId)
            setAssignedToSerialNumber(record.assignedToSerialNumber)
            setIsChecked(record.isPublished)
            setCurrency(record.currency)
            setInitialQty(record.initialQty)
            setNewQty(0)
        }
        setEditValue(true);
        editDetailsForm.setFieldsValue(record);
        setShowAddModal(true);
    }



    const columns = [
        {
            // title: 'S.No',
            dataIndex: 'serialNo',
            key: 'serialNo',
            align: 'center',
            width: '55px',
            render: (text, record, index) => (current - 1) * currentPagesize + index + 1,
        },
        {
            key: 'equipmentType',
            align: 'center',
            width: '30px',
            sorter: (a, b) => a.equipmentType.localeCompare(b.equipmentType),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Equipment Type</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setEquipmentTypeValue(value);
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, equipmentType: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'equipmentType',
            key: 'equipmentType',
            align: 'left',
            // ellipsis: true,
            // sorter: (a, b) => a.equipmentType.localeCompare(b.equipmentType),
        },
        {
            key: 'assignedToSerialNumber',
            align: 'left',
            width: '30px',
            sorter: (a, b) => {
                const aValue = a.assignedToSerialNumber || "";
                const bValue = b.assignedToSerialNumber || "";
                return aValue.localeCompare(bValue);
            },
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Assigned To</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setAssignedToSerialNumberValue(value);
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, assignedToSerialNumber: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'assignedToSerialNumber',
            key: 'assignedToSerialNumber',
            align: 'left',
            render: (text) => {
                if (Array.isArray(text)) {
                    return text.length > 0 ? <div>{text.join(', ')}</div> : <div style={{ paddingLeft: '12px' }}>--</div>;
                }
                return <div style={{ paddingLeft: '12px' }}>--</div>;
            },
        },
        {
            key: 'subCategory',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.subCategory.localeCompare(b.subCategory),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Sub Category</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setEquipmentSubCategory(value)
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, subCategory: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'subCategory',
            key: 'subCategory',
            align: 'left',
            // ellipsis: true
            // sorter: (a, b) => a.equipmentSerialNumber.localeCompare(b.equipmentSerialNumber),
        },
        {
            key: 'sapKitNo',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.sapKitNo.localeCompare(b.sapKitNo),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>SAP KIT No</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSapKitNo(value);
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, sapKitNo: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            // title: 'SAP KIT No',
            dataIndex: 'sapKitNo',
            key: 'sapKitNo',
            align: 'left',
            // sorter: (a, b) => a.sapKitNo.localeCompare(b.sapKitNo),
        },
        {
            key: 'sapNumber',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.sapNumber.localeCompare(b.sapNumber),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>SAP Number</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSapNumber(value);
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, sapNumber: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            // title: 'SAP Number',
            dataIndex: 'sapNumber',
            key: 'sapNumber',
            align: 'left',
            // sorter: (a, b) => a.sapNumber.localeCompare(b.sapNumber),
        },
        {
            key: 'description',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Description</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setDescription(value);
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, description: value });
                            }}
                            style={{ width: '160px' }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            // title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '12%',
            align: 'left',
            ellipsis: true,
            // sorter: (a, b) => a.description.localeCompare(b.description),
            // render: (text) => (
            //     <div className="description-cell">{text?.length > 45 ? text?.slice(0, 45) + "..." : text}</div>
            // ),
        },
        // {
        //     key: 'unit',
        //     align: 'left',
        //     width: '30px',
        //     sorter: (a, b) => a.unit.localeCompare(b.unit),
        // },
        // {
        //     title: (
        //         <div style={{ display: 'flex', flexDirection: 'column' }}>
        //             <div style={{ marginBottom: '4px' }}>Unit</div>
        //             <div className='searchDashboardSpares'>
        //                 <Input
        //                     placeholder="Search"
        //                     onChange={(e) => {
        //                         const value = e.target.value;
        //                         setUnit(value)
        //                         onFetchSparesDashBoardList({ projectId: selectedProject.id, unit: value });
        //                     }}
        //                     allowClear
        //                 />
        //             </div>
        //         </div>
        //     ),
        //     dataIndex: 'unit',
        //     key: 'unit',
        //     align: 'left',
        //     width: '100px',
        //     render: (text, record) => (
        //         <div style={{
        //             textAlign: 'center',
        //             marginLeft: '-10%',
        //             transform: 'translateX(-5%)'
        //         }}>{text}</div>
        //     ),
        //     // ellipsis: true,
        //     // sorter: (a, b) => a.unit.localeCompare(b.unit),
        // },
        {
            key: 'safetyStock',
            align: 'left',
            width: '30px',
            sorter: (a, b) => String(a.safteyStock).localeCompare(String(b.safteyStock)),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Safety Stock</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSafetyStock(value);
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, safteyStock: value });
                            }}
                            style={{ width: '100px' }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'safteyStock',
            key: 'safteyStock',
            align: 'left',
            // sorter: (a, b) => String(a.safteyStock).localeCompare(String(b.safteyStock)),
            render: (text, record) => (
                <div style={{
                    textAlign: 'center',
                    marginLeft: '-10%',
                    transform: 'translateX(-5%)'
                }}>{text}</div>
            ),
        },
        {
            key: 'cost',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.cost.localeCompare(b.cost),
        },

        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Cost</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setCost(value)
                                onFetchSparesDashBoardList({ projectId: selectedProject.id, cost: value });
                            }}
                            style={{ width: '90px' }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'cost',
            key: 'cost',
            align: 'left',
            // width: '7%',
            // sorter: (a, b) => a.cost.localeCompare(b.cost),
            render: (text, record) => (
                <div className="description-cell">{record.currency == 'dollar' ? '$' : record.currency == 'pound' ? '£' : '€'}        {text}</div>
            ),
        },
        {
            title: 'Initial QTY',
            dataIndex: 'initialQty',
            key: 'initialQty',
            align: 'center',
            width: '4%',
        },
        {
            title: 'Current QTY',
            dataIndex: 'initialQty',
            key: 'initialQty',
            align: 'center',
            width: '4%',
            render: (text, record) => {
                const iniQuantity = parseInt(record.initialQty);
                const consumeQuantity = parseInt(record.consumedQty);
                const replaceQuantity = parseInt(record.replacedQty);
                const current = (iniQuantity + replaceQuantity) - consumeQuantity;
                // const totalCostRounded = totalCost.toFixed(2);
                return <span>{current}</span>
            },
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalCost',
            key: 'totalCost',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                const cost = record.cost;
                const quantity = record.initialQty;
                const totalCost = cost * quantity;
                const totalCostRounded = totalCost.toFixed(2);
                return (
                    <span>{record.currency == 'dollar' ? '$' : record.currency == 'pound' ? '£' : '€'} {totalCostRounded}</span>
                );

            },
        },
        {
            title: 'Published',
            key: 'action',
            dataIndex: 'action',
            // fixed: 'right',
            width: '100px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title={(record.isPublished ? "Published" : 'Not Published')} >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover"  >
                                <span className='blinkingDot' style={{ background: (record.isPublished ? 'limegreen' : 'red') }}>
                                    <span className='blinking' style={{ background: (record.isPublished ? 'limegreen' : 'red') }}></span>
                                </span>
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
        {
            // title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Space>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px', marginRight: '5px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showEditModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showDeleteModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                    </Space>
                );
            },
            align: 'center',
            width: '4%',
        }
    ];





    const showModal = () => {
        if (selectedProject) {
            setShowAddSpareModal(true);
        } else {
            notification.warn({ message: 'Select Project to add data.' });
        }
    }

    const handleCancel = () => {
        editDetailsForm.resetFields(); //resetting input fields
        setShowAddModal(false);
        setEditValue(false);
    }


    function isSerialNumberAvailable(data, serialNumber) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].serialNumber === serialNumber) {
                return true;
            }
        }
        return false;
    }

    function filterEquipmentList(data, equipmentType) {
        return data.filter(item => item.equipmentType == equipmentType);
    }
    /** On change function to select Equipment */
    const onChangeSpareEquipmentType = async (value) => {
        spareKitForm.setFieldsValue({ subCategory: '', sapKitNo: '', assignTo: '' });
        addIndSpareForm.setFieldsValue({ subCategory: '', sapKitNo: '', sapNumber: '' });
        // setEquipmentSN('');
        setSubCategory('');
        setIsSerialNoToAssign(false);
        if (value) {
            setEquipmentType(value);
            await onFetchSubcategory({ pihEquipmentType: value, pihEquipmentSubcategory: 'subCategory' });
            await onGetSparesEquipmentKitNo({ sapSparesKitNumber: 'sapSparesKitNumber', pihEquipmentType: value});
            // await onGetSparesEquipmentSn({ pihEquipmentType: value });
        } else {
            setSubCategoryList([]);
            setSapKitNoList([]);
            setSapNumberList([]);
            setEquipmentType('');
            setSubCategory('');
            setEquipmentSapKitNo('');
        }
    }

    /** On change function to select Equipment serial number */
    const onChangeSpareEquipmentSn = async (value) => {
        spareKitForm.setFieldsValue({ sapKitNo: '' });
        addIndSpareForm.setFieldsValue({ sapKitNo: '', sapNumber: '' });
        if (value) {
            const isAvbl = isSerialNumberAvailable(equipmentSn, value);
            if (isAvbl) {
                setSerialNoToAssign(true)
                setIsSerialNoToAssign(false);
                spareKitForm.setFieldsValue({ assignTo: value });
            } else {
                if (value !== 'All') {
                    setIsSerialNoToAssign(true)
                } else {
                    setIsSerialNoToAssign(false)
                }
                setSerialNoToAssign(false)
                spareKitForm.setFieldsValue({ assignTo: '' });
            }
            if (value == 'All' && equipmentType !== '') {
                const filteredEqpList = filterEquipmentList(equipmentSn, equipmentType);
                setSerialNumberList(filteredEqpList)
            } else {
                setSerialNumberList(equipmentSn)
            }
            setEquipmentSN(value);
            await onGetSparesEquipmentKitNo({ sapKitNo: 'sapKitNo', pihEquipmentType: equipmentType, pihEquipmentSn: value });
        } else {
            setSapKitNoList([]);
            setSapNumberList([]);
            setEquipmentSN('');
            setEquipmentSapKitNo('');
            setIsSerialNoToAssign(false)
            setSerialNoToAssign(false)
            spareKitForm.setFieldsValue({ assignTo: '' });
        }
    }

    /** On change function to select Equipment sapKitNo */
    const onChangesapKitNo = async (value) => {
        addIndSpareForm.setFieldsValue({ sapNumber: '' });
        if (value) {
            setEquipmentSapKitNo(value);
            await onGetSparesEquipmentSapNo({ pihEquipmentType: equipmentType, pihEquipmentSapKitNo: value, partSapNumber: 'partSapNumber' });
        } else {
            setSapNumberList([]);
            setEquipmentSapKitNo('');
            setEquipmentSapNumber('');
        }
    }

    // Conver list of data into Excel sheet
    const handleDownloadExcelSheet = async () => {
        if (isExcelSparesListLoading) {
            notification.warning({
                message: 'Wait a moment, Spares List is Loading...'
            });
            return;
        }
        const workbook = new ExcelJS.Workbook();
        const sparesListSheet = workbook.addWorksheet('SparesOrderList');

        sparesListSheet.columns = [
            { header: 'Equipment Type', key: 'equipmentType', width: 18 },
            { header: 'Assigned to Serial Number', key: 'assignedToSerialNumber', width: 25 },
            { header: 'Sub Category', key: 'subCategory', width: 25 },
            { header: 'SAP Kit No', key: 'sapKitNo', width: 20 },
            { header: 'SAP Number', key: 'sapNumber', width: 20 },
            { header: 'Description', key: 'description', width: 30 },
            { header: 'Unit', key: 'unit', width: 10 },
            { header: 'Currency', key: 'currency', width: 10 },
            { header: 'Safety Stock', key: 'safteyStock', width: 15 },
            { header: 'Initial Quantity', key: 'initialQty', width: 15 },
            { header: 'Cost', key: 'cost', width: 10 },
            { header: 'Is Published', key: 'isPublished', width: 12 },
            { header: 'Created At', key: 'createdAt', width: 25 },
        ];

        const headerRow = sparesListSheet.getRow(1);
        headerRow.height = 40;

        headerRow.eachCell((cell) => {
            cell.font = { bold: true };
        });

        excelSparesList.forEach(row => {
            row.isPublished = row.isPublished === 0 ? "No" : "Yes";
            row.createdAt = new Date(row.createdAt).toLocaleString();
            sparesListSheet.addRow(row);
        });

        // Write to buffer
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'sparesList.xlsx';
        link.click();
    };

    const handleAdd = async () => {
        editDetailsForm
            .validateFields()
            .then(async (data) => {
                if (data.equipmentType && data.subCategory && data.sapKitNo && data.sapNumber && data.description
                    //  && data.unit 
                     && data.cost && data.initialQty 
                    //  && data.currency
                    ) {
                    if (editValue) {
                        var payload = {
                            "projectSparePart":
                            {
                                "id": editSpareId,
                                "equipmentType": data.equipmentType,
                                "assignedToSerialNumber": assignedToSerialNumber,
                                "subCategory": data.subCategory,
                                "sapKitNo": data.sapKitNo,
                                "sapNumber": data.sapNumber,
                                "description": data.description,
                                // "unit": data.unit,
                                "safteyStock": data.safteyStock,
                                "cost": data.cost,
                                "initialQty": data.initialQty,
                                "currency": data.currency,
                                "assignedToPihEquipmentId": assignToPihEquipmentId,
                                "assignedToEquipmentId": assignToEquipmentId,
                                "parentId": parentId,
                                "isPublished": isChecked,
                                "projectId": selectedProject.id,
                            }

                        }
                        let res = await onUpdateSpareList({ data: payload, selectedProject: selectedProject })
                    } else {
                        let data = {
                            "name": name,
                            "productCode": productCode,
                            "quantityInStock": initialQuantity,
                            "safetyStock": safetyStock,
                            "projectId": selectedProject.id
                        }
                        // let res = await onAddConsumables(data)
                        if (!isConsumablesLoading) {
                            setBulkUplaodModal(false);
                            setShowAddModal(false);
                            editDetailsForm.resetFields(); //resetting input fields
                        }
                    }
                } else {
                    notification.warning({
                        message: 'Fill all the required fields!'
                    });
                }
            }).catch(err => console.log(err));
    }


    const handlePublishApp = async () => {
        await onPublishDashboardList({ selectedProject: selectedProject })
    }

    useEffect(() => {
        if (!isSparesListLoading) {
            editDetailsForm.resetFields();
            setShowAddModal(false);
            setEditValue(false);
            setIsPublishModalVisible(false);
            setIsKitModalVisible(false);
        }
    }, [isSparesListLoading]);





    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };

    const handlePublish = (record) => {
        setIsPublishModalVisible(true);
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }

    const handlePublishCancel = () => {
        setIsPublishModalVisible(false);
    }

    const handleDeleteOk = async () => {
        await onDeleteDashboardSpare({ id: selectedData.id, selectedProject: selectedProject });
    }



    useEffect(() => {
        if (!isSparesListLoading) {
            setIsDeleteModalVisible(false);
            setSelectedData('');
        }
    }, [isSparesListLoading]);

    const resetSpareKitForm = () => {
        spareKitForm.resetFields();
        setIsKitModalVisible(false);
        setSerialNoList([]);
        setSapKitNoList([]);
        setEquipmentType('');
        // setEquipmentSN('');
        setSubCategory('');
        setEquipmentSapKitNo('');
        setSerialNoToAssign(false);
        setIsSerialNoToAssign(false);
    };

    const handlespareModalCancel = () => {
        resetSpareKitForm();
    };

    const resetAddIndvSpareForm = () => {
        addIndSpareForm.resetFields();
        setShowAddSpareModal(false);
        setSerialNoList([]);
        setSapKitNoList([]);
        setSapNumberList([]);
        setEquipmentType('');
        // setEquipmentSN('');
        setSubCategory('');
        setEquipmentSapKitNo('');
        setEquipmentSapNumber('');
    };

    const handleAddIndvSpareModalCancel = () => {
        resetAddIndvSpareForm();
    };

    const handleSpareAdd = async () => {
        try {
            const data = await spareKitForm.validateFields();

            if (!data.equipmentType || !data.subCategory || !data.sapKitNo || !data.quantity || !data.assignTo) {
                notification.warning({
                    message: 'Fill all the required fields!'
                });
                return;
            }
            const assignedObj = equipmentSn?.find(item => item.serialNumber == data.assignTo);

            if (data.quantity < 1) {
                notification.warning({
                    message: 'Please add quantity to proceed!'
                });
                return;
            }

            const payload = {
                "projectId": selectedProject.id,
                "equipmentType": data.equipmentType,
                "subCategory": data.subCategory,
                "sapKitNo": data.sapKitNo,
                "quantity": data.quantity,
                "assignedToSerialNumber": data.assignTo,
                "assignedToPihEquipmentId": assignedObj.pihEquipmentId,
                "assignedToEquipmentId": assignedObj.equipmentId,
            };
            // const payload = {
            //     "projectId": selectedProject.id,
            //     "equipmentType": data.equipmentType,
            //     "equipmentSerialNumber": data.equipmentSerialNumber,
            //     "sapKitNo": data.sapKitNo,
            //     "quantity": data.quantity,
            //     "assignedToSerialNumber":equipmentSN == 'All' ? data.assignTo : data.equipmentSerialNumber
            // };

            await onAddSpareKit({ payload, selectedProject });
            setTimeout(resetSpareKitForm, 1000);
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddIndvSpare = async () => {
        try {
            const data = await addIndSpareForm.validateFields();

            if (!data.equipmentType || !data.subCategory || !data.sapKitNo || !data.sapNumber || !data.quantity || !data.assignTo) {
                notification.warning({
                    message: 'Fill all the required fields!'
                });
                return;
            }


            const assignedObj = equipmentSn?.find(item => item.pihEquipmentId == data.assignTo);

            if (data.quantity < 1) {
                notification.warning({
                    message: 'Please add quantity to proceed!'
                });
                return;
            }

            const payload = {
                "projectId": selectedProject.id,
                "equipmentType": data.equipmentType,
                "subCategory": data.subCategory,
                "sapKitNo": data.sapKitNo,
                "sapNumber": data.sapNumber,
                "quantity": data.quantity,
                "assignedToPihEquipmentId": assignedObj.pihEquipmentId,
                "assignedToEquipmentId": assignedObj.equipmentId,
                "assignedToSerialNumber": assignedObj.serialNumber
            };

            await onAddIndvSpare({ payload, selectedProject });
            setTimeout(resetAddIndvSpareForm, 1000);
        } catch (err) {
            console.log(err);
        }
    };

    const handleKeyPress = (e) => {
        const charCode = e.charCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className="flex-auto">
                <div className="d-flex mb-3 align-items-center tab-content-title">
                    <Title className="mb-0" level={4}>
                        List of Spares
                    </Title>
                    <div
                        className="new-project-team"
                    >
                        <Text onClick={!isWriteAccess ? showModal : undefined} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add individual spare
                        </Text>
                        <Text onClick={() => !isWriteAccess ? setIsKitModalVisible(true) : undefined} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add Spare Kit
                        </Text>
                        <Text onClick={!isWriteAccess ? handlePublish : undefined} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
                                    <line x1="12" y1="18" x2="12" y2="18"></line>
                                </svg>

                            </span>
                            Publish in App
                        </Text>
                        <Text onClick={handleDownloadExcelSheet} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="currentColor" d="M880 912H144c-17.7 0-32-14.3-32-32V144c0-17.7 14.3-32 32-32h360c4.4 0 8 3.6 8 8v56c0 4.4-3.6 8-8 8H184v656h656V520c0-4.4 3.6-8 8-8h56c4.4 0 8 3.6 8 8v360c0 17.7-14.3 32-32 32M770.87 199.131l-52.2-52.2c-4.7-4.7-1.9-12.8 4.7-13.6l179.4-21c5.1-.6 9.5 3.7 8.9 8.9l-21 179.4c-.8 6.6-8.9 9.4-13.6 4.7l-52.4-52.4l-256.2 256.2c-3.1 3.1-8.2 3.1-11.3 0l-42.4-42.4c-3.1-3.1-3.1-8.2 0-11.3z" /></svg>
                            </span>
                            Export to Excel
                        </Text>
                    </div>

                </div>
                <div className="custom-line" />
            </div>
            <div className='spareOrderDetails'>
                <Table
                    columns={columns}
                    dataSource={sparesList}
                    className="h-100 pipeline-table spares_dashtable table-action-column"
                    scroll={{ x: 2295, y: 'calc(100vh - 300px)' }}
                    loading={isSparesListLoading}
                    pagination={sparesDataCount > 10 ? {
                        position: ['bottomRight'],
                        showSizeChanger: true,
                        total: sparesDataCount,
                        pageSize: currentPagesize,
                        // defaultCurrent: 1,
                        current: current,

                        onChange: async (pageNumber, pageSize) => {
                            setCurrent(pageNumber);
                            setCurrentPagesize(pageSize);
                            let obj = {
                                projectId: selectedProject.id,
                                pageSize: pageSize,
                                pageNumber: pageNumber,
                                equipmentType: equipmentType,
                                assignedToSerialNumber: assignedToSerialNumberValue,
                                subCategory: equipmentSubCategory,
                                sapKitNo: sapKitNo,
                                sapNumber: sapNumber,
                                description: description,
                                unit: unit,
                                safetyStock: safetyStock,
                                cost: cost
                            }
                            await onFetchSparesDashBoardList(obj);

                        }
                    } : false
                    }
                    onChange={(pagination, filters, sorter) => {
                        if (sorter.columnKey && sorter.order) {
                            const sortOrder = sorter.order === 'descend' ? 'DESC' : 'ASC';
                            const sortParam = { projectId: selectedProject.id, [sorter.columnKey]: [sorter.columnKey, sortOrder] };
                            onFetchSparesDashBoardList(sortParam);
                        }
                    }}
                    style={sparesDataCount > 0 ? {} : {overflowY: 'hidden'}}
                />
            </div>


            {/* Add form modal */}
            <Modal
                width="50vw"
                className="pipeline-modal"
                title={editValue ? "Update the spare" : "Create New Spare"}
                centered
                visible={showAddModal}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleAdd} loading={isSparesListLoading}>
                        {!editValue ? (isSparesListLoading ? 'Adding' : 'Add') : (isSparesListLoading ? 'Updating' : 'Update')}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={editDetailsForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={24}>
                            <Col lg={8}>
                                <Form.Item
                                    label="Equipment Type"
                                    name="equipmentType"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New Equipment Type'}
                                        // value={equipmentSerialNumber}
                                        // onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    />
                                    {/* <Select
                                        className='client-select'
                                        placeholder='Select Equipment Type'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                    onChange={onChangeEquipment}
                                    disabled={editValue}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    // }
                                    >
                                        {productHierarchyList && productHierarchyList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                    </Select> */}
                                </Form.Item>
                            </Col>
                            {/* <Col lg={8}>
                                <Form.Item
                                    label="Equipment SI No"
                                    name="equipmentSerialNumber"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New Equipment SI No'}
                                        // value={equipmentSerialNumber}
                                        // onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    /> */}
                                    {/* <Select
                                    className='client-select'
                                    placeholder='Select Equipment SI No'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    // onChange={onChangeEquipment}
                                    disabled={editValue}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {productHierarchyList && productHierarchyList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                </Select> */}
                                {/* </Form.Item>
                            </Col> */}
                            <Col lg={6}>
                            <Form.Item
                                label="Sub Category"
                                name="subCategory"
                                required
                            >
                                <Input
                                        placeholder={'Enter Sub Category'}
                                        // value={equipmentSerialNumber}
                                        // onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    />
                            </Form.Item>
                        </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="SAP KIT No"
                                    name="sapKitNo"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New SAP KIT No'}
                                        // value={equipmentType}
                                        // onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    />
                                    {/* <Select
                                    className='client-select'
                                    placeholder='Select SAP KIT No'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    // onChange={onChangeEquipment}
                                    disabled={editValue}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {productHierarchyList && productHierarchyList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                </Select> */}
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item
                                    label="SAP Number"
                                    name="sapNumber"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New SAP Number'}
                                        value={equipmentType}
                                        onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New Description'}
                                        value={equipmentType}
                                        onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={8}>
                                <Form.Item
                                    label="Unit"
                                    name="unit"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter  Unit'}
                                        value={equipmentType}
                                        onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    />
                                </Form.Item>
                            </Col> */}
                            <Col lg={8}>
                                <Form.Item
                                    label="Saftey Stock"
                                    name="safteyStock"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter Safety Stock'}
                                        value={equipmentType}
                                        onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Cost"
                                    name="cost"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New Cost'}
                                        value={equipmentType}
                                        onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        disabled={editValue}
                                    />
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item
                                    label="Currency"
                                    name="currency"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Currency'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        value={currency}
                                        disabled={editValue}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value='dollar'>Dollar</Option>
                                        <Option value='pound'>Pound</Option>
                                        <Option value='euro'>Euro</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={editValue ? 8 : 8}>
                                <Form.Item
                                    label="Initial QTY"
                                    name="initialQty"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter Initial QTY'}
                                        value={equipmentType}
                                        onChange={(e) => setEquipmentType(e.target.value)}
                                        size="large"
                                        type='number'
                                        disabled={editValue}
                                    />
                                </Form.Item>
                            </Col>
                            {/* {
                                editValue && <Col lg={4}>
                                    <Form.Item
                                        label="Current QTY"
                                        name="initialQty"
                                        required
                                    >
                                        <Input
                                            // placeholder={'Enter New QTY'}
                                            value={equipmentType}
                                            onChange={(e) => setEquipmentType(e.target.value)}
                                            size="large"
                                            type='number'
                                            disabled={editValue}
                                        />
                                    </Form.Item>
                                </Col>
                            } */}

                            {
                                editValue && (
                                    <Col lg={8}>
                                        <Form.Item
                                            label="Add new QTY"
                                            name="newQty"
                                        >
                                            <Input
                                                placeholder='Enter New QTY'
                                                value={newQty}
                                                defaultValue='0'
                                                onChange={(e) => {
                                                    const newQuantity = e.target.value;
                                                    const parsedNewQty = newQuantity === '' ? 0 : parseInt(newQuantity);
                                                    setNewQty(newQuantity);
                                                    editDetailsForm.setFieldsValue({
                                                        initialQty: parseInt(initialQty) + parsedNewQty
                                                    });
                                                }}
                                                onKeyPress={handleKeyPress}
                                                size="large"
                                                type='number'
                                            />
                                        </Form.Item>
                                    </Col>
                                )
                            }

                        </Row>
                    </Form>
                </div>
            </Modal>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Spare"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isSparesListLoading}>
                        {isSparesListLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.equipmentType + " - " + selectedData.equipmentSerialNumber}</b> spare?</Text>
                </div>
            </Modal>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Publish Spare In App"
                centered
                visible={isPublishModalVisible}
                footer={[
                    <Button key="cancel" onClick={handlePublishCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handlePublishApp} loading={isSparesListLoading}>
                        {isSparesListLoading ? 'Publishing' : 'Publish'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to Publish All the spares List</Text>
                </div>
            </Modal>
            <Modal
                width="55vw"
                className="pipeline-modal"
                title="Add Spare Kit"
                centered
                visible={isKitModalVisible}
                footer={[
                    <Button key="cancel" onClick={handlespareModalCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type={!isDisabled ? "primary" : ""} onClick={handleSpareAdd} loading={isSparesListLoading} disabled={isDisabled}>
                        {isSparesListLoading ? 'Adding' : 'Add'}
                    </Button>
                ]}
            >
                <div>
                    <h6>Select Spare Kit : </h6>
                    <Form
                        form={spareKitForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={6}>
                                <Form.Item
                                    label="Equipment Type"
                                    name="equipmentType"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Equipment Type'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeSpareEquipmentType}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isSparesEuipTypeLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isSparesEuipTypeLoading && equipmentTypeList?.map((item, i) =>
                                            <Option key={i} value={item.equipmentType}>{item.equipmentType}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col lg={6}>
                                <Form.Item
                                    label="Equipment SI No"
                                    name="equipmentSerialNumber"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Equipment SI No'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeSpareEquipmentSn}
                                        disabled={!equipmentType}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isSparesEuipSnLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isSparesEuipSnLoading && serialNoList?.map((item, i) =>
                                            <Option key={i} value={item.equipmentSerialNumber}>{item.equipmentSerialNumber}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col lg={6}>
                            <Form.Item
                                label="Sub Category"
                                name="subCategory"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Sub Category'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    // onChange={value => setEquipmentSapNumber(value)}
                                    disabled={!equipmentType}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                        </>
                                    )}
                                >
                                    {isSparesDashSubcategoryLoading && <Option key={'loading'} value={'Loading...'} />}
                                    {!isSparesDashSubcategoryLoading && subCategoryList?.map((item, i) =>
                                        <Option key={i} value={item && item.subCategory}>{item && item.subCategory}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                            <Col lg={6}>
                                <Form.Item
                                    label="SAP KIT No"
                                    name="sapKitNo"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select SAP KIT No'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={value => setEquipmentSapKitNo(value)}
                                        disabled={!equipmentType }
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isSparesSapKitNoLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isSparesSapKitNoLoading && sapKitNoList?.map((item, i) =>
                                            <Option key={i} value={item && item.sapSparesKitNumber}>{item && item.sapSparesKitNumber}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={6}>
                                <Form.Item
                                    label="Quantity"
                                    name="quantity"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New QTY'}
                                        value={equipmentType}
                                        // onChange={(e) => setEquipmentType(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        min={1}
                                        type='number'
                                        size="large" />
                                </Form.Item>
                            </Col>

                            <Col lg={24}>
                                <h6>Assign To : </h6>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    label="Serial No. in Project"
                                    name="assignTo"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Serial No'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        disabled={serialNoToAssign}
                                        // onChange={onChangeSpareEquipmentType}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isEquipmentSnLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isEquipmentSnLoading && serialNumberList?.map((item, i) =>
                                            <Option key={i} value={item.serialNumber}>{item.serialNumber}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {isSerialNoToAssign && <Text style={{ fontSize: '16px', color: "#EF314C" }}>
                        *Please add <b style={{ color: "#8B0000" }}>{equipmentType + " - " + equipmentSN}</b> to the project for assigning spares</Text>}
                    {equipmentSN == 'All' && serialNumberList.length == 0 && <Text style={{ fontSize: '16px', color: "#EF314C" }}>
                        *Please add <b style={{ color: "#8B0000" }}>Serial Number</b> to the project for assigning spares</Text>}
                </div>
            </Modal>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Add Individual Spare"
                centered
                visible={showAddSpareModal}
                footer={[
                    <Button key="cancel" onClick={handleAddIndvSpareModalCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleAddIndvSpare} loading={isSparesListLoading}>
                        {isSparesListLoading ? 'Adding' : 'Add'}
                    </Button>
                ]}
            >
                <div>
                    <h6 >Select Individual Spare</h6>
                    <Form
                        form={addIndSpareForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={8}>
                                <Form.Item
                                    label="Equipment Type"
                                    name="equipmentType"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Equipment Type'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeSpareEquipmentType}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isSparesEuipTypeLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isSparesEuipTypeLoading && equipmentTypeList?.map((item, i) =>
                                            <Option key={i} value={item.equipmentType}>{item.equipmentType}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                            <Form.Item
                                label="Sub Category"
                                name="subCategory"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Sub Category'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    // onChange={value => setEquipmentSapNumber(value)}
                                    disabled={!equipmentType}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                        </>
                                    )}
                                >
                                    {isSparesDashSubcategoryLoading && <Option key={'loading'} value={'Loading...'} />}
                                    {!isSparesDashSubcategoryLoading && subCategoryList?.map((item, i) =>
                                        <Option key={i} value={item && item.subCategory}>{item && item.subCategory}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                            {/* <Col lg={8}>
                                <Form.Item
                                    label="Equipment SI No"
                                    name="equipmentSerialNumber"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Equipment SI No'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeSpareEquipmentSn}
                                        disabled={!equipmentType}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isSparesEuipSnLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isSparesEuipSnLoading && serialNoList?.map((item, i) =>
                                            <Option key={i} value={item.equipmentSerialNumber}>{item.equipmentSerialNumber}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col lg={8}>
                                <Form.Item
                                    label="SAP KIT No"
                                    name="sapKitNo"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select SAP KIT No'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangesapKitNo}
                                        disabled={!equipmentType}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isSparesSapKitNoLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isSparesSapKitNoLoading && sapKitNoList?.map((item, i) =>
                                            <Option key={i} value={item && item.sapSparesKitNumber}>{item && item.sapSparesKitNumber}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item
                                    label="SAP Number"
                                    name="sapNumber"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select SAP Number'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={value => setEquipmentSapNumber(value)}
                                        disabled={!equipmentType || !equipmentSapKitNo}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isSparesSapNoLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isSparesSapNoLoading && sapNumberList?.map((item, i) =>
                                            <Option key={i} value={item && item.partSapNumber}>{item && item.partSapNumber}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item
                                    label="Quantity"
                                    name="quantity"
                                    required
                                >
                                    <Input
                                        placeholder={'Enter New QTY'}
                                        value={equipmentType}
                                        // onChange={(e) => setEquipmentType(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        min={1}
                                        type='number'
                                        size="large" />
                                </Form.Item>
                            </Col>

                            <Col lg={24}>
                                <h6>Assign To</h6>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Serial No. in Project"
                                    name="assignTo"
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Serial No'
                                        showSearch
                                        allowClear
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {isEquipmentSnLoading && <Option key={'loading'} value={'Loading...'}>Loading...</Option>}
                                        {!isEquipmentSnLoading && equipmentSn?.map((item, i) =>
                                            <Option key={i} value={item.pihEquipmentId} label={`${item.serialNumber} ${item?.equipmentType && `(${item.equipmentType})`}`}>
                                                {item.serialNumber} {item?.equipmentType && `(${item.equipmentType})`}
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};


const mapStateToProps = ({ spareDashBoardReducer, equip }) => {
    const sparesList = get(spareDashBoardReducer, 'sparesDashBoardList', []);
    const excelSparesList = get(spareDashBoardReducer, 'ExcelSparesDashBoardList', []);
    const isSparesListLoading = get(spareDashBoardReducer, 'isFetchsparesDashBoardDataLoading', []);
    const isExcelSparesListLoading = get(spareDashBoardReducer, 'isExcelSparesEuipTypeDataLoading', []);
    //add spare kit
    const sparesEuipType = get(spareDashBoardReducer, 'sparesEuipTypeData', []);
    const sparesEuipSn = get(spareDashBoardReducer, 'sparesEuipSnData', []);
    const equipmentSn = get(spareDashBoardReducer, 'euipmentSnData', []);
    const sparesSapKitNo = get(spareDashBoardReducer, 'sparesSapKitNoData', []);
    const sparesSapNo = get(spareDashBoardReducer, 'sparesSapNoData', []);
    const isSparesEuipTypeLoading = get(spareDashBoardReducer, 'isSparesEuipTypeDataLoading', []);
    const isSparesEuipSnLoading = get(spareDashBoardReducer, 'isSparesEuipSnDataLoading', []);
    const isEquipmentSnLoading = get(spareDashBoardReducer, 'isFetchingEuipmentSnDataLoading', []);
    const isSparesSapKitNoLoading = get(spareDashBoardReducer, 'isSparesSapKitNoDataLoading', []);
    const sparesDataCount = get(spareDashBoardReducer, 'sparesDataCount', []);
    const isSparesSapNoLoading = get(spareDashBoardReducer, 'isSparesSapNoDataLoading', []);
    const sparesSubcategoryData = get(spareDashBoardReducer, 'sparesSubcategoryData', []);
    const isSparesDashSubcategoryLoading = get(spareDashBoardReducer, 'isSparesDashSubcategoryLoading', []);
    const equipDetails = get(equip, 'equipData', []);

    return {
        sparesList,
        excelSparesList,
        sparesDataCount,
        isSparesListLoading,
        isExcelSparesListLoading,
        //
        sparesEuipType,
        sparesEuipSn,
        equipmentSn,
        sparesSapKitNo,
        sparesSapNo,
        isSparesEuipTypeLoading,
        isSparesEuipSnLoading,
        isEquipmentSnLoading,
        isSparesSapKitNoLoading,
        isSparesSapNoLoading,
        equipDetails,
        sparesSubcategoryData,
        isSparesDashSubcategoryLoading
    }
}

const mapDispatchToProps = {
    onFetchSparesDashBoardList: fetchSparesDashBoardList,
    onFetchExcelSparesDashBoardList: fetchExcelSparesDashBoardList,
    onGetPihEquipmentTypes: fetchsparesEuipTypeList,
    onGetSparesEquipmentSn: fetchsparesEuipSNList,
    onGetEquipmentSn: fetchEuipSNList,
    onGetSparesEquipmentKitNo: fetchSapKitNoList,
    onGetSparesEquipmentSapNo: fetchSapNoList,
    onAddIndvSpare: addIndvSpare,
    onAddSpareKit: addSpareKit,
    onUpdateSpareList: updateDashboardSpare,
    onPublishDashboardList: publishDashboardList,
    onDeleteDashboardSpare: deleteDashboardSpare,
    onFetchSubcategory: fetchSubcategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(SpareDashboard);