/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Table, Tooltip, Col, Typography, Modal, Form, Input, notification, Button, Select, Divider, Space, Checkbox, Spin } from 'antd';
import { BellOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Option } from 'antd/lib/mentions';
const { Title, Text } = Typography;
import { fetchReportList, addReport, updateReport, deleteReport } from '../redux/reportEmail/action';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
const uuid = require("uuid");
import * as constants from '../utils/constants';
import * as utility from '../utils/utility';

const ReportingEmailPage = (props) => {
    const {
        selectedProject,
        usersList,
        isUserListLoading,
        //
        onFetchReportList,
        onAddReport,
        onUpdateReport,
        onDeleteReport,
        projectTeamMembersAccessList,
        userAccessList

    } = props;
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const [emailModalLoading, setEmailModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [emailData, setEmailData] = useState('');
    const [teamTitle, setTeamTitle] = useState('');
    const [editValue, setEditValue] = useState(false);
    const { Column, ColumnGroup } = Table;
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedTeamMemberData, setSelectedTeamMemberData] = useState('');
    const [emailForm] = Form.useForm();
    const [reportEmails, setReportEmails] = useState([]);

    //
    const [dsrReport, setDsrReport] = useState(false);
    const [ppmReport, setPpmReport] = useState(false);
    const [ppmIssuesReport, setPpmIssuesReport] = useState(false);
    const [communicationsReport, setCommunicationsReport] = useState(false);
    const [builtToStandardReport, setBuiltToStandardReport] = useState(false);
    const [servicetoStandardReport, setServicetoStandardReport] = useState(false);
    const [qcReport, setQcReport] = useState(false);
    const [sparePartsOrder, setSparePartsOrder] = useState(false);
    const [searchKeyExist, setSearchKeyExist] = useState('');
    const [filteredEmails, setFilteredEmails] = useState([]);

    //Reporting Email write access control for normal user
    const isButtonDisabled = () => {
        if (userAccessList.includes('admin'))
            return false;
        if (projectTeamMembersAccessList[0]?.isGuestUser) {
            return true;
        } else if (projectTeamMembersAccessList[0]?.isNormalUser && !projectTeamMembersAccessList[0]?.tabPermissions?.["Reporting Email"]?.write) {
            return true;
        } else {
            return false;
        }
    }
    const isWriteAccess = isButtonDisabled();

    const columns = [
        {
            // title: 'S.No',
            dataIndex: 'serialNo',
            key: 'serialNo',
            align: 'center',
            width: '65px',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            width: '20%',
        },
        // {
        //     title: 'To be listed in DSR ',
        //     dataIndex: 'dsrReport',
        //     key: 'dsrReport',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <Checkbox
        //                 disabled={true}
        //                 checked={record.dsrReport}
        //             >
        //             </Checkbox>
        //         )
        //     }
        // },
        {
            title: 'DSR',
            dataIndex: 'dsrReport',
            key: 'dsrReport',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: 'PPM',
            dataIndex: 'ppmReport',
            key: 'ppmReport',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: 'PPM Issues',
            dataIndex: 'ppmIssuesReport',
            key: 'ppmIssuesReport',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: 'Communications',
            dataIndex: 'communicationsReport',
            key: 'communicationsReport',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: 'Build to Standard',
            dataIndex: 'builtToStandardReport',
            key: 'builtToStandardReport',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: 'Service to Standard',
            dataIndex: 'servicetoStandardReport',
            key: 'servicetoStandardReport',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: 'QC',
            dataIndex: 'qcReport',
            key: 'qcReport',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: 'Spare Parts Order',
            dataIndex: 'sparePartsOrder',
            key: 'sparePartsOrder',
            align: 'center',
            render: (checked) => (checked ? <CheckOutlined /> : ''),
        },
        {
            title: '',
            key: 'action',
            dataIndex: 'action',
            // width: '200px',
            fixed:'right',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showEditModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Member">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '12px' }}>
                                <DeleteOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showDeleteModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];

    useEffect(() => {
        if (selectedProject && selectedProject.id) {
            onFetchReportList(selectedProject);
        }
    }, [selectedProject]);

    useEffect(() => {
        if (usersList && usersList.length > 0) {
            const user = usersList[0];
            if (user.config && user.config.reportDestinationEmails) {
                const reportDestinationEmails = user.config.reportDestinationEmails;
                if (reportDestinationEmails) {
                    setReportEmails(processReportDestinationEmails(reportDestinationEmails));
                    setFilteredEmails(processReportDestinationEmails(reportDestinationEmails));
                }
            } else {
                setReportEmails([]);
                setFilteredEmails([]);
            }
        } else {
            setReportEmails([]);
            setFilteredEmails([]);
        }
    }, [usersList, selectedProject]);


    const processReportDestinationEmails = (reportDestinationEmails) => {
        if (!reportDestinationEmails) {
            return [];
        }

        const emailReportMap = {};

        Object.keys(reportDestinationEmails).forEach(reportType => {
            reportDestinationEmails[reportType].forEach(email => {
                if (!emailReportMap[email]) {
                    emailReportMap[email] = {
                        email,
                        dsrReport: '',
                        ppmReport: '',
                        ppmIssuesReport: '',
                        communicationsReport: '',
                        builtToStandardReport: '',
                        servicetoStandardReport: '',
                        qcReport: '',
                        sparePartsOrder: '',
                    };
                }

                switch (reportType) {
                    case '1':
                        emailReportMap[email].dsrReport = "dsrReport";
                        break;
                    case '2':
                        emailReportMap[email].ppmReport = "ppmReport";
                        break;
                    case '3':
                        emailReportMap[email].ppmIssuesReport = "ppmIssuesReport";
                        break;
                    case '4':
                        emailReportMap[email].communicationsReport = "communicationsReport";
                        break;
                    case '5':
                        emailReportMap[email].builtToStandardReport = "builtToStandardReport";
                        break;
                    case '6':
                        emailReportMap[email].servicetoStandardReport = "servicetoStandardReport";
                        break;
                    case '7':
                        emailReportMap[email].sparePartsOrder = "sparePartsOrder";
                        break;
                    case '8':
                        emailReportMap[email].qcReport = "qcReport";
                        break;
                    default:
                        break;
                }
            });
        });

        return Object.values(emailReportMap);
    };

    const showModal = () => {
        if (selectedProject) {
            setTeamTitle('Add Email');
            setEmailModalVisible(true);
        } else {
            notification.warn({ message: 'Select project to add new email.' });
        }
    }

    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedTeamMemberData(record)
    };

    const showEditModal = async (record, edit) => {

        let emailDt = {};
        if (record !== '') {
            emailDt = {
                email: record.email,
                reportEmailAccess:
                    [
                        record.dsrReport,
                        record.ppmReport,
                        record.ppmIssuesReport,
                        record.communicationsReport,
                        record.builtToStandardReport,
                        record.servicetoStandardReport,
                        record.qcReport,
                        record.sparePartsOrder
                    ]
            };
            emailForm.setFieldsValue(emailDt);
        }
        setEmailData(record.email)
        setEditValue(true);
        setTeamTitle('Update Email');
        setEmailModalVisible(true);
    };

    const handleCancel = () => {
        emailForm.resetFields();
        setEmailModalVisible(false);
        setEditValue(false);
    }

    //Add emails to destination
    function addEmailToReportDestinationEmails(userList, keys, email) {
        userList.forEach(user => {
            if (!user.config) {
                user.config = {};
            }

            if (!user.config["reportDestinationEmails"]) {
                user.config["reportDestinationEmails"] = {};
            }

            const reportDestinationEmails = user.config.reportDestinationEmails;

            keys.forEach(key => {
                if (reportDestinationEmails[key]) {
                    if (!reportDestinationEmails[key].includes(email)) {
                        reportDestinationEmails[key].push(email);
                    }
                } else {
                    reportDestinationEmails[key] = [email];
                }
            });
        });

        return userList;
    }



    //update the email
    function updateEmailInReportDestinationEmails(userList, keys, oldEmail, newEmail) {

        userList.forEach(user => {
            if (!user.config.reportDestinationEmails) {
                user.config.reportDestinationEmails = {};
            }

            const reportDestinationEmails = user.config.reportDestinationEmails;

            Object.keys(reportDestinationEmails).forEach(key => {
                const emailList = reportDestinationEmails[key];
                const emailIndex = emailList.indexOf(oldEmail);

                if (keys.includes(key)) {
                    // If key is in specified keys and email is found, update it
                    if (emailIndex !== -1) {
                        emailList[emailIndex] = newEmail;
                    } else if (!emailList.includes(newEmail)) {
                        // If email is not found, add the newEmail
                        emailList.push(newEmail);
                    }
                } else {
                    // If key is not in specified keys and email is found, remove it
                    if (emailIndex !== -1) {
                        emailList.splice(emailIndex, 1);
                    }
                }
            });

            // Ensure the new email is added to all specified keys
            keys.forEach(key => {
                if (!reportDestinationEmails[key]) {
                    reportDestinationEmails[key] = [newEmail];
                } else if (!reportDestinationEmails[key].includes(newEmail)) {
                    reportDestinationEmails[key].push(newEmail);
                }
            });
        });

        return userList;
    }


    //remove emails from destination
    function removeEmailFromReportDestinationEmails(userList, keys, email) {
        userList.forEach(user => {
            const reportDestinationEmails = user.config.reportDestinationEmails;

            keys.forEach(key => {
                if (reportDestinationEmails[key]) {
                    const emailIndex = reportDestinationEmails[key].indexOf(email);
                    if (emailIndex !== -1) {
                        reportDestinationEmails[key].splice(emailIndex, 1);
                        // If the array is empty after removal, optionally delete the key
                        if (reportDestinationEmails[key].length === 0) {
                            delete reportDestinationEmails[key];
                        }
                    }
                }
            });
        });
        return userList;
    }


    const reportMapping = {
        "dsrReport": "1",
        "ppmReport": "2",
        "ppmIssuesReport": "3",
        "communicationsReport": "4",
        "builtToStandardReport": "5",
        "servicetoStandardReport": "6",
        "sparePartsOrder": "7",
        "qcReport": "8"
    };

    const handleOk = async () => {
        if (!editValue) {
            emailForm
                .validateFields()
                .then((data) => {
                    if (data.reportEmailAccess.length && data.email) {
                        data.reportEmailAccess = data.reportEmailAccess.map(report => reportMapping[report]);
                        const obj = addEmailToReportDestinationEmails(usersList, data.reportEmailAccess, data.email);
                        onAddReport({ data: obj[0], selectedProject: selectedProject });
                    } else {
                        notification.warning({
                            message: 'Please fill all the required fields!'
                        });
                        return;
                    }
                    setTimeout(() => {
                        emailForm.resetFields();
                        setEmailModalVisible(false);
                        setEditValue(false);
                    }, 1000)
                });
        } else {
            emailForm
                .validateFields()
                .then((data) => {
                    if (data.reportEmailAccess.length && data.email) {
                        data.reportEmailAccess = data.reportEmailAccess.map(report => reportMapping[report]);
                        const obj = updateEmailInReportDestinationEmails(usersList, data.reportEmailAccess, emailData, data.email);
                        onUpdateReport({ data: obj[0], selectedProject: selectedProject });
                    } else {
                        notification.warning({
                            message: 'Please fill all the required fields!'
                        });
                        return;
                    }
                    setTimeout(() => {
                        emailForm.resetFields();
                        setEmailModalVisible(false);
                        setEditValue(false);
                    }, 1000)
                });
        }

    }



    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedTeamMemberData('');
    }

    const handleDeleteOk = () => {
        if (selectedTeamMemberData.email) {
            const obj = removeEmailFromReportDestinationEmails(usersList, ["1", "2", "3", "4", "5", "6", "7", "8"], selectedTeamMemberData.email)
            onDeleteReport({ data: obj[0], selectedProject: selectedProject });
        }
        setTimeout(() => {
            setIsDeleteModalVisible(false);
            setSelectedTeamMemberData('');
        }, 1000)
    }

    const onReportSearch = (e) => {
        const searchValue = e.target.value;
        setSearchKeyExist(searchValue);
        if (searchValue) {
            const filtered = reportEmails.filter(email => email.email.toLowerCase().includes(searchValue.toLowerCase()));
            setFilteredEmails(filtered);
        } else {
            setFilteredEmails(reportEmails);
        }
    };


    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden ">
            <div className="flex-auto">
                <div className="d-flex mb-3 align-items-center tab-content-title">
                    <Title className="mb-0" level={4}>
                        Reports
                    </Title>
                    <div className='searchConsumabels'>
                        <Input
                            placeholder='Search By Email'
                            onChange={onReportSearch}
                            value={searchKeyExist}
                            allowClear
                        />
                    </div>
                    <div
                        className="new-project-team"
                    >
                        <Text onClick={!isWriteAccess ? showModal : undefined} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add Email
                        </Text>
                    </div>
                </div>
                <div className="custom-line" />
            </div>
            <Spin spinning={isUserListLoading}>
                <div className='addTeamDetails'>
                    {filteredEmails.length > 0 ? (
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={filteredEmails}
                            className="h-100 pipeline-table table-action-column"
                            scroll={{ x: filteredEmails.length > 0 ? 1500 : 0, y: 'calc(100vh - 290px)' }}
                            loading={isUserListLoading}
                        />
                    ) : (
                        <Table
                            pagination={false}
                                dataSource={[...filteredEmails]}
                                loading={isUserListLoading}
                            />
                    )}
                </div>
            </Spin>

            <Modal
                width="35vw"
                title={teamTitle}
                centered
                visible={emailModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isUserListLoading}>
                        {!editValue ? (isUserListLoading ? 'Adding' : 'Add') : (isUserListLoading ? 'Updating' : 'Update')}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        getcontainer={false}
                        form={emailForm}
                    >
                        <Row gutter={24}>
                            <Col lg={24}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter an Email.',
                                        },
                                        {
                                            pattern: true
                                                ? constants.EMAIL_PATTERN
                                                : undefined,
                                            message: 'Provide a valid Email.',
                                        }
                                    ]}
                                    normalize={(value) => utility.trimSpacesInValues(value)}
                                >
                                    <Input size="large" placeholder="Enter Email" />
                                </Form.Item>
                            </Col>

                            <Col lg={24}>
                                <p style={{ marginTop: '25px' }}>

                                </p>
                            </Col>
                        </Row>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="mb-0"
                            label="Report Email Access"
                            name="reportEmailAccess"
                            rules={[
                                {
                                    required: true,
                                    message: 'At least one Report Email Access is required.',
                                },
                            ]}
                        >
                            <Checkbox.Group className="w-100">
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="dsrReport"
                                            checked={dsrReport}
                                            onChange={(e) => setDsrReport(e.target.checked)}
                                        >
                                            DSR
                                        </Checkbox>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="ppmReport"
                                            checked={ppmReport}
                                            onChange={(e) => setPpmReport(e.target.checked)}
                                        >
                                            PPM
                                        </Checkbox>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="ppmIssuesReport"
                                            checked={ppmIssuesReport}
                                            onChange={(e) => setPpmIssuesReport(e.target.checked)}
                                        >
                                            PPM issues
                                        </Checkbox>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="communicationsReport"
                                            checked={communicationsReport}
                                            onChange={(e) => setCommunicationsReport(e.target.checked)}
                                        >
                                            Communications
                                        </Checkbox>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="builtToStandardReport"
                                            checked={builtToStandardReport}
                                            onChange={(e) => setBuiltToStandardReport(e.target.checked)}
                                        >
                                            Built to standard
                                        </Checkbox>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="servicetoStandardReport"
                                            checked={servicetoStandardReport}
                                            onChange={(e) => setServicetoStandardReport(e.target.checked)}
                                        >
                                            Service to standard
                                        </Checkbox>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="qcReport"
                                            checked={qcReport}
                                            onChange={(e) => setQcReport(e.target.checked)}
                                        >
                                            QC
                                        </Checkbox>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                                        <Checkbox
                                            value="sparePartsOrder"
                                            checked={sparePartsOrder}
                                            onChange={(e) => setSparePartsOrder(e.target.checked)}
                                        >
                                            Spare Parts Order
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                    </Form>
                </div>
            </Modal>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Team Member"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isUserListLoading}>
                        {isUserListLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedTeamMemberData && selectedTeamMemberData.email}</b>?</Text>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ equip, reportEmailReducer }) => {
    const isUserListLoading = get(reportEmailReducer, 'isFetchReportEmailDataLoading', false);
    const usersList = get(reportEmailReducer, 'reportEmailData', []);

    return {
        isUserListLoading,
        usersList
    }
}

const mapDispatchToProps = {
    onFetchReportList: fetchReportList,
    onAddReport: addReport,
    onUpdateReport: updateReport,
    onDeleteReport: deleteReport
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingEmailPage);