/* eslint-disable linebreak-style */
import {
  ADD_EQUIPMENT_DETAILS,
  ADD_EQUIPMENT_DETAILS_FAILURE,
  ADD_EQUIPMENT_DETAILS_SUCCESS,
  ADD_TEAM_MEMBER,
  ADD_TEAM_MEMBER_FAILURE,
  ADD_TEAM_MEMBER_SUCCESS,
  DELETE_EQUIPMENT_DETAILS,
  DELETE_EQUIPMENT_DETAILS_FAILURE,
  DELETE_EQUIPMENT_DETAILS_SUCCESS,
  DELETE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER_FAILURE,
  DELETE_TEAM_MEMBER_SUCCESS,
  FETCH_EQUIPMENT_CATEGORY,
  FETCH_EQUIPMENT_CATEGORY_FAILURE,
  FETCH_EQUIPMENT_CATEGORY_SUCCESS,
  FETCH_EQUIPMENT_DETAILS,
  FETCH_EQUIPMENT_DETAILS_FAILURE,
  FETCH_EQUIPMENT_DETAILS_SUCCESS,
  FETCH_EQUIPMENT_LIST,
  FETCH_EQUIPMENT_LIST_FAILURE,
  FETCH_EQUIPMENT_LIST_SUCCESS,
  FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS,
  FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_FAILURE,
  FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_SUCCESS,
  FETCH_TEAM_MEMBERS_FOR_PROJECT,
  FETCH_TEAM_MEMBERS_FOR_PROJECT_FAILURE,
  FETCH_TEAM_MEMBERS_FOR_PROJECT_SUCCESS,
  FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT,
  FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_FAILURE,
  FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_SUCCESS,
  GET_CHECKS_DETAILS,
  GET_CHECKS_DETAILS_FAILURE,
  GET_CHECKS_DETAILS_SUCCESS,
  GET_CHECKS_LOGS_DETAILS,
  GET_CHECKS_LOGS_DETAILS_FAILURE,
  GET_CHECKS_LOGS_DETAILS_SUCCESS,
  GET_ALL_USERS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_SUCCESS,
  SET_EQUIPMENT_DETAILS_UPDATE,
  UPDATE_EQUIPMENT_DETAILS,
  UPDATE_EQUIPMENT_DETAILS_FAILURE,
  GET_ALL_NON_COGNITO_USERS,
  GET_ALL_NON_COGNITO_USERS_SUCCESS,
  GET_ALL_NON_COGNITO_USERS_FAILURE,
  ADD_NON_COGNITO_USERS,
  ADD_NON_COGNITO_USERS_SUCCESS,
  ADD_NON_COGNITO_USERS_FAILURE
} from './types';

const fetchEquipList = () => ({
  type: FETCH_EQUIPMENT_LIST,
});

const fetchEquipListSuccess = (payload) => ({
  type: FETCH_EQUIPMENT_LIST_SUCCESS, ...payload,
});

const fetchEquipListFailure = () => ({
  type: FETCH_EQUIPMENT_LIST_FAILURE,
});

const addEquipDetails = (payload) => ({
  type: ADD_EQUIPMENT_DETAILS,
  payload
});

const addEquipDetailsSuccess = (payload) => ({
  type: ADD_EQUIPMENT_DETAILS_SUCCESS,
  ...payload
});

const addEquipDetailsFailure = () => ({
  type: ADD_EQUIPMENT_DETAILS_FAILURE
});

const fetchEquipDetails = (payload) => ({
  type: FETCH_EQUIPMENT_DETAILS, ...payload
});

const fetchEquipDetailsSuccess = (payload) => ({
  type: FETCH_EQUIPMENT_DETAILS_SUCCESS,
  ...payload
});

const fetchEquipDetailsFailure = () => ({
  type: FETCH_EQUIPMENT_DETAILS_FAILURE
});

const setUpdateEquipDetails = (payload) => ({
  type: SET_EQUIPMENT_DETAILS_UPDATE, ...payload,
});

const updateEquipDetails = (payload) => ({
  type: UPDATE_EQUIPMENT_DETAILS, ...payload,
});

const setUpdateEquipDetailsFailure = (payload) => ({
  type: UPDATE_EQUIPMENT_DETAILS_FAILURE, ...payload,
});

const deleteEquipDetails = (payload) => ({
  type: DELETE_EQUIPMENT_DETAILS, ...payload,
});

const deleteEquipDetailsSuccess = (payload) => ({
  type: DELETE_EQUIPMENT_DETAILS_SUCCESS, ...payload,
});

const deleteEquipDetailsFailure = (payload) => ({
  type: DELETE_EQUIPMENT_DETAILS_FAILURE, ...payload,
});

const getChecksDetails = (payload) => ({
  type: GET_CHECKS_DETAILS,
  ...payload
});

const getChecksDetailsSuccess = (payload) => ({
  type: GET_CHECKS_DETAILS_SUCCESS,
  ...payload
});

const getChecksDetailsFailure = () => ({
  type: GET_CHECKS_DETAILS_FAILURE
});


const fetchEquipMaintenanceIntervalDetails = (id) => ({
  type: FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS, id
});

const fetchEquipMaintenanceIntervalDetailsSuccess = (payload) => ({
  type: FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_SUCCESS,
  ...payload
});

const fetchEquipMaintenanceIntervalDetailsFailure = () => ({
  type: FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_FAILURE
});


const getChecksLogDetails = (payload) => ({
  type: GET_CHECKS_LOGS_DETAILS,
  ...payload
});

const getChecksLogDetailsSuccess = (payload) => ({
  type: GET_CHECKS_LOGS_DETAILS_SUCCESS,
  ...payload
});

const getChecksLogDetailsFailure = () => ({
  type: GET_CHECKS_LOGS_DETAILS_FAILURE
});

const getAllCognitoUsers = () => ({
  type: GET_ALL_USERS,
});

const getAllUsersSuccess = (payload) => ({
  type: GET_ALL_USERS_SUCCESS,
  ...payload
});

const getAllUsersFailure = () => ({
  type: GET_ALL_USERS_FAILURE
});


const fetchEquipCategory = () => ({
  type: FETCH_EQUIPMENT_CATEGORY, 
});

const fetchEquipCategorySuccess = (payload) => ({
  type: FETCH_EQUIPMENT_CATEGORY_SUCCESS,
  ...payload
});

const fetchEquipCategoryFailure = () => ({
  type: FETCH_EQUIPMENT_CATEGORY_FAILURE
});

const fetchTeamMembersForProject = (payload) => ({
  type: FETCH_TEAM_MEMBERS_FOR_PROJECT,
  ...payload
});

const fetchTeamMembersForProjectSuccess = (payload) => ({
  type: FETCH_TEAM_MEMBERS_FOR_PROJECT_SUCCESS, 
  ...payload
});

const fetchTeamMembersForProjectFailure = () => ({
  type: FETCH_TEAM_MEMBERS_FOR_PROJECT_FAILURE,
});
//TEAM ACCESS 
const fetchTeamMembersAccessForProject = (payload) => ({
  type: FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT,
  ...payload
});

const fetchTeamMembersAccessForProjectSuccess = (payload) => ({
  type: FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_SUCCESS,
  ...payload
});

const fetchTeamMembersAccessForProjectFailure = () => ({
  type: FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_FAILURE,
});

const deleteTeamMember = (payload) => ({
  type: DELETE_TEAM_MEMBER,
  ...payload
});

const deleteTeamMemberSuccess = (payload) => ({
  type: DELETE_TEAM_MEMBER_SUCCESS, 
  ...payload
});

const deleteTeamMemberFailure = () => ({
  type: DELETE_TEAM_MEMBER_FAILURE,
});

const addTeamMember = (payload) => ({
  type: ADD_TEAM_MEMBER,
  payload
});

const addTeamMemberSuccess = (payload) => ({
  type: ADD_TEAM_MEMBER_SUCCESS,
  ...payload
});

const addTeamMemberFailure = () => ({
  type: ADD_TEAM_MEMBER_FAILURE
});

const getAllNonCognitoUsers = () => ({
  type: GET_ALL_NON_COGNITO_USERS,
});

const getAllNonCognitoUsersSuccess = (payload) => ({
  type: GET_ALL_NON_COGNITO_USERS_SUCCESS,
  ...payload
});

const getAllNonCognitoUsersFailure = () => ({
  type: GET_ALL_NON_COGNITO_USERS_FAILURE
});

const addNonCognitoUser = (payload) => ({
  type: ADD_NON_COGNITO_USERS,
  payload
});

const addNonCognitoUserSuccess = (payload) => ({
  type: ADD_NON_COGNITO_USERS_SUCCESS,
  ...payload
});

const addNonCognitoUserFailure = () => ({
  type: ADD_NON_COGNITO_USERS_FAILURE
});

export {
  fetchEquipList,
  fetchEquipListSuccess,
  fetchEquipListFailure,
  addEquipDetails,
  addEquipDetailsSuccess,
  addEquipDetailsFailure,
  fetchEquipDetails,
  fetchEquipDetailsSuccess,
  fetchEquipDetailsFailure,
  setUpdateEquipDetails,
  setUpdateEquipDetailsFailure,
  updateEquipDetails,
  deleteEquipDetails,
  deleteEquipDetailsSuccess,
  deleteEquipDetailsFailure,
  getChecksDetails,
  getChecksDetailsSuccess,
  getChecksDetailsFailure,
  fetchEquipMaintenanceIntervalDetails,
  fetchEquipMaintenanceIntervalDetailsFailure,
  fetchEquipMaintenanceIntervalDetailsSuccess,
  getChecksLogDetails,
  getChecksLogDetailsSuccess,
  getChecksLogDetailsFailure,
  getAllCognitoUsers,
  getAllUsersSuccess,
  getAllUsersFailure,
  fetchEquipCategory,
  fetchEquipCategoryFailure,
  fetchEquipCategorySuccess,
  fetchTeamMembersForProject,
  fetchTeamMembersForProjectSuccess,
  fetchTeamMembersForProjectFailure,
  fetchTeamMembersAccessForProject,
  fetchTeamMembersAccessForProjectSuccess,
  fetchTeamMembersAccessForProjectFailure,
  deleteTeamMember,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure,
  addTeamMember,
  addTeamMemberSuccess,
  addTeamMemberFailure,
  getAllNonCognitoUsers,
  getAllNonCognitoUsersSuccess,
  getAllNonCognitoUsersFailure,
  addNonCognitoUser,
  addNonCognitoUserSuccess,
  addNonCognitoUserFailure
}