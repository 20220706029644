/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_PROJECTS, SET_PROJECTS,
  SET_PROJECT_FAILURE,
  SUBMIT_PROJECT,
  UPDATE_PROJECT,
  SET_PROJECT_ADD,
  SET_PROJECT_UPDATE
} from '../types';

const initialState = {
  isProjectLoading: false,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTS: {
      const Projects = get(action, ['response', 'data'], []);
      const projectData = [];
      for (let i = 0; i < Projects.length; i += 1) {
        const id = get(Projects[i], 'id', 0);
        const projectName = get(Projects[i], 'projectName', '');
        const projectId = get(Projects[i], 'projectId', '');
        const manager = get(Projects[i], 'manager', '');
        const startDate = get(Projects[i], 'startDate', '');
        const contractId = get(Projects[i], 'contractId', '');
        const pihRefId = get(Projects[i], 'pihRefId', '');
        const contractor = get(Projects[i], 'contractor', '');
        const location = get(Projects[i], 'location', '');
        const clientId = get(Projects[i], 'clientId', 0);
        const clientName = get(Projects[i], 'clientName', '');
        const endDate = get(Projects[i], 'endDate', '');
        const config = get(Projects[i], 'config', '');
        const members = get(Projects[i], 'teamMembers', '');
        const isGuestUser = get(Projects[i], 'isGuestUser', '');
        const selectedTabs = get(Projects[i], 'selectedTabs', '');
        const projectStatus = get(Projects[i], 'projectStatus', '');
        if (projectId !== '') {
          projectData[i] = {
            clientId,
            clientName,
            contractId,
            contractor,
            endDate,
            id,
            location,
            manager,
            pihRefId,
            projectId,
            projectName,
            startDate,
            config,
            projectStatus,
            members,
            isGuestUser,
            selectedTabs
          };
        }
      }

      return {
        ...state,
        projectData,
        isProjectLoading: false,
      };
    }

    case FETCH_PROJECTS: {
      return {
        ...state,
        isProjectLoading: true,
      };
    }

    case SET_PROJECT_FAILURE: {
      return {
        ...state,
        isProjectLoading: false,
      };
    }

    case SUBMIT_PROJECT: {
      return {
        ...state,
        isProjectLoading: true
      };
    }

    case UPDATE_PROJECT: {
      return {
        ...state,
      };
    }

    case SET_PROJECT_ADD: {
      const projectArr = get(action, 'responseData', {});
      const clientName = get(action, 'clientName', {});
      const projData = get(state, 'projectData', []);
      projectArr.clientName = clientName
      const newData = [...projData, projectArr];
      return {
        ...state,
        projectData: newData,
        isProjectLoading: false
      }
    }

    case SET_PROJECT_UPDATE: {
      const projectArr = get(action, 'responseData', {});
      const id = get(action, 'id', {});
      const projData = get(state, 'projectData', []);

      var objIndex = projData.findIndex((obj => obj.id == id));
      //Update object's property.
      projData[objIndex].startDate = projectArr.startDate
      projData[objIndex].endDate = projectArr.endDate
      projData[objIndex].config = projectArr.config
      projData[objIndex].projectStatus = projectArr.projectStatus
      const newData = [...projData];
      return {
        ...state,
        projectData: newData,
        isProjectLoading: false
      }
    }

    default: {
      return state;
    }
  }
};

export default projectReducer;
