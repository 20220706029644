/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Typography, Form, Row, Col, Table, Modal, Tooltip, Select, Input, Spin, Button, Upload, notification
} from 'antd';
import AddNewStation from './AddNewStation';
import { connect } from 'react-redux';
import Item from 'antd/lib/list/Item';
import get from 'lodash/get';
import { getProjectStationDetails, addProjectStationDetails, updateSowStationName, deleteSowStation } from '../redux/stationDetails/action';
import AddNewTest from './AddNewTest';
import { addTestDetails, deleteTestDetails, fetchStationTestDetails, updateTestDetails, fetchTestTypes, fetchStationName, uploadITPAddstationFile } from '../redux/testDetails/action';
import {
    EditOutlined, DeleteOutlined, CloseOutlined, CheckOutlined, LoadingOutlined, DeleteFilled, CloudUploadOutlined
} from '@ant-design/icons';
import { fetchSowDetails } from '../redux/sowDetails/actions';
import WeldNumberGeneration from './WeldNumberGeneration';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import CopyStationTest from './CopyStationTest';

const { Title, Text } = Typography;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ left: "5px", bottom: "3px", color: '#ef314c' }} spin />;

const criteriaListValue = [
    {
        id: 1,
        value: "Accept/Reject/NA",
        valueType: "ACCEPT_OR_REJECT_OR_NA",
        types: [
            {
                id: 1,
                value: "Accept/Reject/NA",
                valueType: "ACCEPT_OR_REJECT_OR_NA"
            }
        ]
    },
    {
        id: 2,
        value: "Value",
        valueType: "Value",
        types: [
            // {
            //     id: 1,
            //     valueType: "NONE",
            //     value: ""
            // },
            // {
            //     id: 2,
            //     valueType: "DEGREE_CELSIUS",
            //     value: "°C"
            // },
            // {
            //     id: 3,
            //     valueType: "MICRO_METER",
            //     value: "μm"
            // },
            // {
            //     id: 4,
            //     valueType: "MILLI_METER",
            //     value: "mm"
            // },
            // // {
            // //     id: 5,
            // //     valueType: "ACCEPT_OR_REJECT_OR_NA",
            // //     value: "Accept/Reject/NA"
            // // },
            // {
            //     id: 6,
            //     valueType: "MICRO_SIEMENS",
            //     value: "μS/cm"
            // },
            // {
            //     id: 7,
            //     valueType: "MICROGRAM_CENTIMETERSQUARE",
            //     value: "μg/cm²"
            // },
            // {
            //     id: 8,
            //     valueType: "RATING",
            //     value: "Rating"
            // },
            // {
            //     id: 9,
            //     valueType: "NEWTON_PER_MM",
            //     value: "N/mm"
            // },
            // {
            //     id: 10,
            //     valueType: "JOULE_PER_MM",
            //     value: "J/mm"
            // },
            // {
            //     id: 11,
            //     valueType: "MEGAPASCAL",
            //     value: "MPa"
            // },
            // {
            //     id: 12,
            //     valueType: "PERCENTAGE",
            //     value: "%"
            // },
            // {
            //     id: 13,
            //     valueType: "KILOVOLTS_PER_MM",
            //     value: "KV/mm"
            // },
            // {
            //     id: 14,
            //     valueType: "MILLIGRAMS_PER_SQUARE_METER",
            //     value: "mg/m²"
            // },
            // {
            //     id: 15,
            //     valueType: "MILLIGRAMS_PER_SQUARE_MM",
            //     value: "N/mm²"
            // },
            // {
            //     id: 16,
            //     valueType: "MINUTES",
            //     value: "mins"
            // },
            // {
            //     id: 17,
            //     valueType: "SECONDS",
            //     value: "secs"
            // },
            // {
            //     id: 18,
            //     valueType: "THOUSANDTHS_OF_INCH",
            //     value: "mils"
            // },
            // {
            //     id: 19,
            //     valueType: "MICRO_OUNCES_PER_SQUARE_INCH",
            //     value: "μoz/in²"
            // },
            // {
            //     id: 20,
            //     valueType: "MICRO_SIEMENS_PER_INCH",
            //     value: "μS/in"
            // },
            // {
            //     id: 21,
            //     valueType: "POUNDS_FORCE_PER_INCH",
            //     value: "lbf/in"
            // }
        ]

    }
];
const ITP = (props) => {

    const { stationList,
        setStationList,
        selectedProject,
        onGetProjStationDetails,
        projStationDetails,
        isStationsLoading,
        onAddProjStationDetails,
        onAddTestDetails,
        onGetStationTestDetails,
        projStationTestData,
        testTypesList,
        onDeleteTestDetails,
        onFetchSowDetails,
        sowDetails,
        onUpdateStationName,
        onUpdateTestDetails,
        onDeleteSowStation,
        onFetchTestTypes,
        displayStr,
        onUploadITPAddstation,
        isUploadAddstationLoading,
        onFetchStationName,
        projestStnNames,
        isStnNamesLoading,
        isSowLoading,
        projectTeamMembersAccessList,
        userAccessList
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
    const [isTestModalVisible, setIsTestModalVisible] = useState(false);
    const [itpData, setItpData] = useState([]);
    const [projStationTestList, SetProjStationTestList] = useState([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedTestData, setSelectedTestData] = useState('');
    const [locationData, setLocationData] = useState([]);
    const [projectIdValue, setProjectIdValue] = useState(false);
    const [location, setLocation] = useState();
    const [isEditStationName, setIsEditStationName] = useState(false);
    const [editId, setEditId] = useState(null);
    const [newStationName, setNewStationName] = useState('');
    const [loader, setLoader] = useState(false);

    const [loadingBtn, setLoadingBtn] = useState(false);
    const [isDeleteSowStation, setIsDeleteSowStation] = useState(false);
    const [deleteStationData, setDeleteStationData] = useState('');

    //for adding new station
    const [locationRef, setLocationRef] = useState();
    const [selectedLocation, setSelectedLocation] = useState(null);


    const [editStationForm] = Form.useForm();
    const [editStationCopyForm] = Form.useForm();
    const [selectStation] = Form.useForm();
    const [testForm] = Form.useForm();

    const [inputValue, setInputValue] = useState(false);
    const [valueObj, setValueObj] = useState('');
    const [testTypeValue, setTestTypeValue] = useState('');

    const [criteria, setCriteria] = useState('');
    const [testValue, setTestValue] = useState('');
    const [editTest, setEditTest] = useState(false);
    const [editTestId, setEditTestId] = useState(null);


    const [weldNameChange, setWeldNameChange] = useState(false);
    const [downloadEnabled, setDownloadEnabled] = useState(false);
    const [isWeldModalVisible, setIsWeldModalVisible] = useState(false);
    const [imagesRequired, setImagesRequired] = useState(false);
    const [stopwatchValueRequired, setStopwatchValueRequired] = useState(false);
    const [criteriaList, setCriteriaList] = useState(criteriaListValue);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [comparator, setComparator] = useState('<');

    const [weldForm] = Form.useForm();

    //ITP write access control for normal user
    const isButtonDisabled = () => {
        if (userAccessList.includes('admin'))
            return false;
        else if (projectTeamMembersAccessList[0]?.isGuestUser) {
            return true;
        } else if (projectTeamMembersAccessList[0]?.isNormalUser && !projectTeamMembersAccessList[0]?.tabPermissions?.ITP?.write) {
            return true;
        } else {
            return false;
        }
    }
    const isWriteAccess = isButtonDisabled();

    const updatedCriteriaList = criteriaListValue.map(criteria => {
        if (criteria.valueType === "Value") {
            const types = displayStr.map(displayItem => ({
                id: displayItem.id,
                value: displayItem.displayStr,
                valueType: displayItem.valueType
            }));
            return {
                ...criteria,
                types: types
            };
        } else {
            return criteria;
        }
    });

    useEffect(() => {
        setCriteriaList(updatedCriteriaList);
    }, []);


    useEffect(() => {
        onFetchTestTypes();
    }, [selectedProject]);

    useEffect(() => {
        const ids = sowDetails.map(item => item.id);
        const queryString = ids.map(id => `sowId=${id}`).join('&');
        if (queryString) {
            onFetchStationName(queryString)
        }

        setDownloadEnabled(false);
    }, [sowDetails]);

    /** Fetching API to get  Pipeline details  (SOW details)*/
    useEffect(() => {
        const projId = get(selectedProject, 'id', undefined);
        sessionStorage.setItem('projectId', projId)
        if (projId) {
            setItpData([]);
            setLocation(null);
            setLocationRef(null);
            setLocationData([]);
            selectStation.resetFields();
            setIsEditStationName(false); setEditId(null); setNewStationName('')
            onFetchSowDetails(projId);
            weldForm.resetFields();
            // setUpdateDisable(true)
            // setWeldNameChange(false)
            setSelectedLocation(null);
            setIsWeldModalVisible(false)
            setDownloadEnabled(true);
        }
        setProjectIdValue(projId);
    }, [selectedProject]);

    /** Getting Location details*/
    useEffect(() => {
        setLocationData(sowDetails);
    }, [sowDetails]);

    useEffect(() => {
        let itpObj = [];
        if (projStationDetails) {
            for (let i = 0; i < projStationDetails.length; i++) {
                if (projStationDetails[i].tests.length > 0) {
                    projStationDetails[i].projectStationId = projStationDetails[i].tests[0].projectStationId
                    if (testTypesList.length > 0) {
                        for (let j = 0; j < projStationDetails[i].tests.length; j++) {
                            for (let k = 0; k < testTypesList.length; k++) {
                                if (projStationDetails[i].tests[j].testValueTypeId === testTypesList[k].id) {
                                    projStationDetails[i].tests[j].valueType = testTypesList[k].valueType
                                }
                            }
                        }
                    }
                } else {
                    projStationDetails[i].projectStationId = projStationDetails[i].id
                }
                itpObj.push(projStationDetails[i]);
            }
            setItpData(itpObj);
        }
    }, [projStationDetails]);

    useEffect(() => {
        SetProjStationTestList(projStationTestData);
    }, [projStationTestData]);

    //
    useEffect(() => {
        if (locationData && locationData.length > 0) {
            setLocation(locationData[0]?.pipelineSection)
            selectStation.setFieldsValue({
                // location: locationData[0]?.pipelineSection,
                location: `${locationData[0]?.pipelineSection} (${locationData[0]?.pipeStringId})`,
            });
            selectedPipeline(locationData[0]?.id);
        }
    }, [locationData]);

    /**Function to Add stations */
    const onSave = async (sow, station, customStationName) => {
        var obj = {
            "statmentOfWorkId": sow.id,
            "stationId": station.id,
            "name": station.name, //default value
            "customStationName": customStationName
        }

        await onAddProjStationDetails({ data: obj, name: station.name });
    }

    /** Function to add tests for stations */
    const onSaveTestDetails = async (data) => {
        var sowId = sessionStorage.getItem('sowId');
        await onAddTestDetails({ data, sowId });
    }

    /** Function to Update tests for stations */
    const onSaveEditTestDetails = async (data, testId) => {
        var sowId = sessionStorage.getItem('sowId');
        await onUpdateTestDetails({ data, testId, sowId });
    }

    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedTestData(record)
    };

    const showEditModal = (record, projectStationId) => {
        setIsTestModalVisible(true);
        setEditTest(true);
        setEditTestId(record.id);
        sessionStorage.setItem('sowStationId', projectStationId)
        let editData = null;
        let value = null;
        let valueUnit = null;
        if (record != null) {

            if (criteriaList.length > 0) {
                if (record.valueType === "ACCEPT_OR_REJECT_OR_NA") {
                    value = "Accept/Reject/NA";
                } else {
                    value = "Value"
                }
                const selected = criteriaList.find(u => u.value === value);
                //for Accept or Reject or NA
                if (selected && selected.id === 1) {
                    setValueObj(selected.types);
                    const testValueTypeId = testTypesList && testTypesList.find(u => u.valueType === selected.valueType);
                    setTestTypeValue(testValueTypeId)
                    setInputValue(false);
                }
                //for Value (°C, μm, mm, µS/cm)
                else {
                    setValueObj(selected.types);
                    setInputValue(true);
                    var valueUnitObj = selected.types;
                    const selectedUnit = valueUnitObj.find(u => u.valueType === record.valueType);
                    const testValueTypeId = testTypesList && testTypesList.find(u => u.valueType === selectedUnit.valueType);
                    setTestTypeValue(testValueTypeId);
                    setTestValue(selectedUnit.value)
                    valueUnit = selectedUnit.value
                }
            }
            setCriteria(value);
            editData = {
                testId: record.testId,
                typeOfTest: record.typeOfTest,
                criteria: value,
                testValue: valueUnit,
                minValue: record.minValue,
                comparator: record.comparator,
                maxValue: record.maxValue,
                mandatory: record.mandatory,
                imagesRequired: record.imagesRequired,
                stopwatchValueRequired: record.stopwatchValueRequired
            };
            setImagesRequired(record.imagesRequired);
            setStopwatchValueRequired(record.stopwatchValueRequired);
            setComparator(record.comparator);
            testForm.setFieldsValue(editData);
        }
    };

    const handleOk = async () => {
        var sowId = sessionStorage.getItem('sowId');
        await onDeleteTestDetails({ testId: selectedTestData.id, sowId });

        setLoadingBtn(true);
        setTimeout(() => {
            setLoadingBtn(false);
            setIsDeleteModalVisible(false);
            setSelectedTestData('');
        }, 1000)
    }

    const handleCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedTestData('')
    }

    const selectedPipeline = (value) => {

        if (value) {

            const locData = locationData.find(u => u.id === value);
            setLocation(locData.pipelineSection);
            //for adding new station
            setLocationRef(locData.pipelineSection);
            editStationForm.setFieldsValue({ locationRef: locData.pipelineSection });
            setSelectedLocation(locData);

            //get stations with respect to sow Id
            if (locData) {
                onGetProjStationDetails(locData.id);
                sessionStorage.setItem('sowId', locData.id);
            }
        } else {
            setItpData([]);
            setLocation(null);
            //for adding new station
            setLocationRef(null);
            editStationForm.resetFields();
            setSelectedLocation(null);
        }
        setIsEditStationName(false);
    }

    const handleUpdateWeldName = (e, weld) => {
        if (weld === 'prefix') {
            setWeldPrefix(e.target.value);

        } else if (weld === 'suffix') {
            setWeldSuffix(e.target.value);
        }
        if (e.target.value) {
            setWeldNameChange(true)
        } else if (!weldPrefix || !weldSuffix) {
            setWeldNameChange(false)
        }

    }

    const getValueText = (valueType) => {
        switch (valueType) {
            case "NONE":
                return "";
            case "DEGREE_CELSIUS":
                return "°C";
            case "MICRO_METER":
                return "μm";
            case "MILLI_METER":
                return "mm";
            case "ACCEPT_OR_REJECT_OR_NA":
                return "Accept/Reject/NA";
            case "MICRO_SIEMENS":
                return "µS/cm";
            case "MICROGRAM_CENTIMETERSQUARE":
                return "µg/cm²";
            case "RATING":
                return "Rating";
            case "NEWTON_PER_MM":
                return "N/mm";
            case "JOULE_PER_MM":
                return "J/mm";
            case "MEGAPASCAL":
                return "MPa";
            case "PERCENTAGE":
                return "%";
            case "KILOVOLTS_PER_MM":
                return "KV/mm";
            case "MILLIGRAMS_PER_SQUARE_METER":
                return "mg/m²";
            case "MILLIGRAMS_PER_SQUARE_MM":
                return "N/mm²";
            case "MINUTES":
                return "mins";
            case "SECONDS":
                return "secs";
            case "THOUSANDTHS_OF_INCH":
                return "mils";
            case "MICRO_OUNCES_PER_SQUARE_INCH":
                return "μoz/in²";
            case "MICRO_SIEMENS_PER_INCH":
                return "μS/in";
            case "POUNDS_FORCE_PER_INCH":
                return "lbf/in";
            default:
                return "";
        }
    };

    const getUnit = async () => {
        let unitFormulae = [];
        if (Array.isArray(criteriaList)) {
            criteriaList?.forEach(criteria => {
                criteria?.types.forEach(type => {
                    if (type.value !== "") {
                        unitFormulae.push(type.value);
                    }
                });
            });
            unitFormulae = unitFormulae.slice(1);
            return unitFormulae;
        }
    }

    const getPipeline = async () => {
        let pipelineFormulae = [];
        if (Array.isArray(locationData)) {
            locationData?.forEach(item => {
                if (item.pipelineSection !== "") {
                    pipelineFormulae.push(item.pipelineSection);
                }
            });
            return pipelineFormulae;
        }

    }

    const getStation = async () => {
        let stationFormulae = [];
        if (Array.isArray(stationList)) {
            stationList?.forEach(item => {
                if (item.name !== "") {
                    stationFormulae.push(item.name);
                }
            });
            return stationFormulae;
        }

    }

    const getPipelineStation = async () => {
        let pipelineStationFormulae = [];
        if (Array.isArray(projestStnNames)) {
            projestStnNames?.forEach(item => {
                if (item.name.trim() !== "") {
                    pipelineStationFormulae.push(item.name);
                }
            });
            return pipelineStationFormulae;
        }

    }

    const addDropdownOptions = async (worksheet, columnLetter, options) => {
        let optionsSheet = worksheet.workbook.getWorksheet('Options');

        if (!optionsSheet) {
            optionsSheet = worksheet.workbook.addWorksheet('Options');
            optionsSheet.state = 'veryHidden';
        }

        options.forEach((value, index) => {
            optionsSheet.getCell(`${columnLetter}${index + 1}`).value = value;
        });

        for (let i = 2; i < 1000; i++) {
            worksheet.getCell(`${columnLetter}${i}`).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [`=Options!$${columnLetter}$1:$${columnLetter}$9999`]
            };
        }
    };


    const handleDownloadTemplate = async () => {
        const unitFormulae = await getUnit();
        const pipelineFormulae = await getPipeline();
        const pipelineStationFormulae = await getPipelineStation();
        const stationFormulae = await getStation();
        const workbook = new ExcelJS.Workbook();
        const itpAddstationSheet = workbook.addWorksheet('ITPStation');
        const headerRow = itpAddstationSheet.getRow(1);
        headerRow.height = 40;

        itpAddstationSheet.columns = [
            { header: 'Test ID', key: 'testId', width: 15 },
            { header: 'Pipeline', key: 'pipeline', width: 18 },
            { header: 'Station Type', key: 'stationType', width: 18 },
            { header: 'Station Name', key: 'stationName', width: 18 },
            { header: 'Type Of Test', key: 'typeOfTest', width: 18 },
            { header: 'Criteria', key: 'criteria', width: 16 },
            { header: 'Unit', key: 'testValue', width: 16 },
            { header: 'Min Value', key: 'minValue', width: 15 },
            { header: 'Comparator', key: 'comparator', width: 15 },
            { header: 'Max Value', key: 'maxValue', width: 15 },
            { header: 'Mandatory (True/False)', key: 'mandatory', width: 21 },
            { header: 'Images (True/False)', key: 'imagesRequired', width: 20 },
            { header: 'Stopwatch (True/False)', key: 'stopwatchValueRequired', width: 20 },
        ];

        await addDropdownOptions(itpAddstationSheet, 'B', pipelineFormulae);
        await addDropdownOptions(itpAddstationSheet, 'C', stationFormulae);
        await addDropdownOptions(itpAddstationSheet, 'D', pipelineStationFormulae);
        await addDropdownOptions(itpAddstationSheet, 'G', unitFormulae);

        headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        for (let i = 2; i < 1000; i++) {
            const row = itpAddstationSheet.getRow(i);
            const testIdColumn = row.getCell('testId');
            testIdColumn.dataValidation = {
                type: 'textLength',
                operator: 'lessThan',
                showErrorMessage: true,
                allowBlank: true,
                formulae: [6],
                errorStyle: 'error',
                error: 'Test ID length must be less than or equal to Five'
            };

            const pipelineColumn = row.getCell('pipeline');
            pipelineColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [`=Options!$B$1:$B$9999`]
                // formulae: ['"' + location + '"']
            };

            const stationTypeColumn = row.getCell('stationType');
            stationTypeColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=Options!$C$1:$C$9999']
            };

            // const stationNameColumn = row.getCell('stationName');
            // stationNameColumn.dataValidation = {
            //     type: 'list',
            //     allowBlank: true,
            //     formulae: ['=Options!$D$1:$D$9999']
            // };

            const criteriaColumn = row.getCell('criteria');
            criteriaColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['"Accept/Reject/NA,Value"']
            };

            const unitColumn = row.getCell('testValue');
            unitColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=Options!$G$1:$G$9999']
            };

            const comparatorColumn = row.getCell('comparator');
            comparatorColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['"<, <=, >, >=, =, BETWEEN"']
            };

            const mandatoryColumn = row.getCell('mandatory');
            mandatoryColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['"True,False"']
            };

            const imagesRequiredColumn = row.getCell('imagesRequired');
            imagesRequiredColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['"True,False"']
            };

            const stopwatchValueRequiredColumn = row.getCell('stopwatchValueRequired');
            stopwatchValueRequiredColumn.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['"True,False"']
            };
        }

        // Write to buffer
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'template.xlsx';
        link.click();
    };


    const showBulkUploadModal = () => {
        if (selectedProject) {
            setBulkUplaodModal(true);
        } else {
            notification.warn({ message: 'Select Project to add data.' });
        }
    }

    const handleCancelUploadFile = () => {
        setFileList([]);
        setBulkUplaodModal(false);
        setDisableFileUpload(false);
    }

    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        if (jsonData[1].length === 0) {
                            throw new Error('Invalid file. Please select a valid file.');
                        }

                        const headers = jsonData[0];

                        const keyMapping = {
                            'Test ID': 'Test ID',
                            'Pipeline': 'Pipeline',
                            'Station Type': 'Station Type',
                            'Station Name': 'Station Name',
                            'Type Of Test': 'Type Of Test',
                            'Criteria': 'Criteria',
                            'Unit': 'Unit',
                            'Min Value': 'Min Value',
                            'Comparator': 'Comparator',
                            'Max Value': 'Max Value',
                            'Mandatory (True/False)': 'Mandatory (True/False)',
                            'Images (True/False)': 'Images (True/False)',
                            'Stopwatch (True/False)': 'Stopwatch (True/False)',
                        };


                        const formattedData = jsonData.slice(1).map(row =>
                            headers.reduce((acc, header, index) => {
                                if (keyMapping[header] === 'Test ID') {
                                    const value = row[index];
                                    acc[keyMapping[header]] = value !== undefined ? String(value) : '';
                                } else {
                                    const value = row[index];
                                    acc[keyMapping[header]] = value !== undefined ? value : '';
                                }
                                return acc;
                            }, {})
                        );

                        // Send the formatted data to your API
                        var sowId = sessionStorage.getItem('sowId');
                        onUploadITPAddstation({ data: formattedData, projectId: selectedProject.id, sowId: sowId });
                        setTimeout(handleCancelUploadFile, 1000)

                    } catch (error) {
                        notification.error({ message: 'Oops! Unable to process the Excel file.', description: 'Please make sure you have selected a valid Excel file.' });

                    }
                };

                reader.readAsArrayBuffer(file);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };



    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };

    return (
        <div className="d-flex h-100">
            <div className="flex-fill custom-fill overflow-auto" style={{ maxWidth: '100%' }}>
                <div className="d-flex flex-column h-100">
                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">

                            <Title className="mb-0" style={{ marginRight: '20px' }} level={4}>
                                Inspection Test
                            </Title>

                            <div className='itp-select'>
                                <Form
                                    form={selectStation}
                                    name="basic"
                                    layout="vertical"
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        name="location"
                                        // label="Select Pipeline"
                                        required
                                    >
                                        <Select
                                            className='pipeline-select'
                                            placeholder='Select Pipeline & Pipe String ID'
                                            onChange={(value) => selectedPipeline(value)}
                                            value={location}
                                            allowClear
                                            showSearch
                                        // filterOption={(input, option) =>
                                        //     (option.children).toLowerCase().includes(input.toLowerCase())
                                        // }
                                        >
                                            {locationData && locationData.map((item, i) => (
                                                <Option key={i} value={item?.id}>
                                                    {item?.pipelineSection}  {item?.pipeStringId && `(${item?.pipeStringId})`}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Form>

                            </div>

                            {/* {selectedLocation && selectedLocation.weldNamingConfig && selectedLocation.weldNamingConfig.isRequired &&
                                <WeldNumberGeneration
                                    setIsWeldModalVisible={setIsWeldModalVisible}
                                    isWeldModalVisible={isWeldModalVisible}
                                    weldForm={weldForm}
                                    locationData={locationData}
                                    selectedLocation={selectedLocation}
                                />
                            } */}
                            <AddNewStation
                                className='details-modal'
                                setIsModalVisible={setIsModalVisible}
                                isModalVisible={isModalVisible}
                                editStationForm={editStationForm}
                                onSave={onSave}
                                stationList={stationList}
                                locationData={locationData}
                                setStationList={setStationList}
                                locationRef={locationRef}
                                setLocationRef={setLocationRef}
                                setSelectedLocation={setSelectedLocation}
                                selectedLocation={selectedLocation}
                                loadingBtn={loadingBtn} setLoadingBtn={setLoadingBtn}
                                antIcon={antIcon}
                                checked={selectedLocation && selectedLocation.weldNamingConfig && selectedLocation.weldNamingConfig.isRequired}
                                isWriteAccess={isWriteAccess}
                            />
                            <CopyStationTest
                                className='details-modal'
                                setIsCopyModalVisible={setIsCopyModalVisible}
                                isCopyModalVisible={isCopyModalVisible}
                                // editStationCopyForm={editStationCopyForm}
                                locationData={locationData}
                                setLocation={setLocation}
                                selectedPipeline={selectedPipeline}
                                selectStation={selectStation}
                                selectedProject={selectedProject}
                                isWriteAccess={isWriteAccess}
                            />
                            <div className="itp-project-team">
                                <Text onClick={!isWriteAccess ? showBulkUploadModal : undefined} className="ps-3 add-new-team">
                                    <span className="icon-22">
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                    Bulk Upload
                                </Text>
                                {!downloadEnabled && <Text onClick={handleDownloadTemplate} className='ps-3 add-new-team'>
                                    <span className="icon-22">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M12 3v18M5 12l7 7 7-7"></path>
                                        </svg>
                                    </span>
                                    Download template
                                </Text>}
                            </div>

                        </div>
                        <div className="custom-line" />
                    </div>
                    <div className="flex-fill overflow-auto itp-overflow main_station_height"
                        style={{
                            // height: '40vh', 
                            height: 'calc(100vh - 400px)',
                        }}>
                        {/* <div className="d-flex flex-column h-100">
                            <div className="flex-auto overflow-hidden"> */}
                        <Row gutter={16}>
                            <Col lg={24} className="">
                                {(location && itpData.length > 0 && isStationsLoading == false) ?
                                    <div className='d-inline-flex float-start itp_carousal'
                                    >
                                        {itpData && itpData.map((item, i) => {
                                            return <div className='view_station' key={i}>
                                                {/* <div className='station_container' >
                                                    {item.station.imageUrl !== "NULL" ?
                                                        <img className="station_hover" src={item.station.imageUrl} key={item.stationId} alt={item.name}
                                                            style={
                                                                // item.stationId === 3 ? { width: '230px' } : { width: '120px' }
                                                                { width: 'auto', height: '90%' }
                                                            }
                                                        >

                                                        </img>
                                                        :
                                                        <Text className='no_image'>No Image to Display</Text>
                                                    }
                                                    <div className='delete_sow_station'>
                                                        <Tooltip title="Delete Station">
                                                            <DeleteFilled className='delete_station_icon'
                                                                onClick={async () => {
                                                                    setIsDeleteSowStation(true);
                                                                    var sowId = sessionStorage.getItem('sowId')
                                                                    setDeleteStationData({ sowStationId: item.id, sowId: parseInt(sowId), name: item.name })
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div> */}
                                                {/* <div className='mt-2 ' >
                                                    <Text className='station_name_'><span>Station Type :- </span>{item.station.name}</Text>
                                                    <Tooltip className='mt-1' title="Delete Station">
                                                        <DeleteFilled className='delete_station_icon'
                                                            onClick={async () => {
                                                                setIsDeleteSowStation(true);
                                                                var sowId = sessionStorage.getItem('sowId')
                                                                setDeleteStationData({ sowStationId: item.id, sowId: parseInt(sowId), name: item.name })
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div> */}
                                                <div className='border-right mt-2'
                                                >
                                                    <div className='station_name'>
                                                        {isEditStationName && i === editId ? (
                                                            <div>
                                                                <Input className='edit_station_input' value={newStationName} onChange={(e) => setNewStationName(e.target.value)} />
                                                                {!loader ? <> <CloseOutlined className='edit_close_icon' onClick={() => setIsEditStationName(false)} />
                                                                    <CheckOutlined className='edit_apply_icon' onClick={async () => {
                                                                        var sowId = sessionStorage.getItem('sowId');
                                                                        if (!newStationName.trim()) {
                                                                            notification.warning({
                                                                                message: 'Please type a valid inputs to proceed!'
                                                                            });
                                                                            return
                                                                        }
                                                                        setLoader(true);
                                                                        const data = {
                                                                            name: newStationName
                                                                        }
                                                                        await onUpdateStationName({ data, stationId: item.id, sowId: parseInt(sowId) });
                                                                        setTimeout(() => {
                                                                            setIsEditStationName(false);
                                                                            setLoader(false);
                                                                        }, 2000);
                                                                    }} />
                                                                </> :
                                                                    <Spin indicator={antIcon} />}
                                                            </div>) :
                                                            <>
                                                                <Tooltip title={item.name}><Text className='m-0 station_name_' >{item.name}</Text></Tooltip>
                                                                <Tooltip title='Update Station Name'>
                                                                    <EditOutlined
                                                                        className='edit_station_name'
                                                                        onClick={() => {
                                                                            if (!isWriteAccess) {
                                                                                setIsEditStationName(true);
                                                                                setEditId(i);
                                                                                setNewStationName(item.name)
                                                                            }
                                                                        }}
                                                                        style={{ color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }}
                                                                    />
                                                                </Tooltip>
                                                                <Tooltip title="Delete Station">
                                                                    <DeleteFilled className='delete_station_icon'
                                                                        onClick={async () => {
                                                                            if (!isWriteAccess) {
                                                                                setIsDeleteSowStation(true);
                                                                                var sowId = sessionStorage.getItem('sowId')
                                                                                setDeleteStationData({ sowStationId: item.id, sowId: parseInt(sowId), name: item.name })
                                                                            }
                                                                        }}
                                                                        style={{ color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }}
                                                                    />
                                                                </Tooltip>
                                                            </>}
                                                    </div>
                                                    <div className='mt-1 ' >
                                                        <Text className='m-0 station_name_'><span>Type :- </span>{item.station.name}</Text>
                                                    </div>
                                                    <div className='py-3 px-3'>
                                                        <Row >
                                                            <Col span={3}><Text className='float-start fw-medium'>Test ID</Text></Col>
                                                            <Col span={5}><Text className='float-start fw-medium'>Type of Test</Text></Col>
                                                            <Col span={5}><Text className='fw-medium'>Criteria</Text></Col>
                                                            <Col span={3}><Text className='fw-medium'>Unit</Text></Col>
                                                            <Col span={4}><Text className='fw-medium'>Range</Text></Col>
                                                            <Col span={2}><Text className='fw-medium'>{' '}</Text></Col>
                                                            <Col span={2}><Text className='fw-medium'>{' '}</Text></Col>
                                                        </Row>
                                                        <div className='py-3 test_height' style={{ overflowX: 'auto', height: 'calc(100vh - 570px)' }}>
                                                            {item.tests && item.tests.length > 0 && item.tests.map((test, j) => {
                                                                return <Row justify="start" className='small' key={test.id} style={{ margin: '10px 0' }}>
                                                                    <Col span={3} style={{ textAlign: 'center' }}><Tooltip title={test.testId}><Text className='float-start test_text'>{test.testId}</Text></Tooltip></Col>
                                                                    <Col span={5} style={{ textAlign: 'left' }}><Tooltip title={test.typeOfTest}><Text className='float-start test_text'>{test.typeOfTest}</Text></Tooltip></Col>
                                                                    <Col span={5} className='text-center'>
                                                                        {test.valueType === "ACCEPT_OR_REJECT_OR_NA" ?
                                                                            <Text>Accept/Reject/NA</Text> : <Text>Value</Text>}
                                                                    </Col>
                                                                    <Col span={3} className='text-center'>
                                                                        {test.valueType === "ACCEPT_OR_REJECT_OR_NA" ? (
                                                                            <Text>-</Text>
                                                                        ) : (
                                                                            <Text>{getValueText(test.valueType)}</Text>
                                                                        )}
                                                                    </Col>
                                                                    <Col span={4} className='text-center'>
                                                                        {test.valueType === "ACCEPT_OR_REJECT_OR_NA" ?
                                                                            <Text>-</Text> :
                                                                            <Text>
                                                                                {`${test.comparator ? test.comparator == "BETWEEN" ? '' : test.comparator : "-"} ${test.minValue !== null && test.minValue !== "0.00" ? test.minValue : ""}${test.comparator == "BETWEEN" ? " -" : ''} ${test.maxValue !== null && test.maxValue !== "0.00" ? test.maxValue : ""}`}
                                                                            </Text>
                                                                        }
                                                                    </Col>

                                                                    <Col span={2}>
                                                                        <Tooltip title={`Update ${test.typeOfTest}`} placement="right">
                                                                            <span className='edit-hover'>
                                                                                <EditOutlined style={{ fontSize: '14px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showEditModal(test, item.id) : undefined }} />
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col span={1}>
                                                                        <span className='edit-hover'>
                                                                        <Tooltip title={`Delete ${test.typeOfTest}`} placement="right">
                                                                                <DeleteOutlined style={{ fontSize: '14px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showDeleteModal(test) : undefined }} />
                                                                        </Tooltip>
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='float-start add-test'>
                                                        <AddNewTest
                                                            isTestModalVisible={isTestModalVisible}
                                                            setIsTestModalVisible={setIsTestModalVisible}
                                                            sowStationId={item.id}
                                                            testTypesList={testTypesList}
                                                            onSaveTestDetails={onSaveTestDetails}
                                                            onSaveEditTestDetails={onSaveEditTestDetails}
                                                            testForm={testForm}
                                                            criteriaList={criteriaList}
                                                            inputValue={inputValue}
                                                            setInputValue={setInputValue}
                                                            valueObj={valueObj}
                                                            setValueObj={setValueObj}
                                                            testTypeValue={testTypeValue}
                                                            setTestTypeValue={setTestTypeValue}
                                                            criteria={criteria}
                                                            setCriteria={setCriteria}
                                                            testValue={testValue}
                                                            setTestValue={setTestValue}
                                                            setEditTest={setEditTest}
                                                            editTest={editTest}
                                                            editTestId={editTestId}
                                                            loadingBtn={loadingBtn} setLoadingBtn={setLoadingBtn}
                                                            imagesRequired={imagesRequired}
                                                            setImagesRequired={setImagesRequired}
                                                            stopwatchValueRequired={stopwatchValueRequired}
                                                            setStopwatchValueRequired={setStopwatchValueRequired}
                                                            comparator={comparator}
                                                            setComparator={setComparator}
                                                            projectTeamMembersAccessList={projectTeamMembersAccessList}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        {/* </Carousel> */}
                                    </div> :
                                    <Table dataSource={''} columns={''} loading={isStationsLoading} />}
                            </Col>
                        </Row>
                        {/* </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Test Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={loadingBtn}>
                        Delete
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}><p>Deleting test will delete all the associated welds.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedTestData && selectedTestData.typeOfTest}</b> test?</Text>
                </div>
            </Modal>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title={`Delete Station`}
                centered
                visible={isDeleteSowStation}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setIsDeleteSowStation(false);
                        setDeleteStationData('');
                    }}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={async () => {

                        await onDeleteSowStation({ sowStationId: deleteStationData.sowStationId, sowId: deleteStationData.sowId, name: deleteStationData.name });

                        setLoadingBtn(true);
                        setTimeout(() => {
                            setLoadingBtn(false);
                            setIsDeleteSowStation(false);
                            setDeleteStationData('');
                        }, 1000)
                    }} loading={loadingBtn}>
                        Delete
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}><p>Deleting Station will delete all the associated data (Tests & Welds).</p><p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{deleteStationData && deleteStationData.name}</b>?</p></Text>
                </div>
            </Modal>
            <Modal
                className="pipeline-modal"
                title='Upload ITP Station  Data'
                centered
                visible={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={isUploadAddstationLoading}>
                        {isUploadAddstationLoading ? 'uploading' : 'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>
        </div >
    );
}

const mapStateToProps = ({ station, testReducer, sow }) => {
    const projStationDetails = get(station, 'projStationData', []);
    const isStationsLoading = get(station, 'isStationsLoading', []);
    const projStationTestData = get(testReducer, 'projStationTestData', []);
    const sowDetails = get(sow, 'sowData', []);
    const isSowLoading = get(sow, 'isSowLoading', []);
    const displayStr = get(testReducer, 'testTypes', []);
    const projestStnNames = get(testReducer, 'stationNames', []);
    const isStnNamesLoading = get(testReducer, 'isStationNameLoading', []);
    return {
        projStationDetails,
        isStationsLoading,
        projStationTestData,
        sowDetails,
        displayStr,
        projestStnNames,
        isStnNamesLoading,
        isSowLoading
    }
}

const mapDispatchToProps = {
    onGetProjStationDetails: getProjectStationDetails,
    onAddProjStationDetails: addProjectStationDetails,
    onAddTestDetails: addTestDetails,
    onGetStationTestDetails: fetchStationTestDetails,
    onDeleteTestDetails: deleteTestDetails,
    onFetchSowDetails: fetchSowDetails,
    onUpdateStationName: updateSowStationName,
    onUpdateTestDetails: updateTestDetails,
    onDeleteSowStation: deleteSowStation,
    onFetchTestTypes: fetchTestTypes,
    onFetchStationName: fetchStationName,
    onUploadITPAddstation: uploadITPAddstationFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(ITP);