export const FETCH_EQUIPMENT_LIST = '@@PIH-Equipment/fetch-equipment-list';
export const FETCH_EQUIPMENT_LIST_SUCCESS = '@@PIH-Equipment/fetch-equipment-list-success';
export const FETCH_EQUIPMENT_LIST_FAILURE = '@@PIH-Equipment/fetch-equipment-list-failure';

export const ADD_EQUIPMENT_DETAILS = '@@PIH-Equipment/add-equipment-details';
export const ADD_EQUIPMENT_DETAILS_SUCCESS = '@@PIH-Equipment/add-equipment-details-success';
export const ADD_EQUIPMENT_DETAILS_FAILURE = '@@PIH-Equipment/add-equipment-details-failure';

export const FETCH_EQUIPMENT_DETAILS = '@@PIH-Equipment/fetch-equipment-details';
export const FETCH_EQUIPMENT_DETAILS_SUCCESS = '@@PIH-Equipment/fetch-equipment-details-success';
export const FETCH_EQUIPMENT_DETAILS_FAILURE = '@@PIH-Equipment/fetch-equipment-details-failure';

export const UPDATE_EQUIPMENT_DETAILS = '@@PIH-Equipment/update-equipment-details';
export const UPDATE_EQUIPMENT_DETAILS_FAILURE = '@@PIH-Equipment/update-equipment-details-failure';
export const SET_EQUIPMENT_DETAILS_UPDATE = '@@PIH-Equipment/set-equipment-details-update';

export const DELETE_EQUIPMENT_DETAILS = '@@PIH-Equipment/delete-equipment-details';
export const DELETE_EQUIPMENT_DETAILS_FAILURE = '@@PIH-Equipment/delete-equipment-details-failure';
export const DELETE_EQUIPMENT_DETAILS_SUCCESS = '@@PIH-Equipment/delete-equipment-details-success';


export const GET_CHECKS_DETAILS = '@@PIH-Equipment/get-checks-details';
export const GET_CHECKS_DETAILS_SUCCESS = '@@PIH-Equipment/get-checks-details-success';
export const GET_CHECKS_DETAILS_FAILURE = '@@PIH-Equipment/get-checks-details-failure';

export const GET_CHECKS_LOGS_DETAILS = '@@PIH-Equipment/get-checks-logs-details';
export const GET_CHECKS_LOGS_DETAILS_SUCCESS = '@@PIH-Equipment/get-checks-logs-details-success';
export const GET_CHECKS_LOGS_DETAILS_FAILURE = '@@PIH-Equipment/get-checks-logs-details-failure';

export const GET_ALL_USERS = '@@PIH-Equipment/get-all-users';
export const GET_ALL_USERS_SUCCESS = '@@PIH-Equipment/get-all-users-success';
export const GET_ALL_USERS_FAILURE = '@@PIH-Equipment/get-all-users-failure';

export const FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS = '@@PIH-Equipment/fetch-equipment-maintenance-interval-details';
export const FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_SUCCESS = '@@PIH-Equipment/fetch-equipment-maintenance-interval-details-success';
export const FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS_FAILURE = '@@PIH-Equipment/fetch-equipment-maintenance-interval-details-failure';

export const FETCH_EQUIPMENT_CATEGORY = '@@PIH-Equipment/fetch-equipment-category';
export const FETCH_EQUIPMENT_CATEGORY_SUCCESS = '@@PIH-Equipment/fetch-equipment-category-success';
export const FETCH_EQUIPMENT_CATEGORY_FAILURE = '@@PIH-Equipment/fetch-equipment-category-failure';

export const FETCH_TEAM_MEMBERS_FOR_PROJECT = '@@PIH-Equipment/fetch-team-members-for-project';
export const FETCH_TEAM_MEMBERS_FOR_PROJECT_SUCCESS = '@@PIH-Equipment/fetch-team-members-for-project-success';
export const FETCH_TEAM_MEMBERS_FOR_PROJECT_FAILURE = '@@PIH-Equipment/fetch-team-members-for-project-failure';

//TEAMS ACCESS TYPE
export const FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT = '@@PIH-Equipment/fetch-team-members-access-for-project';
export const FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_SUCCESS = '@@PIH-Equipment/fetch-team-members-access-for-project-success';
export const FETCH_TEAM_MEMBERS_ACCESS_FOR_PROJECT_FAILURE = '@@PIH-Equipment/fetch-team-members-access-for-project-failure';

export const DELETE_TEAM_MEMBER = '@@PIH-Equipment/delete-team-member';
export const DELETE_TEAM_MEMBER_FAILURE = '@@PIH-Equipment/delete-team-member-failure';
export const DELETE_TEAM_MEMBER_SUCCESS = '@@PIH-Equipment/delete-team-member-success';

export const ADD_TEAM_MEMBER = '@@PIH-Equipment/add-team-member';
export const ADD_TEAM_MEMBER_SUCCESS = '@@PIH-Equipment/add-team-member-success';
export const ADD_TEAM_MEMBER_FAILURE = '@@PIH-Equipment/add-team-member-failure';

export const GET_ALL_NON_COGNITO_USERS = '@@PIH-Equipment/get-all-non-cognito-users';
export const GET_ALL_NON_COGNITO_USERS_SUCCESS = '@@PIH-Equipment/get-all-non-cognito-users-success';
export const GET_ALL_NON_COGNITO_USERS_FAILURE = '@@PIH-Equipment/get-all-non-cognito-users-failure';

export const ADD_NON_COGNITO_USERS = '@@PIH-Equipment/add-non-cognito-users';
export const ADD_NON_COGNITO_USERS_SUCCESS = '@@PIH-Equipment/add-non-cognito-users-success';
export const ADD_NON_COGNITO_USERS_FAILURE = '@@PIH-Equipment/add-non-cognito-users-failure';