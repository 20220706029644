/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Details from '../components/Details';
import ITP from '../components/ITP';
import LogDetails from '../components/LogDetails';
import ProjectTeamDetails from '../components/ProjectTeamDetails';
import MaterialConsumption from '../components/MaterialConsumption';
import Consumables from '../components/Consumables';
import Equipment from '../components/Equipment';
import DSR from '../components/DSR';
import ToolboxHome from '../components/ToolboxHome';
import SpareDashboard from '../components/SpareDashboard';
import ReportingEmailPage from '../components/ReportingEmailPage';
import InvoiceAndBilling from '../components/InvoiceAndBilling';

const { TabPane } = Tabs;

const TabList = (props) => {
    const {
        selectedProject,
        stationList,
        testTypesList,
        setStationList,
        equipmentList,
        selectedData,
        projectTeamMembersAccessList,
    } = props;

    const [userAccessList, setUserAccessList] = useState([]);
    const visibleTabs = projectTeamMembersAccessList[0]?.selectedTabs || [];
    const [filteredTabs, setFilteredTabs] = useState([]);
    useEffect(() => {
        const storedUserGroup = localStorage.getItem('UserGroup');
        let parsedUserGroup;
        try {
            parsedUserGroup = JSON.parse(storedUserGroup);
        } catch (error) {
            parsedUserGroup = storedUserGroup ? storedUserGroup.split(',') : [];
        }
        setUserAccessList(parsedUserGroup);
    }, []);
    // Check if the user has admin access
    const isAdmin = userAccessList.includes('admin');




    // Define all tabs and their corresponding keys
    const allTabs = [
        {
            key: '1', tab: 'Details',
            component: (
                <Details
                    selectedProject={selectedProject}
                    userAccessList={userAccessList}
                />)
        },
        {
            key: '2',
            tab: 'Equipment',
            component: (
                <Equipment
                    selectedProject={selectedProject}
                    equipmentList={equipmentList}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '10',
            tab: 'Spares',
            component: (
                <SpareDashboard
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '3',
            tab: 'Consumables',
            component: (
                <Consumables
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '4',
            tab: 'Material Consumption',
            component: (
                <MaterialConsumption
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '5',
            tab: 'Team',
            component: (
                <ProjectTeamDetails
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '11',
            tab: 'Reporting Email',
            component: (
                <ReportingEmailPage
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '6',
            tab: 'Log',
            component: (
                <LogDetails
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '7',
            tab: 'ITP',
            component: (
                <ITP
                    stationList={stationList}
                    selectedProject={selectedProject}
                    testTypesList={testTypesList}
                    setStationList={setStationList}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '12',
            tab: 'Billing',
            component: (
                <InvoiceAndBilling
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '8',
            tab: 'DSR',
            component: (
                <DSR
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
        {
            key: '9',
            tab: 'Communications',
            component: (
                <ToolboxHome
                    selectedProject={selectedProject}
                    projectTeamMembersAccessList={projectTeamMembersAccessList}
                    userAccessList={userAccessList}
                />
            ),
        },
    ];
    useEffect(() => {
        if (projectTeamMembersAccessList.length > 0) {
            const visibleTabs = projectTeamMembersAccessList[0]?.selectedTabs || [];
            console.log("Updated visibleTabs:", visibleTabs);
        }
    }, [projectTeamMembersAccessList]);

    useEffect(() => {
        const newFilteredTabs = isAdmin ? allTabs : allTabs.filter(tab => visibleTabs.includes(tab.tab));
        setFilteredTabs(newFilteredTabs);
    }, [visibleTabs, isAdmin, allTabs, userAccessList]);

    // // Filter tabs based on user access
    // const filteredTabs = isAdmin
    //     ? allTabs // Show all tabs for admin
    //     : allTabs.filter((tab) => visibleTabs.includes(tab.tab)); // Show only visible tabs for non-admin

    return (
        <Tabs className="custom-tab h-100" defaultActiveKey="1">
            {filteredTabs.map((tab) => (
                <TabPane tab={tab.tab} key={tab.key} style={{ position: 'relative' }}>
                    {tab.component}
                </TabPane>
            ))}
        </Tabs>
    );
};

export default TabList;