import React, { useEffect, useState } from 'react';
import { Typography, Modal, notification, Button, Form, Select, Col, Row, Input, Spin } from 'antd';
import { ReactGrid, Column, DropPosition } from "@silevis/reactgrid";
// import "@silevis/reactgrid/styles.css";
import * as XLSX from 'xlsx';
import InvoiceGridExpand from './InvoiceGridExpand';

const { Title, Text } = Typography;
const { Option } = Select;
let uuid = self.crypto.randomUUID();
// Define the initial data structure
const getInitialData = () => [
    { uniqueId: uuid, category: "Project Management", description: "Project Management", milestoneReference: "", accumulativeQty: 0, inMonthQty: 0, unit: "Each", rate: 0, accumulativeTotalValue: 0, inMonthInvoice: 0, isOpen: false },
];

const unitOptions = [
    { label: "Lump Sum", value: "Lump Sum" },
    { label: "Per Day", value: "Per Day" },
    { label: "Each", value: "Each" },
];

const getColumns = () => [
    { columnId: "category", width: 550, reorderable: true },
    { columnId: "description", width: 390, reorderable: true },
    { columnId: "milestoneReference", width: 290, reorderable: true },
    { columnId: "accumulativeQty", width: 250, style: { paddingLeft: '10px' }, reorderable: true },
    { columnId: "inMonthQty", width: 130, reorderable: true },
    { columnId: "unit", width: 140, reorderable: true },
    { columnId: "rate", width: 130, reorderable: true },
    { columnId: "accumulativeTotalValue", width: 250, reorderable: true },
    { columnId: "inMonthInvoice", width: 170, reorderable: true },
];


const Invoice = (props) => {
    const { selectedProject, monthName, invoiceTitle, setInvoiceTitle, invoiceModalVisible, setInvoiceModalVisible, timesheetData, setTimesheetData, invoiceRowsList, isInvoiceDataLoading, updateInvoiceData, handleAddRow, isWriteAccess } = props;

    const [editSheet, setEditSheet] = useState(false);
    const [columns, setColumns] = useState(getColumns());

    useEffect(() => {
        const rowsList = invoiceRowsList.data || [];
        setTimesheetData(rowsList.length ? rowsList.map(item => calculateValues(item, columns)) : getInitialData().map(item => calculateValues(item, columns)));
    }, [monthName, invoiceRowsList]);


    const headerRow = {
        rowId: "header",
        cells: [
            { type: "header", text: "Category", style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px' } },
            { type: "header", text: "Description", style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
            { type: "header", text: "Milestone Reference", style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
            { type: "header", text: "Accumulative Qty", style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
            { type: "header", text: "In-Month Qty", style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
            { type: "header", text: "Units", style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
            { type: "header", text: "Rate", style: { paddingLeft: '5px', backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
            { type: "header", text: "Accumulative Total Value", style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
            { type: "header", text: `In Month Invoice`, style: { backgroundColor: 'transparent', color: '#ef314c', fontSize: '14px', height: '40px', lineHeight: '40px', } },
        ],
    };



    const getCellStyle = (item, columnId, rowIndex) => {
        let style = {
        };

        style.border = {
            left: { color: "transparent", style: "solid", width: "1px" },
            right: { color: "transparent", style: "solid", width: "1px" },
            bottom: { color: "#afafaf", style: "", width: "1px" },
            top: { color: "transparent", style: "solid", width: "1px" },
        };
        if (columnId === "unit") {
            style.background = 'none'
        }
        return style;
    };


    const getRows = (data, columnsOrder) => {
        const rows = [
            headerRow,
            ...data.map((item, idx) => ({
                rowId: idx + 1,
                cells: [
                    { type: "text", text: item[columnsOrder[0]], style: getCellStyle(item, '', idx) },
                    { type: "text", text: item[columnsOrder[1]], style: getCellStyle(item, '', idx) },
                    { type: "text", text: item[columnsOrder[2]], style: getCellStyle(item, '', idx) },
                    { type: "text", text: item[columnsOrder[3]].toString(), style: getCellStyle(item, '', idx) },
                    { type: "text", text: item[columnsOrder[4]].toString(), style: getCellStyle(item, '', idx) },
                    {
                        type: "dropdown",
                        selectedValue: item[columnsOrder[5]],
                        values: unitOptions,
                        isOpen: item.isOpen,
                        style: getCellStyle(item, columnsOrder[5], idx),
                        className: 'custom-dropdown'
                    },
                    { type: "text", text: item[columnsOrder[6]].toString(), style: getCellStyle(item, '', idx) },
                    { type: "text", text: item[columnsOrder[7]].toString(), nonEditable: true, style: getCellStyle(item, '', idx) },
                    { type: "text", text: item[columnsOrder[8]].toString(), nonEditable: true, style: getCellStyle(item, '', idx) },
                ],
            })),
        ];

        const totalAccumulativeTotalValue = data.reduce((sum, item) => sum + parseFloat(item.accumulativeTotalValue), 0);
        const totalMonthInvoice1 = data.reduce((sum, item) => sum + parseFloat(item.inMonthInvoice), 0);

        const totalsRow = {
            rowId: "totals",
            cells: [
                { type: "text", text: "Total(GBP)", className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: "", className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: "", className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: "", className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: "", className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: "", className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: "", className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: totalAccumulativeTotalValue.toFixed(2), className: "total-cell", nonEditable: true, style: getCellStyle({}) },
                { type: "text", text: totalMonthInvoice1.toFixed(2), className: "total-cell", nonEditable: true, style: getCellStyle({}) },

            ],
        };

        rows.push(totalsRow);

        return rows;
    };

    function calculateValues(data, columns) {
        data.accumulativeTotalValue = (data.accumulativeQty * data.rate).toFixed(2);
        data.inMonthInvoice = (data.inMonthQty * data.rate).toFixed(2);

        columns.forEach(column => {
            if (column.selectedKey) {
                try {
                    data[column.columnId] = (data[column.selectedKey] * (column.percentage / 100)).toFixed(2);
                } catch (error) {
                    console.error(`Error evaluating formula for ${column.columnId}: ${error.message}`);
                }
            }
        });

        return data;
    }

    const showModal = () => {
        if (selectedProject) {
            setInvoiceTitle("Add Timesheet");
            setInvoiceModalVisible(true);
        } else {
            notification.warn({ message: 'Select project to add new timesheet.' });
        }
    };

    const showEditModal = () => {
        if (selectedProject) {
            setTimesheetData(getInitialEditData().map(item => calculateValues(item, columns)));
            setEditSheet(true);
            setInvoiceTitle("Edit Timesheet");
            setInvoiceModalVisible(true);
        } else {
            notification.warn({ message: 'Select project to add new timesheet.' });
        }
    };

    const handleCancel = () => {
        setInvoiceModalVisible(false);
        setEditSheet(false);
    };

    const handleChanges = (changes) => {
        setTimesheetData((prevData) => applyChangesToPeople(changes, prevData));
    };


    const applyChangesToPeople = (changes, data) => {
        const updatedData = data.map(item => ({ ...item }));
        changes.forEach(change => {
            const { rowId, columnId, newCell, previousCell } = change;
            if (columnId === 'accumulativeTotalValue' || columnId === 'inMonthInvoice') return;
            const index = updatedData.findIndex((item, idx) => idx + 1 === rowId);
            if (index !== -1) {
                if (newCell.type === 'dropdown') {
                    if (newCell.selectedValue !== change.previousCell.selectedValue) {
                        updatedData[index][columnId] = newCell.selectedValue;

                    }
                    updatedData[index]['isOpen'] = newCell.isOpen;
                } else {
                    updatedData[index][columnId] = newCell.text;
                }
                updatedData[index] = calculateValues(updatedData[index], columns);
            }
        });
        return updatedData;
    };


    const reorderArray = (arr, idxs, to) => {
        const movedElements = arr.filter((_, idx) => idxs.includes(idx));
        const targetIdx = Math.min(...idxs) < to ? to += 1 : to -= idxs.filter(idx => idx < to).length;
        const leftSide = arr.filter((_, idx) => idx < targetIdx && !idxs.includes(idx));
        const rightSide = arr.filter((_, idx) => idx >= targetIdx && !idxs.includes(idx));
        return [...leftSide, ...movedElements, ...rightSide];
    }

    const handleColumnsReorder = (targetColumnId, columnIds) => {
        const to = columns.findIndex((column) => column.columnId === targetColumnId);
        const columnIdxs = columnIds.map((columnId) => columns.findIndex((c) => c.columnId === columnId));
        setColumns(prevColumns => reorderArray(prevColumns, columnIdxs, to));
    }

    const handleRowsReorder = (targetRowId, rowIds) => {
        setTimesheetData((prevData) => {
            const to = timesheetData.findIndex(item => item.id === targetRowId);
            const rowsIds = rowIds.map((id) => timesheetData.findIndex(item => item.id === id));
            return reorderArray(prevData, rowsIds, to);
        });
    }

    const handleCanReorderRows = (targetRowId, rowIds) => {
        return targetRowId !== 'header';
    }

    const rows = getRows(timesheetData, columns.map(c => c.columnId));

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <Spin spinning={isInvoiceDataLoading}>
                <div style={{ width: "100%", height: "calc(100vh - 195px)", overflowX: "auto" }}>
                    {!invoiceModalVisible && <ReactGrid
                        rows={rows}
                        columns={columns}
                        onCellsChanged={handleChanges}
                    />}
                </div>
            </Spin>
            {invoiceModalVisible &&
                <InvoiceGridExpand
                    rows={rows}
                    columns={columns}
                    handleChanges={handleChanges}
                    invoiceTitle={invoiceTitle}
                    handleAddRow={handleAddRow}
                    handleCancel={handleCancel}
                    updateInvoiceData={updateInvoiceData}
                isInvoiceDataLoading={isInvoiceDataLoading}
                isWriteAccess={isWriteAccess}
                />
            }

        </div>
    );
}

export default Invoice;