/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, notification, Select, Tooltip, Typography, Upload, Skeleton, Row, Col, Checkbox, Radio, Input } from 'antd';
import { PlusOutlined, EyeOutlined, MoreOutlined, UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import DocEquipmentTypes from '../../components/admin/DocEquipmentTypes';
import AddNewEquipmentType from '../../components/admin/AddNewEquipmentType';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchAdminEquipTypes, fetchAdminPihEquipTypes } from '../../redux/admin/equipmentTypes/action';
import { uploadEquipmentfile } from '../../redux/admin/upload/action';
import { fetchEquipCategory } from '../../redux/pihEquipmentDetails/action';
import { fetchAdminEquipSno, addDocumentType, fetchAdminEquipDocType, fetchAdminEquipDocList, fetchAdminEquipCategory, fetchAdminDocumentSubTypeSno } from '../../redux/admin/equipmentSno/action';

const { Text } = Typography;
const { Option } = Select;

const EquipmentSubCategory = (props) => {
    const { adminEquipTypesLoading, adminEquipTypes, onGetPihEquipmentTypes, onUploadEquipmentFiles, isUploadLoading,
        //
        isEquipDetailsLoading,
        adminEquipSnoLoading,
        categoryData,
        onGetCategory,
        adminEquipSnoData,
        onGetEquipSno,
        onAddDocumentType,
        onFetchAdminEquipDocType,
        adminEquipDocTypeLoad,
        adminEquipDocTypeData,
        onFetchAdminEquipDocList,
        //
        onFetchAdminEquipCategory,
        adminEquipCategoryData,
        adminEquipCategoryLoad,
        //
        onFetchAdminDocumentSubTypeSno,
        adminDocumentSubCategoryLoad,
        adminDocumentSubTypeSnoData
        //

    } = props;


    // Maitencance types
    const [checkOpen, setChecksOpen] = useState(false);
    const [openPage, setOpenPage] = useState('');

    //equipment Types
    const [equipTypeForm] = Form.useForm();
    const [equipTypeSearchForm] = Form.useForm();

    const [openModal, setOpenModel] = useState(false);
    const [equipTypeList, setEquipTypeList] = useState([]);
    const [equipTypeListDrop, setEquipTypeListDrop] = useState([]);
    const [clear, setClear] = useState(false);
    const [paginationClear, setPaginationClear] = useState(false);
    const [equipmentType, setEquipmentType] = useState('');
    const [editModelValue, setEditModelValue] = useState('');
    const [editRecord, setEditRecord] = useState('');

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [isEquipmentModalVisible, setIsEquipmentModalVisible] = useState(false);
    const [equipTitle, setEquipTitle] = useState('');
    const [disableTypes, setDisableTypes] = useState(false)
    const [serialNoList, setSerialNoList] = useState([]);
    const [equipmentFormData] = Form.useForm();
    const [pihEquipment, setPihEquipment] = useState('');
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [categoryDataList, setCategoryDataList] = useState([]);
    const [adminEquipmentTypes, setAdminEquipmentTypes] = useState([]);
    const [adminEquipTypeId, setAdminEquipTypeId] = useState();
    const [adminEquipSnoId, setAdminEquipSnoId] = useState();
    const [certificateUpload, setCertificateUpload] = useState(false);
    const [serialNo, setSerialNo] = useState('');
    const [pihCategory, setPihCategory] = useState('');
    const [selectedEquipment, setSelectedEquipment] = useState(true);
    const [documentBase64, setDocumentBase64] = useState('');
    const [documentId, setDocumentId] = useState('1');
    const [equipmentSubTypeId, setEquipmentSubTypeId] = useState();
    const [loadingDocList, setLoadingDocList] = useState(false);
    const [subCategoryPageActive, setSubCategoryPageActive] = useState(false);
    const [documentPageActive, setDocumentPageActive] = useState(false);


    /** Getting Equipment Types data on load */
    useEffect(() => {
        // onGetPihEquipmentTypes({
        //     pageSize: 10,
        //     pageNumber: 1
        // });
        onFetchAdminEquipCategory();
        onGetCategory()
        onFetchAdminEquipDocType();
    }, []);

    /** Setting the equipment type values to the state */
    useEffect(() => {
        setEquipTypeList(adminEquipCategoryData);
        // setEquipTypeListDrop(adminEquipCategoryData);
    }, [adminEquipCategoryData])

    /** Setting the equipment sub category */
    // useEffect(() => {
    //     setAdminEquipmentTypes(adminDocumentSubTypeSnoData);
    // }, [adminDocumentSubTypeSnoData])

    const RemoveDuplicates = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    /** Function to make category list in alphabetical order */
    const titleCase = (str) => {
        if (str !== null) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            // Directly return the joined string
            var stringUpperLowerCase = splitStr.join(' ');

            //After joining the string making string to uppercase which are enclosed in brases ()
            const result = capitalCase(stringUpperLowerCase);

            return result;
        } else {
            return str
        }
    }

    const capitalCase = (str) => {
        const result = str
            .split(' ')
            .map((s) =>
                s.startsWith('(') && s.endsWith(')')
                    ? s.toUpperCase()
                    : s.slice(0, 1).toUpperCase() + s.slice(1),
            )
            .join(' ');
        return result;
    }

    function setCategoryDataFunc(categoryData) {
        var arr = RemoveDuplicates(categoryData, 'pihEquipmentTypeCategory');

        var filteredArr = arr.filter(function (el) {
            return el.pihEquipmentTypeCategory != null;
        });

        //make array of category in alphabetical order
        filteredArr.sort((a, b) => a.pihEquipmentTypeCategory.toLowerCase().localeCompare(b.pihEquipmentTypeCategory.toLowerCase()))

        //making array Camel Case and string which are enclosed in brases () to UPPERCASE
        for (let i = 0; i < filteredArr.length; i++) {
            filteredArr[i].category = titleCase(filteredArr[i].pihEquipmentTypeCategory);
        }

        setCategoryDataList(filteredArr);
        setEquipTypeListDrop(filteredArr);
    }
    useEffect(() => {
        if (categoryData && categoryData.length > 0) {
            setCategoryDataFunc(categoryData);
        } else {
            setCategoryDataList([]);
        }
    }, [categoryData]);

    /** Setting Serial Number list on equipment details */
    useEffect(() => {
        function addAllOption(data) {
            const allOption = {
                id: "ALL",
                serialNumber: "ALL",
            };

            return [allOption, ...data];
        }
        if (adminEquipSnoData.length) {
            const updatedData = addAllOption(adminEquipSnoData);
            setSerialNoList(updatedData);
        }
    }, [adminEquipSnoData]);

    /** Setting Create new Equipment Model Open */
    const handleCreateEquip = () => {
        setOpenModel(true);
        setEditModelValue(false);
        setEditRecord('');
    }

    /** Getting Searched values from the selected equipment type */
    const onSearchEquipmentType = () => {

        equipTypeSearchForm.validateFields().then(async (data) => {
            if (data && data.equipmentType) {
                setClear(true);
                const newArray = categoryDataList.find(u => u.pihEquipmentTypeCategory === data.equipmentType);
                setCategoryDataList([newArray]);
                setPaginationClear(true);
            } else {
                notification.warning({
                    message: 'Select Equipment Category to Search.'
                });
            }
        });
    }

    /** View clera button on select of equipment type */
    const onSelectEquipType = () => {
        setClear(true);
    }

    /** clearing selected data from equipment type dropdown */
    const OnclearEquipType = () => {
        setClear(false);
        equipTypeSearchForm.resetFields();
        setEquipTypeList(adminEquipCategoryData)
        setPaginationClear(false);
        setCategoryDataFunc(categoryData);
    }

    /** Setting Properties for upload file  */
    const fileProps = {
        accept: ".pdf,.jpeg,.jpg,.png", // Restrict file picker to these formats
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
            const isAllowedType = allowedTypes.includes(file.type);

            if (!isAllowedType) {
                notification.error({
                    message: "Invalid File Type",
                    description: "Only PDF, JPEG, JPG, and PNG files are allowed.",
                });
                return Upload.LIST_IGNORE; // Prevent file from being added to the list
            }
            //for multiple upload
            setFileList([...fileList, file]);
            // if (fileList.length > 0) {
            //     notification.warn({ message: 'Upload only one file at a time.' })
            // } else {
            // for single file upload
            // setFileList([file]);
            // setDisableFileUpload(true);
            // }
            return false;
        },
        fileList,
    };



    /** Fucntion to call upload file api and get **presigned Url to upload file to aws s3 bucket */
    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            await onUploadEquipmentFiles(fileList);

        } else {
            notification.warn({ message: 'Please select a file to upload.' })
        }

    }

    /** Removing file and closing upload window on cancel */
    const handleCancelUploadFile = () => {
        setFileList([]);
        setUploading(false);
        setOpenUploadModal(false);
        setDisableFileUpload(false);
    }

    useEffect(() => {
        if (!isUploadLoading) {
            setFileList([]);
            setUploading(false);
            setOpenUploadModal(false);
            setDisableFileUpload(false);
        }
    }, [isUploadLoading]);

    const onSearch = (value) => {
        onFetchAdminEquipCategory({ searchKey: value })
    };


    /** Show modal to add new document details */
    const showModal = () => {
        setIsEquipmentModalVisible(true);
        setDisableTypes(false);
        setEquipTitle('Add New Manuals & Diagrams');
        // setSerialNoList([]);
        // equipmentFormData.resetFields();
        // setPihEquipment('');
    }

    /** Cloase modal on cancel */
    const handleCancel = () => {
        setIsEquipmentModalVisible(false);
        if (subCategoryPageActive && documentPageActive) {
            equipmentFormData.resetFields(['certificate', 'serialNo', 'documentType']);
        } else if (subCategoryPageActive) {
            equipmentFormData.resetFields(['certificate', 'serialNo', 'documentType', 'pihEquipment']);
            setSerialNoList([]);
        } else {
            equipmentFormData.resetFields();
            setPihEquipment('');
            setSerialNoList([]);
        }
        // setEditValue(false);
        setDisableTypes(false);
        setFileList([]);
        setLoadingDocList(false);
    }

    /** Function to Add or Update equipment details */
    const handleOk = () => {
        setLoadingDocList(true);
        equipmentFormData.validateFields().then(async (data) => {
            if (
                data.pihCategory &&
                data.pihEquipment &&
                data.serialNo.length &&
                data.documentType &&
                fileList.length
            ) {
                if (Array.isArray(data.serialNo) && data.serialNo.includes("ALL")) {
                    data.serialNo = "ALL";
                }
                let payload = {
                    equipmentIds: data.serialNo,
                    equipmentTypeId: adminEquipTypeId,
                    documentTypeId: data.documentType,
                    documentBase64: documentBase64,
                    //document list params
                    equipmentDocTypeId: equipmentSubTypeId,
                    documentDocTypeId: documentId
                };

                // Conditionally include certificate if a file has been uploaded
                if (fileList.length > 0) {
                    payload.certificate = fileList;
                }
                onAddDocumentType(payload);
                setTimeout(() => {
                    handleCancel();
                }, 5000)

            } else {
                notification.warning({
                    message: "Fill all the required fields!",
                });
                setLoadingDocList(false);
            }
        });
    };

    /** On change function to select Category */
    const onChangeCategory = async (value) => {
        equipmentFormData.resetFields(['serialNo', 'pihEquipment']);
        setSerialNo('');
        setPihEquipment('');
        setAdminEquipmentTypes([]);
        if (value) {
            setPihCategory(value);
            // await onFetchAdminDocumentSubTypeSno({ pihEquipmentTypeCategory: value });
            var equipArr = categoryData && categoryData.filter((item) => item.pihEquipmentTypeCategory == value);
            //remove duplicates
            var arr = RemoveDuplicates(equipArr, 'pihEquipmentType');
            arr.sort((a, b) => a.pihEquipmentType.toLowerCase().localeCompare(b.pihEquipmentType.toLowerCase()))
            setAdminEquipmentTypes(arr);
        } else {
            setSerialNoList([]);
            setPihEquipment('');
            setSerialNo('');
            setSelectedEquipment(false);
            setPihCategory('');
            setAdminEquipmentTypes([]);
        }
    }

    /** On change function to select Equipment */
    const onChangeEquipmentType = async (value) => {
        equipmentFormData.resetFields(['serialNo']);
        setSerialNo('');
        if (value) {
            // const equipmentTypeId = adminEquipmentTypes.find(u => u.name === value);
            const equipmentTypeId = categoryData.find(u => u.pihEquipmentType === value);
            setAdminEquipTypeId(equipmentTypeId?.id);
            setPihEquipment(value);
            // setSelectedEquipment(true);
            await onGetEquipSno({ pihEquipmentTypeCategory: pihCategory, pihEquipmentType: value });
            // await onGetAdminMaintenanceTypesById({ id: equipmentTypeId.id });
        } else {
            setSerialNoList([]);
            setPihEquipment('');
            setSerialNo('');
            setSelectedEquipment(false);
        }
    }


    const handleSetForm = ({ pihEquipment, pihCategory }) => {
        if (pihCategory) {
            equipmentFormData.setFieldsValue({
                pihCategory: pihCategory,
            });
            onChangeCategory(pihCategory)
        }
        if (pihCategory && pihEquipment) {
            equipmentFormData.setFieldsValue({
                pihEquipment: pihEquipment, pihCategory: pihCategory,
            });
            onChangeEquipmentType(pihEquipment)
        }
    };

    return (
        <div className='admin'>
            <header style={{ textAlign: 'left', height: '28px' }}>
                <div className="create_equip">

                    <div style={{ display: 'inline-flex', height: '90px' }}>
                        <Form
                            form={equipTypeSearchForm}
                            style={{ width: '500px' }}
                        >
                            <Form.Item
                                name="equipmentType"
                                required
                            >
                                <Select
                                    className='client-select search_equip'
                                    placeholder='Category/Select Equipment Category'
                                    showSearch
                                    // allowClear
                                    optionFilterProp="children"
                                    onChange={onSelectEquipType}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={checkOpen}
                                >
                                    {categoryDataList && categoryDataList.map((item, i) =>
                                        <Option key={i} value={item && item.pihEquipmentTypeCategory}>{item && item.category}</Option>)}
                                </Select>
                            </Form.Item>
                        </Form>
                        <Button className='equipBtn' onClick={onSearchEquipmentType} style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }} disabled={checkOpen}>Search</Button>
                        {clear && <Button className='equipBtn' style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }} disabled={checkOpen} onClick={OnclearEquipType}>Clear</Button>}
                    </div>
                    <div className="flex-auto create_equipment">
                        <div
                            className="new-spare-data"
                        >
                            <Text onClick={showModal} className="ps-3 add-new-spare">
                                <span className="icon-22">
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Add New Manuals & Diagrams
                            </Text>
                        </div>
                    </div>
                </div>
            </header>
            <hr />
            {/* </>
            } */}
            {/* Components */}
            <div>
                <DocEquipmentTypes
                    checkOpen={checkOpen}
                    setChecksOpen={setChecksOpen}
                    //
                    equipTypeList={equipTypeList}
                    setEquipTypeList={setEquipTypeList}
                    categoryDataList={categoryDataList}
                    categoryData={categoryData}
                    //
                    adminEquipTypesLoading={adminEquipCategoryLoad}
                    setOpenModel={setOpenModel}
                    openModal={openModal}
                    equipTypeForm={equipTypeForm}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    editModelValue={editModelValue}
                    setEditModelValue={setEditModelValue}
                    setEditRecord={setEditRecord}
                    editRecord={editRecord}
                    openPage={openPage}
                    setOpenPage={setOpenPage}
                    equipTypeSearchForm={equipTypeSearchForm}
                    OnclearEquipType={OnclearEquipType}
                    clear={clear}
                    setClear={setClear}
                    paginationClear={paginationClear}
                    setPaginationClear={setPaginationClear}
                    equipmentFormData={equipmentFormData}
                    documentId={documentId}
                    setDocumentId={setDocumentId}
                    setEquipmentSubTypeId={setEquipmentSubTypeId}
                    equipmentSubTypeId={equipmentSubTypeId}
                    handleSetForm={handleSetForm}
                    setDocumentPageActive={setDocumentPageActive}
                    setSubCategoryPageActive={setSubCategoryPageActive}
                />
            </div>

            {/* Modal to create/update equipmentType */}
            <AddNewEquipmentType
                setOpenModel={setOpenModel}
                openModal={openModal}
                equipTypeForm={equipTypeForm}
                equipmentType={equipmentType}
                setEquipmentType={setEquipmentType}
                editModelValue={editModelValue}
                setEditModelValue={setEditModelValue}
                setEditRecord={setEditRecord}
                editRecord={editRecord}
            />

            <Modal
                // getContainer={false}
                width="45vw"
                className="pipeline-modal"
                title={equipTitle}
                centered
                visible={isEquipmentModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={loadingDocList}>
                        {loadingDocList ? 'Adding' : 'Add'}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={equipmentFormData}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        getcontainer={false}
                    >
                        <Row gutter={24}>
                            <Col lg={12}>
                                <Form.Item
                                    label="Equipment Category"
                                    name="pihCategory"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Category'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeCategory}
                                        disabled={disableTypes}
                                    >
                                        {/* {adminEquipCategoryLoad && <Option key={'loading'} value={'Loading...'} />} */}
                                        {categoryDataList && categoryDataList.map((item, i) =>
                                            <Option key={i} value={item && item.pihEquipmentTypeCategory}>{item && item.category}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Equipment Subcategory"
                                    name="pihEquipment"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Equipment'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeEquipmentType}
                                        disabled={disableTypes || !pihCategory}
                                    >
                                        {/* {adminDocumentSubCategoryLoad && <Option key={'loading'} value={'Loading...'} />} */}
                                        {adminEquipmentTypes && adminEquipmentTypes.map((item, i) =>
                                            <Option key={i} value={item && item.pihEquipmentType}>{item && item.pihEquipmentType}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Equipment Serial No."
                                    name="serialNo"
                                    required
                                >
                                    <Select
                                        className="multi-select"
                                        style={{ width: '100%' }}
                                        placeholder={'Select Sl.No.'}
                                        // showSearch
                                        optionFilterProp="children"
                                        mode="multiple"
                                        // allowClear={false}
                                        disabled={!pihEquipment}
                                    >
                                        {adminEquipSnoLoading ? (
                                            <Option key="loading" value="Loading...">Loading...</Option>
                                        ) : (
                                            serialNoList?.map((item, i) => (
                                                <Option key={i} value={item?.id}>
                                                    {item?.serialNumber}
                                                </Option>
                                            ))
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={12}>
                                <Form.Item
                                    label="Manuals & Diagrams Type"
                                    name="documentType"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder={'Select Manuals & Diagrams type'}
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                    // onChange={onChangeEquipmentSno}
                                    // disabled={disableTypes || !pihEquipment}
                                    >
                                        {adminEquipDocTypeLoad && <Option key={'loading'} value={'Loading...'} />}
                                        {!adminEquipDocTypeLoad && adminEquipDocTypeData && adminEquipDocTypeData.map((item, i) =>
                                            <Option key={i} value={item && item.id}>{item && item.description}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={12}>
                                <Form.Item
                                    label="Select Documents"
                                    name="certificate"
                                    required
                                >
                                    <Upload {...fileProps} >
                                        <Button disabled={certificateUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </div>
            </Modal >
        </div>
    )
};

const mapStateToProps = ({ adminEquipTypeReducer, uploadReducer, equip, adminEquipSnoReducer }) => {
    const adminEquipTypes = get(adminEquipTypeReducer, 'adminEquipTypes', []);
    const adminEquipTypesLoading = get(adminEquipTypeReducer, 'adminEquipTypesLoading', false);
    const isUploadLoading = get(uploadReducer, 'isUploadLoading', false);
    const isEquipDetailsLoading = get(equip, 'isEquipDetailsLoading', false);
    const adminEquipSnoLoading = get(adminEquipSnoReducer, 'adminEquipSnoLoading', false);
    const categoryData = get(equip, 'categoryData', []);
    const adminEquipSnoData = get(adminEquipSnoReducer, 'adminEquipSnoData', []);
    const adminEquipDocTypeLoad = get(adminEquipSnoReducer, 'adminEquipDocTypeLoad', false);
    const adminEquipDocTypeData = get(adminEquipSnoReducer, 'adminEquipDocTypeData', []);
    const adminEquipCategoryLoad = get(adminEquipSnoReducer, 'adminEquipCategoryLoad', false);
    const adminEquipCategoryData = get(adminEquipSnoReducer, 'adminEquipCategoryData', []);
    const adminDocumentSubCategoryLoad = get(adminEquipSnoReducer, 'adminDocumentSubCategoryLoad', false);
    const adminDocumentSubTypeSnoData = get(adminEquipSnoReducer, 'adminDocumentSubTypeSnoData', []);
    return {
        adminEquipTypes,
        adminEquipTypesLoading,
        isUploadLoading,
        isEquipDetailsLoading,
        adminEquipSnoLoading,
        categoryData,
        adminEquipSnoData,
        adminEquipDocTypeLoad,
        adminEquipDocTypeData,
        adminEquipCategoryData,
        adminEquipCategoryLoad,
        adminDocumentSubTypeSnoData,
        adminDocumentSubCategoryLoad
    }
}

const mapDispatchToProps = {
    onGetPihEquipmentTypes: fetchAdminPihEquipTypes,
    onUploadEquipmentFiles: uploadEquipmentfile,
    onGetCategory: fetchEquipCategory,
    onGetEquipSno: fetchAdminEquipSno,
    onAddDocumentType: addDocumentType,
    onFetchAdminEquipDocType: fetchAdminEquipDocType,
    onFetchAdminEquipDocList: fetchAdminEquipDocList,
    onFetchAdminEquipCategory: fetchAdminEquipCategory,
    onFetchAdminDocumentSubTypeSno: fetchAdminDocumentSubTypeSno,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentSubCategory);
