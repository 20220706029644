/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, notification, Select, Tooltip, Typography, Upload } from 'antd';
import { PlusOutlined, EyeOutlined, MoreOutlined, UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import EquipmentTypes from '../../components/admin/EquipmentTypes';
import AddNewEquipmentType from '../../components/admin/AddNewEquipmentType';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchAdminEquipTypes, fetchAdminPihEquipTypes } from '../../redux/admin/equipmentTypes/action';
import { uploadEquipmentfile } from '../../redux/admin/upload/action';

const { Text } = Typography;
const { Option } = Select;

const Admin = (props) => {

    const { adminEquipTypesLoading, adminEquipTypes, onGetPihEquipmentTypes, onUploadEquipmentFiles, isUploadLoading } = props;




    // Maitencance types
    const [checkOpen, setChecksOpen] = useState(false);
    const [openPage, setOpenPage] = useState('');

    //equipment Types
    const [equipTypeForm] = Form.useForm();
    const [equipTypeSearchForm] = Form.useForm();

    const [openModal, setOpenModel] = useState(false);
    const [equipTypeList, setEquipTypeList] = useState([]);
    const [equipTypeListDrop, setEquipTypeListDrop] = useState([]);
    const [clear, setClear] = useState(false);
    const [paginationClear, setPaginationClear] = useState(false);
    const [equipmentType, setEquipmentType] = useState('');
    const [editModelValue, setEditModelValue] = useState('');
    const [editRecord, setEditRecord] = useState('');

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [disableFileUpload, setDisableFileUpload] = useState(false);

    /** Getting Equipment Types data on load */
    useEffect(() => {
        onGetPihEquipmentTypes({
            pageSize: 20,
            pageNumber: 1
        });
    }, []);

    /** Setting the equipment type values to the state */
    useEffect(() => {
        setEquipTypeList(adminEquipTypes);
        setEquipTypeListDrop(adminEquipTypes);
    }, [adminEquipTypes])

    /** Setting Create new Equipment Model Open */
    const handleCreateEquip = () => {
        setOpenModel(true);
        setEditModelValue(false);
        setEditRecord('');
    }

    /** Getting Searched values from the selected equipment type */
    const onSearchEquipmentType = () => {

        equipTypeSearchForm.validateFields().then(async (data) => {
            if (data && data.equipmentType) {
                setClear(true);
                const newArray = adminEquipTypes.find(u => u.pihEquipmentType === data.equipmentType);
                setEquipTypeList([newArray]);
                setPaginationClear(true);
            } else {
                notification.warning({
                    message: 'Select Equipment Type to Search.'
                });
            }
        });
    }

    /** View clera button on select of equipment type */
    const onSelectEquipType = () => {
        setClear(true);
    }

    /** clearing selected data from equipment type dropdown */
    const OnclearEquipType = () => {
        setClear(false);
        equipTypeSearchForm.resetFields();
        setEquipTypeList(adminEquipTypes)
        setPaginationClear(false);
        onGetPihEquipmentTypes({
            pageSize: 10,
            pageNumber: 1
        });
        setEquipTypeListDrop(adminEquipTypes);
    }

    /** Setting Properties for upload file  */
    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            //for multiple upload
            // setFileList([...fileList, file]);
            // if (fileList.length > 0) {
            //     notification.warn({ message: 'Upload only one file at a time.' })
            // } else {
            // for single file upload
            setFileList([file]);
            setDisableFileUpload(true);
            // }
            return false;
        },
        fileList,
    };

    /** Fucntion to call upload file api and get **presigned Url to upload file to aws s3 bucket */
    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            await onUploadEquipmentFiles(fileList);

        } else {
            notification.warn({ message: 'Please select a file to upload.' })
        }

    }

    /** Removing file and closing upload window on cancel */
    const handleCancelUploadFile = () => {
        setFileList([]);
        setUploading(false);
        setOpenUploadModal(false);
        setDisableFileUpload(false);
    }

    useEffect(() => {
        if (!isUploadLoading) {
            setFileList([]);
            setUploading(false);
            setOpenUploadModal(false);
            setDisableFileUpload(false);
        }
    }, [isUploadLoading]);

    const onSearch = (value) => {
        onGetPihEquipmentTypes({ searchKey: value })
    };

    return (
        <div className='admin'>
            <header style={{ textAlign: 'left', height: '28px' }}>
                <div className="create_equip">
                    {/* <div className='project-details-wrapper create-quip-icon'
                        onClick={() => {
                            setOpenUploadModal(true);
                        }}
                    >
                        <Tooltip title={'Upload File'} placement='left'>
                            <CloudUploadOutlined className={`icon-28`} />
                        </Tooltip>
                    </div>

                    <div style={{ display: 'inline-flex' }}>
                        <div className='project-details-wrapper create-quip-icon'
                            onClick={handleCreateEquip}
                        >
                            <Tooltip title={'Create New Equipment Type'} placement='right'> <PlusOutlined className={`icon-28`} /></Tooltip>
                        </div>

                    </div> */}

                    <div style={{ display: 'inline-flex', height: '90px' }}>
                        <Form
                            form={equipTypeSearchForm}
                            style={{ width: '500px' }}
                        >
                            <Form.Item
                                name="equipmentType"
                                required
                            >
                                <Select
                                    className='client-select search_equip'
                                    placeholder='Type/Select Equipment Type'
                                    showSearch
                                    // allowClear
                                    optionFilterProp="children"
                                    onChange={onSelectEquipType}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={checkOpen}
                                >
                                    {equipTypeListDrop && equipTypeListDrop.map((item, i) =>
                                        <Option key={i} value={item && item.pihEquipmentType}>{item && item.pihEquipmentType}</Option>)}
                                </Select>
                            </Form.Item>
                        </Form>
                        <Button className='equipBtn' onClick={onSearchEquipmentType} style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }} disabled={checkOpen}>Search</Button>
                        {clear && <Button className='equipBtn' style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }} disabled={checkOpen} onClick={OnclearEquipType}>Clear</Button>}
                    </div>
                    <div className="flex-auto create_equipment">
                        <div
                            className="new-spare-data"
                        >
                            <Text onClick={handleCreateEquip} className="ps-3 add-new-spare" >
                                <span className="icon-22">
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Create New Equipment Type
                            </Text>

                            <Text onClick={() => {
                                setOpenUploadModal(true);
                            }} className="ps-3 add-new-spare">
                                <span className="icon-22">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="rotate(180)">
                                        <path d="M12 3v18M5 12l7 7 7-7"></path>
                                    </svg>

                                </span>
                                Upload File
                            </Text>
                        </div>
                    </div>
                </div>
            </header>
            <hr />
            {/* </>
            } */}
            {/* Components */}
            <div>
                <EquipmentTypes
                    checkOpen={checkOpen}
                    setChecksOpen={setChecksOpen}
                    equipTypeList={equipTypeList}
                    setEquipTypeList={setEquipTypeList}
                    adminEquipTypesLoading={adminEquipTypesLoading}
                    setOpenModel={setOpenModel}
                    openModal={openModal}
                    equipTypeForm={equipTypeForm}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    editModelValue={editModelValue}
                    setEditModelValue={setEditModelValue}
                    setEditRecord={setEditRecord}
                    editRecord={editRecord}
                    openPage={openPage}
                    setOpenPage={setOpenPage}
                    equipTypeSearchForm={equipTypeSearchForm}
                    OnclearEquipType={OnclearEquipType}
                    clear={clear}
                    setClear={setClear}
                    paginationClear={paginationClear}
                    setPaginationClear={setPaginationClear}
                />
            </div>

            {/* Modal to create/update equipmentType */}
            <AddNewEquipmentType
                setOpenModel={setOpenModel}
                openModal={openModal}
                equipTypeForm={equipTypeForm}
                equipmentType={equipmentType}
                setEquipmentType={setEquipmentType}
                editModelValue={editModelValue}
                setEditModelValue={setEditModelValue}
                setEditRecord={setEditRecord}
                editRecord={editRecord}
            />


            <Modal
                className="pipeline-modal"
                title='Upload Equipment Data'
                centered
                visible={openUploadModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={isUploadLoading}>
                        {isUploadLoading ? 'uploading' : 'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>
        </div>
    )
};

const mapStateToProps = ({ adminEquipTypeReducer, uploadReducer }) => {
    const adminEquipTypes = get(adminEquipTypeReducer, 'adminEquipTypes', []);
    const adminEquipTypesLoading = get(adminEquipTypeReducer, 'adminEquipTypesLoading', false);
    const isUploadLoading = get(uploadReducer, 'isUploadLoading', false);
    return {
        adminEquipTypes,
        adminEquipTypesLoading,
        isUploadLoading
    }
}

const mapDispatchToProps = {
    onGetPihEquipmentTypes: fetchAdminPihEquipTypes,
    onUploadEquipmentFiles: uploadEquipmentfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);