/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Table, Tooltip, Col, Typography, Modal, Form, Input, notification, Button, Select, Divider, Space, Checkbox, Spin, Card } from 'antd';
import { BellOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Option } from 'antd/lib/mentions';
const { Title, Text } = Typography;
import { getAllCognitoUsers, fetchTeamMembersForProject, deleteTeamMember, addTeamMember, getAllNonCognitoUsers, addNonCognitoUser } from '../redux/pihEquipmentDetails/action';
import { addRole, fetchRoles } from '../redux/roles/action'
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
const uuid = require("uuid");
import { getUsers, getAccess } from '../services/teamMembers';
import { set } from 'lodash';

const ProjectTeamDetails = (props) => {
    const {
        selectedProject,
        // isUserListLoading,
        // allUsersList,
        // onGetAllUsers,
        isProjectTeamMembersLoading,
        projectTeamMembersList,
        onFetchTeamMembersForProject,
        isDeletingInProgress,
        onDeleteTeamMember,
        onAddTeamMember,
        isAddingMemberInProgress,
        onFetchRoles,
        roles,
        onAddRole,
        nonCognitoUsersList,
        onGetAllNonCognitoUsers,
        isAddingNonCognitoUser,
        onAddNonCognitoUser,
        projectTeamMembersAccessList,
        userAccessList
    } = props;
    const [isAddMemberVisible, setIsAddMemberVisible] = useState(false);
    const [cognitoUsersList, setCognitoUsersList] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedTeamMemberData, setSelectedTeamMemberData] = useState('');
    const [role, setRole] = useState('');
    const [newRole, setNewRole] = useState('');
    const [accessToDsr, setAccessToDsr] = useState(false);
    const [accessToTbt, setAccessToTbt] = useState(false);
    const [disableTypes, setDisableTypes] = useState(false);
    const [teamTitle, setTeamTitle] = useState('');
    const [editValue, setEditValue] = useState(false);
    const [isAddNonCognitoUserVisible, setIsAddNonCognitoUserVisible] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [allUsersList, setAllUsersList] = useState([]);
    const [isUserListLoading, setIsUserListLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    //guest and tabs access
    const [selectedTabs, setSelectedTabs] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isGuestUser, setIsGuestUser] = useState(false);
    const [isNormalUser, setIsNormalUser] = useState(false);
    const [permissions, setPermissions] = useState({});
    const [selectedUserRoles, setSelectedUserRoles] = useState([]);
    const [isSelectedUserAdmin, setIsSelectedUserAdmin] = useState(true);

    //Team write access control for normal user
    const isButtonDisabled = () => {


        if (userAccessList.includes('admin'))
            return false;
        else if (projectTeamMembersAccessList[0]?.isGuestUser) {
            return true;
        } else if (projectTeamMembersAccessList[0]?.isNormalUser && !projectTeamMembersAccessList[0]?.tabPermissions?.Team?.write) {
            return true;
        } else {
            return false;
        }
    }
    const isWriteAccess = isButtonDisabled();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (_, record) => (
                <div>
                    {record.firstName && `${record.firstName} ${record.lastName}`}
                </div>

            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            render: (_, record) => (
                <div>
                    {!record.isNonCognitoUser ? `${record.email}` : ''}
                </div>

            ),
        },
        {
            title: 'Job Title',
            dataIndex: 'roleName',
            key: 'roleName',
            align: 'left'
        },
        {
            title: 'To be listed in DSR ',
            dataIndex: 'accessToDsr',
            key: 'accessToDsr',
            align: 'center',
            render: (text, record) => {
                return (
                    <Checkbox
                        disabled={true}
                        checked={record.accessToDsr}
                    >
                    </Checkbox>
                )
            }
        },
        {
            title: 'To be listed in Communications ',
            dataIndex: 'accessToTbt',
            key: 'accessToTbt',
            align: 'center',
            render: (text, record) => {
                return (
                    <Checkbox
                        disabled={true}
                        checked={record.accessToTbt}
                    >
                    </Checkbox>
                )
            }
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            // width: '200px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showEditModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Send Notification">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '12px', }} >
                                <BellOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }}
                                    onClick={() => { !isWriteAccess ? handleEyeClick(record) : undefined }}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Member">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '12px' }}>
                                <DeleteOutlined style={{ fontSize: '18px', color: isWriteAccess ? 'gray' : '', opacity: isWriteAccess ? '0.7' : '', }} onClick={() => { !isWriteAccess ? showDeleteModal(record) : undefined }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];
    const resetFields = () => {
        setSelectAll(false)
        setIsGuestUser(false)
        setIsNormalUser(false)
        setPermissions({})
        setSelectedUserRoles([])
        setSelectedTabs([]);
        setIsSelectedUserAdmin(true);
    }
    const getUsersList = async (searchKeyExist) => {
        try {
            setIsUserListLoading(true);
            const response = await getUsers('', searchKeyExist);
            if (response && response.users.length) {
                setAllUsersList(response.users);
                setIsUserListLoading(false);
                return response.users;
            } else if (response.users.length === 0) {
                setAllUsersList(null);
            } else {
                throw new Error('No users found.');
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong.',
            });
        }
        setIsUserListLoading(false);
    };

    useEffect(() => {
        getUsersList();
    }, []);

    useEffect(() => {
        if (!isUserListLoading) {
            // onGetAllUsers();
            getUsersList();
            onGetAllNonCognitoUsers();
            onFetchRoles();
        }
    }, []);

    useEffect(() => {
        if (selectedProject && selectedProject.id) {
            onFetchTeamMembersForProject(selectedProject);
        }
    }, [selectedProject]);

    useEffect(() => {
        let usersList = [];

        if (allUsersList && allUsersList.length > 0) {
            usersList = allUsersList.map(userData => {
                const fName = getCognitoValue("custom:first_name", userData?.Attributes);
                const lName = getCognitoValue("custom:last_name", userData?.Attributes);
                const displayName = (fName && lName) ? `${fName} ${lName}` : getCognitoValue("name", userData?.Attributes);
                if (displayName.trim() !== '') {
                    userData['displayName'] = displayName;
                    userData['email'] = getCognitoValue("email", userData?.Attributes);
                    userData['isNonCognitoUser'] = false;
                    userData['Username'] = userData?.Username;
                    return userData;
                }
                return null;
            }).filter(user => user !== null);
        }

        if (nonCognitoUsersList && nonCognitoUsersList.length > 0) {
            const nonUsersListData = nonCognitoUsersList.map(nonUserData => {
                const displayName = `${nonUserData?.firstName} ${nonUserData?.lastName}`;
                if (displayName.trim() !== '') {
                    nonUserData['displayName'] = displayName;
                    nonUserData['Username'] = `${nonUserData?.cognitoUserId}`;
                    return nonUserData;
                }
                return null;
            }).filter(user => user !== null);

            usersList.push(...nonUsersListData);
        }

        setCognitoUsersList(usersList);
    }, [allUsersList, nonCognitoUsersList]);


    useEffect(() => {
        if (!isAddingMemberInProgress) {
            setIsAddMemberVisible(false)
            setSelectedUser({})
            setRole('')
        }
    }, [isAddingMemberInProgress]);

    useEffect(() => {
        if (!isAddingNonCognitoUser) {
            setIsAddNonCognitoUserVisible(false)
        }
    }, [isAddingNonCognitoUser]);

    useEffect(() => {
        if (!isDeletingInProgress) {
            setIsDeleteModalVisible(false);
        }
    }, [isDeletingInProgress]);

    const getCognitoValue = (key, arr) => {

        const res = arr.find(obj => obj.Name === key)

        if (res)
            return res.Value;
        else
            return '';
    }

    const showModal = () => {
        if (selectedProject) {
            setDisableTypes(false);
            setTeamTitle('Add Member To Project');
            setAccessToDsr(false);
            setAccessToTbt(false);
            setIsAddMemberVisible(true);
        } else {
            notification.warn({ message: 'Select Project to add new user.' });
        }
    }

    const showEditModal = async (record) => {

        if (record != null) {
            setAccessToDsr(record.accessToDsr);
            setAccessToTbt(record.accessToTbt);
            setSelectedTabs(record.selectedTabs);
            setIsGuestUser(record.isGuestUser);
            setIsNormalUser(record.isNormalUser);
            setPermissions(record.tabPermissions);

            let userData = cognitoUsersList.find(c => c.email == record.email);
            if (!userData) {
                setLoading(true);
                const userListData = await getUsers('', record.email)
                userData = userListData.users.map(userData => {
                    const fName = getCognitoValue("custom:first_name", userData?.Attributes);
                    const lName = getCognitoValue("custom:last_name", userData?.Attributes);
                    userData['displayName'] = fName && lName ? `${fName} ${lName}` : getCognitoValue("name", userData?.Attributes);
                    userData['email'] = getCognitoValue("email", userData?.Attributes);
                    userData['isNonCognitoUser'] = false;
                    userData['Username'] = userData?.Username;
                    return userData;
                })[0];
            }
            setSelectedUser(userData);

            const roleData = roles.find(u => u.name === record.roleName);
            setRole(roleData);
        }
        setLoading(false);
        setDisableTypes(true)
        setIsAddMemberVisible(true);
        setTeamTitle('Update Member To Project');
        setEditValue(true);
    };

    const handleCancel = () => {
        setSelectedUser({})
        setIsAddMemberVisible(false);
        setRole('')
        setDisableTypes(false);
        setEditValue(false);
        setSelectedTabs([])
        setSelectAll(false)
        setIsGuestUser(false)
        setIsNormalUser(false)
        setPermissions({})
    }

    useEffect(() => {
        const fetchAccess = async () => {
            if (selectedUser.Username) {
                try {
                    const response = await getAccess(selectedUser.Username);
                    setSelectedUserRoles(response.map(x => x.GroupName));
                    setIsSelectedUserAdmin(response.map(x => x.GroupName).includes("admin"));
                } catch (error) {
                    console.error("Error fetching access:", error);
                }
            } else {
                resetFields()
            }
        };
        fetchAccess();
    }, [selectedUser]);

    const handleOk = async () => {
        if (!selectedUser.displayName || !role.id) {
            notification.warning({
                message: 'Fill all the required fields!'
            });
        } else if ((isGuestUser || isNormalUser) && isSelectedUserAdmin) {
            notification.warn({ message: 'User is already a Admin user!' });
            return;
        } else {
            if (selectedUser.Attributes && selectedUser.Attributes.length > 0) {
                var awsUser = selectedUser.Attributes;
                var userEmail = '', userFisrtName = '', userLastName = ''
                for (let i = 0; i < awsUser.length; i++) {
                    if (awsUser[i].Name === "email") {
                        userEmail = awsUser[i].Value
                    }
                    if (awsUser[i].Name === "custom:first_name") {
                        userFisrtName = awsUser[i].Value
                    }
                    if (awsUser[i].Name === "custom:last_name") {
                        userLastName = awsUser[i].Value
                    }
                    if (userFisrtName === '') {
                        if (awsUser[i].Name === "name") {
                            userFisrtName = awsUser[i].Value
                        }
                    }
                }


                let res = await onAddTeamMember({
                    addMemberPayload: {
                        cognitoUserId: selectedUser.Username,
                        email: userEmail,
                        firstName: userFisrtName,
                        lastName: userLastName,
                        projectId: selectedProject.id,
                        roleId: role.id,
                        accessToDsr: accessToDsr,
                        accessToTbt: accessToTbt,
                        isGuestUser: isGuestUser,
                        isNormalUser: isNormalUser,
                        selectedTabs: selectedTabs,
                        tabPermissions: isNormalUser ? permissions : {},
                        isNonCognitoUser: selectedUser.isNonCognitoUser
                    },
                    selectedProject: selectedProject
                })
                resetFields()

            } else if (selectedUser.firstName || selectedUser.lastName) {
                let res = await onAddTeamMember({
                    addMemberPayload: {
                        cognitoUserId: selectedUser.Username,
                        email: selectedUser.email,
                        firstName: selectedUser.firstName,
                        lastName: selectedUser.lastName,
                        projectId: selectedProject.id,
                        roleId: role.id,
                        accessToDsr: accessToDsr,
                        accessToTbt: accessToTbt,
                        isGuestUser: isGuestUser,
                        isNormalUser: isNormalUser,
                        selectedTabs: selectedTabs,
                        tabPermissions: isNormalUser ? permissions : {},
                        isNonCognitoUser: selectedUser.isNonCognitoUser
                    },
                    selectedProject: selectedProject
                })
                resetFields()
            }
            else {

                notification.warn({ message: 'User Does not exist. Please reload and try again!' })
            }
        }

        setDisableTypes(false);


    }

    const addNonCognitoUser = async () => {
        if (firstName.trim() === '' || lastName.trim() === '') {
            notification.warning({
                message: 'Fill all the required fields!'
            });
        } else {

            let res = await onAddNonCognitoUser({
                addNonCognitoPayload: {
                    cognitoUserId: uuid.v4(),
                    email: uuid.v4(),
                    firstName: firstName,
                    lastName: lastName,
                    isNonCognitoUser: true
                },
            })

        }
        // setIsAddNonCognitoUserVisible(false);
        onGetAllNonCognitoUsers();
        setFirstName('')
        setLastName('')
    }

    const userSelect = (value) => {
        setSelectedUser(cognitoUsersList[value]);
    }

    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedTeamMemberData(record)
    };

    const handleDeleteOk = async () => {
        await onDeleteTeamMember({
            data: {
                teamMemberData: selectedTeamMemberData,
                selectedProject: selectedProject
            }
        });
        // setIsDeleteModalVisible(false);
    }

    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedTeamMemberData('');
    }

    const onAddNewRole = (event) => {
        setNewRole(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (!isEmpty(newRole) && !isEmpty(newRole.trim())) {
            const existingRole = roles.find((role) => role.name.toLowerCase() === newRole.toLowerCase());
            if (existingRole) {
                notification.warn({ message: 'Role already exists!' })
                setNewRole('');
            } else {
                onAddRole({ name: newRole })
                setNewRole('');
            }
        }
    };

    const removeItem = (e) => {
        e.preventDefault();
        setNewRole('');
    }

    const onChange = (value) => {
        if (roles.length > 0) {
            const roleData = roles.find(u => u.name === value);
            setRole(roleData);
        }
    };

    const accessToDsrValue = (e) => {
        setAccessToDsr(e.target.checked);
    }

    const accessToTbtValue = (e) => {
        if (e.target.checked) {
            setAccessToTbt(1);
        } else {
            setAccessToTbt(0);
        }
    }

    //tabs access and guest`s access
    const tabs = [
        { label: 'Details', value: 'details' },
        { label: 'Equipment', value: 'equipment' },
        { label: 'Spares', value: 'spares' },
        { label: 'Consumables', value: 'consumables' },
        { label: 'Material Consumption', value: 'material-consumption' },
        { label: 'Team', value: 'team' },
        { label: 'Reporting Email', value: 'reporting-email' },
        { label: 'Log', value: 'log' },
        { label: 'ITP', value: 'itp' },
        { label: 'Billing', value: 'billing' },
        { label: 'DSR', value: 'dsr' },
        { label: 'Communications', value: 'communications' },
    ];

    const handleGuestUserChange = (e) => {
        const isChecked = e.target.checked;
        setIsGuestUser(isChecked);
        if (isChecked) {
            setIsNormalUser(false);

            handleSelectAllChange(true);
            setPermissions({})
            // setSelectedTabs([]);
        }
    };

    const handleNormalUserChange = (e) => {
        const isChecked = e.target.checked;
        setIsNormalUser(isChecked);
        if (isChecked) {
            setIsGuestUser(false);
            handleSelectAllChange(true);
            // setSelectedTabs([]);
        }
    };

    const handleSelectAllChange = (isChecked) => {
    //  const isChecked = e.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            // Select all tabs
            const allTabs = tabs.map(tab => tab.label);
            setSelectedTabs(allTabs);

            // Set "Read" permissions for all tabs
            const updatedPermissions = {};
            allTabs.forEach(tab => {
                updatedPermissions[tab] = { read: true, write: false };
            });
            setPermissions(updatedPermissions);
        } else {
            setSelectedTabs([]);
            setPermissions({});
        }
    };

    const handleTabChange = (checkedValues) => {
        // Update selected tabs
        setSelectedTabs(checkedValues);
        setSelectAll(checkedValues.length === tabs.length);

        // Update permissions for newly selected tabs
        const updatedPermissions = { ...permissions };
        checkedValues.forEach(tabLabel => {
            if (!updatedPermissions[tabLabel]) {
                updatedPermissions[tabLabel] = { read: true, write: false }; // Set "read" to true by default
            }
        });

        // Remove permissions for deselected tabs
        Object.keys(updatedPermissions).forEach(tabLabel => {
            if (!checkedValues.includes(tabLabel)) {
                delete updatedPermissions[tabLabel];
            }
        });

        setPermissions(updatedPermissions);
    };

    const handlePermissionChange = (tabLabel, permissionType, isChecked) => {
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [tabLabel]: {
                ...prevPermissions[tabLabel],
                [permissionType]: isChecked,
            },
        }));
    };
    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className="flex-auto">
                <div className="d-flex mb-3 align-items-center tab-content-title">
                    <Title className="mb-0" level={4}>
                        List of Team Members
                    </Title>
                    <div
                        className="new-project-team"
                    >
                        <Text onClick={!isWriteAccess ? showModal : undefined} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add Team
                        </Text>
                    </div>
                </div>
                <div className="custom-line" />
            </div>
            {/* <Spin spinning={loading}> */}
            <div className='addTeamDetails'>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={[...projectTeamMembersList]}
                    className="h-100 pipeline-table "
                    scroll={{ y: 'calc(100vh - 290px)' }}
                    loading={isProjectTeamMembersLoading}
                />
            </div>
            {/* </Spin> */}

            <Modal
                width="40vw"
                title={teamTitle}
                centered
                visible={isAddMemberVisible}
                footer={[

                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isAddingMemberInProgress}>
                        {!editValue ? (isAddingMemberInProgress ? 'Adding' : 'Add') : (isAddingMemberInProgress ? 'Updating' : 'Update')}
                    </Button>
                ]}
            >
                <div>
                    <Row gutter={24}>
                        <Col lg={24}>
                            <Select
                                className='maintenance-select'
                                placeholder={isUserListLoading ? 'Loading...' : 'Search User By Email'}
                                loading={isUserListLoading}
                                showSearch
                                optionFilterProp="children"
                                onChange={userSelect}
                                // onFocus={onGetAllUsers}
                                onSearch={(value) => getUsersList(value)}
                                style={{
                                    height: '32px',
                                    position: 'relative',
                                    top: '7px',
                                    width: '100%',
                                }}
                                value={selectedUser?.displayName}
                                disabled={disableTypes}
                                // dropdownRender={(menu) => (
                                //     <>
                                //         {menu}
                                //         <Divider style={{ margin: '8px 0' }} />
                                //         <Space style={{ padding: '0 8px 4px' }}>
                                //             <Button className='addTeam_member' type="text" icon={<PlusOutlined />}
                                //                 onClick={() => {
                                //                     setIsAddNonCognitoUserVisible(true)
                                //                     setFirstName('')
                                //                     setLastName('')
                                //                 }}>
                                //                 Add User
                                //             </Button>
                                //         </Space>
                                //     </>
                                // )}
                            >
                                {cognitoUsersList &&
                                    cognitoUsersList
                                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                                    .map((item, i) => (
                                        <Option key={i} value={item && item.UserName}>
                                            {item.displayName} {!item.isNonCognitoUser && item.email ? `(${item.email})` : ''}
                                        </Option>
                                    ))
                                }

                            </Select>
                        </Col>



                        <Col lg={24}>
                            <Select
                                className='maintenance-select'
                                placeholder="Select Job title"
                                showSearch
                                style={{
                                    height: '32px',
                                    position: 'relative',
                                    top: '7px',
                                    width: '100%',
                                    marginTop: '30px'
                                }}
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={null}
                                value={role.name}

                                // filterOption={(input, option) =>
                                //   (option.children).toLowerCase().includes(input.toLowerCase())
                                // }
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Role" value={newRole} onChange={onAddNewRole} />
                                            <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}
                            >
                                {roles &&
                                    roles
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item, i) =>
                                            <Option key={i} value={item.name}>
                                                {item && item.name}
                                            </Option>
                                        )}
                            </Select>
                        </Col>
                        <Col lg={10}>
                            <p style={{ marginTop: '25px' }}>
                                <Checkbox onChange={accessToDsrValue} checked={accessToDsr}>
                                    To be listed in DSR
                                </Checkbox>
                            </p>
                        </Col>
                        <Col lg={14}>
                            <p style={{ marginTop: '25px' }}>
                                <Checkbox onChange={accessToTbtValue} checked={accessToTbt}>
                                    To be listed in Communications
                                </Checkbox>
                            </p>
                        </Col>
                        <Col lg={10}>
                            <p style={{ marginTop: '5px' }}>
                                <Checkbox onChange={handleGuestUserChange} disabled={isSelectedUserAdmin} checked={isGuestUser}>
                                    Guest User
                                </Checkbox>
                            </p>
                        </Col>
                        <Col lg={14}>
                            <p style={{ marginTop: '5px' }}>
                                <Checkbox onChange={handleNormalUserChange} disabled={isSelectedUserAdmin} checked={isNormalUser}>
                                    Normal User
                                </Checkbox>
                            </p>
                        </Col>

                        <Col lg={24}>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Checkbox onChange={(e) => handleSelectAllChange(e.target.checked)} checked={selectAll} disabled={isSelectedUserAdmin}>
                                        <Text>Select ALL</Text>
                                    </Checkbox>
                                </Col>
                                <Col span={18}>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={24}>
                            <Checkbox.Group value={selectedTabs} disabled={isSelectedUserAdmin} onChange={handleTabChange}>
                                <Row gutter={[16, 10]}>
                                    {tabs.map((tab) => (
                                        <Col span={12} key={tab.label}>
                                            <Checkbox value={String(tab.label)}>{String(tab.label)}</Checkbox>
                                            {isNormalUser && selectedTabs.includes(tab.label) && permissions[tab.label] ? (
                                                <div style={{ marginLeft: '24px', display: 'flex' }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <label className="round-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                disabled={isSelectedUserAdmin}
                                                                checked={!!permissions[tab.label]?.read}
                                                                onChange={(e) => handlePermissionChange(tab.label, "read", e.target.checked)}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <label className='custom-label'>Read</label>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "16px" }}>
                                                        <label className="round-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                disabled={isSelectedUserAdmin}
                                                                checked={!!permissions[tab.label]?.write}
                                                                onChange={(e) => handlePermissionChange(tab.label, "write", e.target.checked)}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <label className='custom-label'>Write</label>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Col>
                    </Row>
                    {isSelectedUserAdmin && selectedUserRoles.length > 0 && <div
                        key='warn'
                        style={{
                            padding: '16px',
                            borderRadius: '8px',    // Slightly rounded corners (use 50% for circle)
                            border: '1px solid #EF314C', // Changed to full border // Red accent bar
                            marginTop: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px'
                        }}
                    >
                        <div style={{
                            background: '#EF314C',
                            color: 'white',
                            borderRadius: '50%',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexShrink: 0
                        }}>
                            !
                        </div>
                        <Text style={{ fontSize: '16px' }}>
                            The selected user has an Admin role and will have full access to the project. <b style={{ color: "#EF314C" }}>
                            </b>
                        </Text>
                    </div>}

                    {!isSelectedUserAdmin && isGuestUser && selectedTabs.length > 0 && <div

                        style={{
                            padding: '16px',
                            borderRadius: '8px',    // Slightly rounded corners (use 50% for circle)
                            border: '1px solid #EF314C', // Changed to full border // Red accent bar
                            marginTop: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px'
                        }}
                    >

                        <Text style={{ fontSize: '16px' }}>
                            Guest users are granted view-only access, meaning they are unable to modify or make changes to any content or settings

                        </Text>
                    </div>}
                </div>
            </Modal>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Team Member"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeletingInProgress}>
                        {isDeletingInProgress ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedTeamMemberData && selectedTeamMemberData.firstName + selectedTeamMemberData.lastName}</b>?</Text>
                </div>
            </Modal>
            <Modal
                width="45vw"
                title={"Add User"}
                centered
                visible={isAddNonCognitoUserVisible}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setIsAddNonCognitoUserVisible(false)
                        setFirstName('')
                        setLastName('')
                    }}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={addNonCognitoUser} loading={isAddingNonCognitoUser}>
                        {isAddingNonCognitoUser ? 'Adding' : 'Add'}
                    </Button>
                ]}
            >
                <div>
                    <Row gutter={16}>
                        <Col lg={12}>
                            <Input
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                                required={true}
                                size="large" />
                        </Col>

                        <Col lg={12}>
                            <Input
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                                required={true}
                                size="large" />
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ equip, rolesReduceer }) => {
    const isUserListLoading = get(equip, 'isUserListLoading', false);
    // const allUsersList = get(equip, 'usersList', []);
    const isProjectTeamMembersLoading = get(equip, 'isProjectTeamMembersLoading', false);
    const projectTeamMembersList = get(equip, 'projectTeamMembersList', []);
    const isDeletingInProgress = get(equip, 'isDeletingInProgress', false);
    const isAddingMemberInProgress = get(equip, 'isAddingMemberInProgress', false);
    const roles = get(rolesReduceer, 'roles', []);
    const nonCognitoUsersList = get(equip, 'nonCognitoUsersList', []);
    const isAddingNonCognitoUser = get(equip, 'isAddingNonCognitoUser', false);

    return {
        isUserListLoading,
        // allUsersList,
        isProjectTeamMembersLoading,
        projectTeamMembersList,
        isDeletingInProgress,
        isAddingMemberInProgress,
        roles,
        nonCognitoUsersList,
        isAddingNonCognitoUser
    }
}

const mapDispatchToProps = {
    // onGetAllUsers: getAllCognitoUsers,
    onFetchTeamMembersForProject: fetchTeamMembersForProject,
    onDeleteTeamMember: deleteTeamMember,
    onAddTeamMember: addTeamMember,
    onFetchRoles: fetchRoles,
    onAddRole: addRole,
    onGetAllNonCognitoUsers: getAllNonCognitoUsers,
    onAddNonCognitoUser: addNonCognitoUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTeamDetails);